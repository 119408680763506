import { useCallback, useRef } from "react"

const useTimedClickCounter = ({
  timeoutMS,
  onCount,
}: {
  timeoutMS: number
  onCount: (clicks: number, reset: () => void) => void
}) => {
  const clicks = useRef(0)
  const timeout = useRef<any>()

  const reset = () => (clicks.current = 0)
  const onClick = useCallback(() => {
    clicks.current = clicks.current + 1
    clearTimeout(timeout.current)
    timeout.current = setTimeout(reset, timeoutMS)
    onCount(clicks.current, reset)
  }, [onCount, timeoutMS])
  return { onClick, reset, clicks }
}

export default useTimedClickCounter
