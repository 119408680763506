import { PuxSkeletonText } from "@phonero/pux-react"
import React, { FC } from "react"

export const LoadingPlaceholder: FC<{ count: number; animated: boolean }> = ({
  count,
  animated,
}) => {
  return (
    <>
      {[...Array(count)].map((_value, index) => (
        <PuxSkeletonText
          key={index}
          animated={animated}
          style={{ height: "6.4rem" }}
        />
      ))}
    </>
  )
}
