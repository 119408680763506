import { PredefinedColors } from "@phonero/pux-core"
import { PuxSheet, PuxTypography } from "@phonero/pux-react"
import css from "./DialogMessage.module.scss"
import * as React from "react"
import classNames from "classnames"

export function DialogMessage({
  title,
  children,
  color = "dark",
  ...rest
}: {
  title?: React.ReactNode | null | undefined
  children?: any
  color?: PredefinedColors
  style?: React.CSSProperties
} & Partial<Parameters<typeof PuxSheet>[0]>) {
  return (
    <PuxSheet
      {...rest}
      className={classNames(css.dialogMessage, rest.className)}
      color={color}
    >
      {!!title && (
        <PuxTypography variant="h4" component="h4" className={css.title}>
          {title}
        </PuxTypography>
      )}
      {typeof children !== "string" ? (
        children
      ) : (
        <PuxTypography variant="body1" component="p">
          {children}
        </PuxTypography>
      )}
    </PuxSheet>
  )
}
