import { useState } from "react"
import { PuxTypography, useTranslation } from "@phonero/pux-react"
import classNames from "classnames"
import css from "./ShowLessOrAll.module.scss"

interface ShowLessOrAllProps {
  items: string[]
  maxItemsShownOnLess?: number
}
export const ShowLessOrAll = ({
  items,
  maxItemsShownOnLess = 10,
}: ShowLessOrAllProps) => {
  const showAllLinkNeeded = items.length > maxItemsShownOnLess
  const [showAllSelected, setShowAllSelected] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div>
      <div className={classNames(css.clickableDiv, "box")}>
        {items
          .slice(0, showAllSelected ? items.length : maxItemsShownOnLess)
          .join(", ")}
        {showAllLinkNeeded && !showAllSelected && <>...</>}
        {showAllLinkNeeded && (
          <div>
            <button
              onClick={() => {
                setShowAllSelected((v) => !v)
              }}
            >
              <PuxTypography component={"span"} variant={"info-small"}>
                {t("ExtraData.ShowAllToggle", {
                  context: showAllSelected ? "ON" : "OFF",
                })}
              </PuxTypography>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}