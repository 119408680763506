import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client"
import { useCallback } from "react"
import { graphqlToast, Options } from "./graphqlHelpers"
import { useTranslation } from "@phonero/pux-react"

import { pollInterval } from "../providers/apolloOptions"
import { useAppConstants } from "../AppConstants"

export const useAppQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: Q<TData, TVariables>,
  extendedOptions: O<TData, TVariables> = {}
) => {
  if (extendedOptions.pollInterval) {
    extendedOptions.pollInterval = pollInterval(extendedOptions.pollInterval)
  }
  const options = useBaseOptions(query, extendedOptions)
  return useQuery(query, options)
}

export const useAppLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: Q<TData, TVariables>,
  extendedOptions: O<TData, TVariables> = {}
) => {
  const options = useBaseOptions(query, extendedOptions)
  return useLazyQuery(query, options)
}

export const useAppMutation = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  extendedOptions?: OM<TData, TVariables>
) => {
  const options = useBaseOptions(mutation, extendedOptions)
  return useMutation(mutation, options as any)
}

type ExtraOptions = Options & {
  subKey?: string
}

type O<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
> = QueryHookOptions<TData, TVariables> & ExtraOptions
type OM<TData = any, TVariables = OperationVariables> = MutationHookOptions<
  TData,
  TVariables
> &
  ExtraOptions
export type Q<TData = any, TVariables = OperationVariables> =
  | DocumentNode
  | TypedDocumentNode<TData, TVariables>

function getOperationDefition<TData = any, TVariables = OperationVariables>(
  doc: Q<TData, TVariables>
) {
  for (const def of doc.definitions) {
    switch (def.kind) {
      case "OperationDefinition":
        return {
          operationName: def.name?.value,
          ...def,
        }
    }
  }
}

const useBaseOptions = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: Q<TData, TVariables>,
  {
    subKey: _subKey,
    toastOnErrors,
    toastOnSuccess,
    onCompleted: _onCompleted,
    onError: _onError,
    ...options
  }: O<TData, TVariables> | OM<TData, TVariables> = {}
) => {
  const { graphqlToastBehaviour } = useAppConstants()
  const rest = useTranslation()
  const { t } = rest
  const { operation, operationName } = getOperationDefition(query) || {}

  if (toastOnErrors === undefined && graphqlToastBehaviour === "opt-out") {
    toastOnErrors = true
  }

  switch (operation) {
    case "mutation":
      if (toastOnSuccess === undefined && graphqlToastBehaviour === "opt-out") {
        toastOnSuccess = true
      }
      break
  }

  const subKey = _subKey || operationName || "__unknown__"
  const { onCompleted: toastCompleted, onError: toastError } = graphqlToast(
    t,
    subKey,
    { toastOnErrors, toastOnSuccess }
  )

  const onError = useCallback(
    (err: ApolloError) => {
      const first = err.graphQLErrors?.[0]
      if (first) {
        console.error(
          "gqlErr in",
          first.extensions?.schemaName,
          first.path,
          first.message
        )
      }
      _onError && _onError(err)
      if (toastError) {
        toastError(err)
      }
    },
    [_onError, toastError]
  )
  const onCompleted = useCallback(
    (result: TData) => {
      _onCompleted && _onCompleted(result)
      if (toastCompleted) {
        toastCompleted(result)
      }
    },
    [_onCompleted, toastCompleted]
  )
  return {
    ...options,
    onCompleted,
    onError,
  }
}
