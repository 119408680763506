import { toSafeJSON } from "@phonero/common-ux"

export const devPrettyJson = (
  obj: {} | (() => any),
  showJson: boolean,
  ignoreKeys?: Set<string>,
  indentation: null | number = 2,
  prefix: string = "Developer-ouput: \n\n"
) => {
  if (!showJson) {
    return ""
  }
  return (
    prefix +
    toSafeJSON(
      typeof obj === "function" ? obj() : obj,
      indentation === null ? undefined : indentation,
      ignoreKeys
    ).replaceAll('"', "")
  )
}

export const devTooltipData = (data: any, key = "title") => {
  // @ts-ignore
  if (!_IS_DEV) {
    return null
  }
  return { [key]: devPrettyJson(typeof data === "function" ? data() : data, true) }
}
