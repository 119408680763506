import { toaster } from "../utils/toast/pushToast"
import { ApolloError } from "@apollo/client"
import getStacktrace from "./getstackTrace"
import { TFunction } from "i18next"
import { offlineStatus } from "./useOfflineStatus"
import { extractMessageFromApolloError } from "../util/handleGraphqlError"
import { shortenMessage } from "../Error/InlineError"

/** Applies general error-handling for mutations using translations with fallback.
 *
 * For instance, supply the `subKey` 'getUser', and the function will show an error to the user
 * from the translation-key `gqlError.getuser` with fallback if that key does not exist to `gqlError.general`.
 *
 * In addition, it will show a success-message to the user the same way, but with the prefix `gqlSuccess` instead.
 *
 */
export function graphqlMutationToast(t: TFunction, subKey: string) {
  return graphqlToast(t, subKey, { toastOnErrors: true, toastOnSuccess: true })
}

/** Applies general error-handling for queries using translations with fallback
 *
 * For instance, supply the `subKey` 'getUser', and the function will show an error to the user
 * from the tranlastion-key `gqlError.getuser` with fallback if that key does not exist to `gqlError.general`.
 */
export function graphqlQueryToast(t: TFunction, subKey: string) {
  return graphqlToast(t, subKey, { toastOnErrors: true, toastOnSuccess: false })
}

export type Options = {
  toastOnErrors?: boolean
  toastOnSuccess?: Boolean
}

/** General toaster for graphql-queries/mutations */
export const graphqlToast = (
  t: TFunction,
  subKey: string,
  options: Options
): {
  onError?: ReturnType<typeof onError>
  onCompleted?: ReturnType<typeof onCompleted>
} => {
  const { toastOnErrors, toastOnSuccess } = options
  return {
    ...(toastOnErrors && { onError: onError(t, subKey) }),
    ...(toastOnSuccess && { onCompleted: onCompleted(t, subKey) }),
  }
}

export function onError(t: TFunction, subKey?: string) {
  return async (err: ApolloError) => {
    if (!offlineStatus().online) {
      return
    }
    const errorMessage = extractMessageFromApolloError(err)
    let suffix = ""
    if (process.env.NODE_ENV === "development") {
      const stack = await getStacktrace(err)
      suffix = ` [DevKey: ${subKey} // ${stack.prettyCurrent}]`
    }
    toaster.error(
      t(["gqlError." + subKey, "gqlError.general"], { subKey, errorMessage, shortMessage: shortenMessage(errorMessage) }),
      {
        header: "Error" + suffix,
      }
    )
  }
}

export function onCompleted(t: TFunction, subKey?: string) {
  return (result: any) => {
    return toaster.success(
      t(["gqlSuccess." + subKey, "gqlSuccess.general"], { ...result, subKey }).toString()
    )
  }
}
