import {
  PuxModal,
  PuxContent,
  PuxSheet,
  PuxItemGroup,
  PuxItem,
} from "@phonero/pux-react"
import React, { useCallback, useRef, useState } from "react"
import { useBuildInfo, useAppInfo, DevDetails, appLaunchInfo } from "@phonero/common-ux"
import DialogTitle from "./DialogTitle"
import * as appConstants from "../../appConstants"
import { isDevEnv } from "../../appConstants"

const BuildInfoModal = ({
  open,
  toggleOpen,
}: {
  open: boolean
  toggleOpen: () => void
}) => {
  const firstModalRef = React.useRef<HTMLPuxModalElement>(null)

  return (
    <PuxModal
      keyboardClose
      ref={firstModalRef}
      swipeToClose
      isOpen={open}
      onWillDismiss={toggleOpen}
    >
      <DialogTitle>Versjonsinformasjon</DialogTitle>
      <PuxContent>
        <PuxSheet>
          <BuildInfo />
        </PuxSheet>
      </PuxContent>
    </PuxModal>
  )
}

export const BuildInfo = () => {
  const appInfo = useAppInfo()
  const build = useBuildInfo()
  const { tag, hash, branch, number, buildDateString, since } = build
  const [showDetials, setShowDetails] = useState(isDevEnv)
  const { onClick } = useTimedClickCounter({
    timeoutMS: 1000,
    onCount: (clicks, reset) => {
      if (clicks > 5) {
        setShowDetails(true)
        reset()
      }
    },
  })
  return (
    <PuxItemGroup onClick={onClick}>
      {process.env.NODE_ENV === "development" && (
        <p>NOTE: The information below is mocked in local development</p>
      )}
      <PuxItem>Tag: {tag}</PuxItem>
      <PuxItem>
        Byggedato: {buildDateString} {!!since && since}
      </PuxItem>
      <PuxItem>Hash: {hash}</PuxItem>
      <PuxItem>Branch: {branch}</PuxItem>
      <PuxItem> {number}</PuxItem>
      {appInfo.set && (
        <PuxSheet>
          <hr />
          <h5>AppInfo:</h5>
          {appInfo.appName && <PuxItem>AppName: {appInfo.appName}</PuxItem>}
          {appInfo.packageName && (
            <PuxItem>PackageName: {appInfo.packageName}</PuxItem>
          )}
          {appInfo.versionCode && (
            <PuxItem>VersionCode: {appInfo.versionCode}</PuxItem>
          )}
          {appInfo.versionNumber && (
            <PuxItem>VersionNumber: {appInfo.versionNumber}</PuxItem>
          )}
        </PuxSheet>
      )}
      <DevDetails
        data={{ appInfo, build, appConstants, appLaunchInfo: appLaunchInfo }}
        showOverride={showDetials}
      />
    </PuxItemGroup>
  )
}

const useTimedClickCounter = ({
  timeoutMS,
  onCount,
}: {
  timeoutMS: number
  onCount: (clicks: number, reset: () => void) => void
}) => {
  const clicks = useRef(0)
  const timeout = useRef<any>()

  const reset = () => (clicks.current = 0)
  const onClick = useCallback(() => {
    clicks.current = clicks.current + 1
    clearTimeout(timeout.current)
    timeout.current = setTimeout(reset, timeoutMS)
    onCount(clicks.current, reset)
  }, [onCount, timeoutMS])
  return { onClick, reset, clicks }
}

export default BuildInfoModal
