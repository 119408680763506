const schemeRegex = new RegExp(".*:///?")
export const toLocalUrl = (url: string) => {
  const withoutScheme = url
    .replace(schemeRegex, "/")
    .replace("/ditt.phonero.no", "")
    .replace("/ditt.dev.phonero.io", "")
    .replace("/ditt.staging.phonero.io", "")

  return withoutScheme
}
