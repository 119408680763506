import { PuxText } from "@phonero/pux-react"
import css from "../Checkout.module.scss"
import React, { FC } from "react"
/*import useAppTranslations from "@phonero/common-ux"*/

export const NoAvailableProducts: FC = () => {
  /*const { t, i18n } = useAppTranslations()*/

  return (
    <>
      <div className={css.container}>
        <div className={css.notAvailableContainer}>
          <p>
            <PuxText
              style={{
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Ikke mulig å bestille
            </PuxText>
          </p>
          <p>
            <PuxText>
              Du har en ubrukt data pakke og kan ikke bestille ny før denne er
              tatt i bruk eller utløpt.
            </PuxText>
          </p>
          <p>
            <PuxText>Hilsen Phonero</PuxText>
          </p>
        </div>
      </div>
    </>
  )
}
