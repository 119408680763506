import { CSSProperties } from "react"
import { PuxTypography, useTranslation } from "@phonero/pux-react"

export const Price = ({
  value,
  priceDiff,
  prefix,
  suffix,
  action,
  priceStyle,
  suffixStyle,
}: {
  value?: string | number | undefined | null
  priceDiff?: string | number
  prefix?: "priceFrom" | undefined
  suffix?: ("exclMva" | "inclMva" | "perMonth" | "perStk")[] | undefined
  action?: ("ceil" | "addMva" | "zeroAsBlank" | "freeIfZero")[] | undefined
  priceStyle?: CSSProperties
  suffixStyle?: CSSProperties
}) => {
  const { t, i18n } = useTranslation()
  let price = Number(value)
  if (isNaN(price)) {
    return <></>
  }
  if (action?.includes("ceil")) {
    price = Math.ceil(price)
  }
  if (action?.includes("addMva")) {
    price = price * 1.25
  }

  const hasValue = !!price || !!priceDiff
  const shouldShowZeroAsBlank = !hasValue && !!action?.includes("zeroAsBlank")
  const shouldShowFree = !hasValue && action?.includes("freeIfZero")
  const shouldShowSuffix = !shouldShowZeroAsBlank && !shouldShowFree && !!suffix
  return (
    <span>
      {prefix === "priceFrom" && (
        <PuxTypography variant="body2" component={"span"}>
          {t("ServiceDetails.From")}{" "}
        </PuxTypography>
      )}

      {!shouldShowFree && !shouldShowZeroAsBlank && (
        <PuxTypography
          variant={"label"}
          component={"span"}
          style={{ ...priceStyle }}
        >
          {typeof priceDiff === "number" &&
            (priceDiff > 0 ? "+" : "") + i18n.format(priceDiff, "price")}
          {typeof priceDiff !== "number" && i18n.format(price, "price")}
        </PuxTypography>
      )}

      {shouldShowFree && (
        <PuxTypography
          variant={"body1"}
          component={"span"}
          style={{ ...priceStyle }}
        >
          {t("ServiceDetails.Free")}
        </PuxTypography>
      )}

      {shouldShowSuffix && (
        <PuxTypography
          variant={"body2"}
          component={"span"}
          style={{ ...suffixStyle }}
        >
          {suffix?.includes("perMonth") && t("PerMonth")}
          {suffix?.includes("perStk") && " " + t("PerStk")}
          {suffix?.includes("exclMva") && " " + t("ExVat")}
          {suffix?.includes("inclMva") && " inkl. mva"}
        </PuxTypography>
      )}
    </span>
  )
}

export default Price
