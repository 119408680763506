import React, { FC } from "react"
import { externalLink } from "@phonero/pux-icons/icons"
import { PuxButton, PuxIcon } from "@phonero/pux-react"

import css from "./MobileInsuranceLinks.module.scss"

export function GetCertificateUrlForProduct(productId: number) {
  return productId === 24711
    ? "https://filer.phonero.no/web/forsikringsbevis_mobilforsikring_0387-1.pdf"
    : productId === 24712
    ? "https://filer.phonero.no/web/forsikringsbevis_tryggansatt_0388-1.pdf"
    : ""
}

export interface MobileInsuranceCertificateLinkProps {
  productId: number
  styleType: "button" | "anchor"
}

export const MobileInsuranceCertificateLink: FC<
  MobileInsuranceCertificateLinkProps
> = (props: MobileInsuranceCertificateLinkProps) => {
  const url = GetCertificateUrlForProduct(props.productId)
  const text = "Forsikringsbevis"
  const icon = <PuxIcon icon={externalLink} />
  if (props.styleType === "anchor") {
    return (
      <div className={css.insuranceLinkAnchor}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
          {icon}
        </a>
      </div>
    )
  }
  return (
    <PuxButton
    className={css.insuranceLinkButton}
    strong
    fill="clear"
    size="small"
    color="primary"
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
    {icon}
  </PuxButton>
  )
}
