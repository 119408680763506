import React, { Dispatch, FC, useRef } from "react"
import { useLocation } from "react-router-dom"
import { PuxFooter, PuxModal } from "@phonero/pux-react"
import {
  ChangeDataPackageInput,
  DataPackageProduct,
  OrderStatusId,
  OrderType,
} from "@phonero/common-graphql/types"
import { ChangeSubscriptionDialog } from "./components/change-subscription/ChangeSubscriptionDialog"
import { dataCy, OrdersQueryVariables } from "@phonero/common-ux"
import { useGoBack } from "../util/useGoBack"

export const ChangeSubscriptionContext = React.createContext<null | {
  selected?: Partial<DataPackageProduct>
  setSelected: Dispatch<any>
  input?: Partial<ChangeDataPackageInput>
  setInput: Dispatch<Partial<ChangeDataPackageInput>>
}>(null)

const analyticsProps = {
  orderKind: "change-subscription",
}

export const ordersVariables = (
  subscriptionId: string
): OrdersQueryVariables => ({
  first: 1,
  withDetails: true,
  where: {
    status: {
      in: [OrderStatusId.Cancelled, OrderStatusId.Completed],
    },
    orderTypeId: {
      in: [OrderType.Changedatapackage],
    },
  },
  subscriptionID: subscriptionId,
})

export const ChangeSubscriptionModal: FC = () => {
  const { hash } = useLocation()
  const isOpen = hash === "#endre"
  const firstModalRef = useRef<HTMLPuxModalElement>(null)
  const { goBack } = useGoBack({ onlyIfRouteMatch: "edit" })

  const onClose = () => {
    goBack()
  }

  return (
    <PuxModal
      keyboardClose
      ref={firstModalRef}
      swipeToClose
      isOpen={isOpen}
      onWillDismiss={onClose}
      {...dataCy("modal-change-subscription")}
    >
      {!!isOpen && <ChangeSubscriptionDialog />}
      <PuxFooter />
    </PuxModal>
  )
}
