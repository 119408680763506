import { makeVar, useReactiveVar } from "@apollo/client"
import { CustomerFeature } from "@phonero/common-graphql/types"

/** Indicates whether the selected customer has release number feature activated */
export const hasReleaseNumberFeatureAccess = makeVar<boolean | undefined>(
  undefined
)
export const useHasReleaseNumberFeatureAccess = () =>
  useReactiveVar(hasReleaseNumberFeatureAccess)

/** Indicates whether the selected customer has restricted access to directory information */
export const hasRestricedDirectoryInfoFeatureAccess = makeVar<
  boolean | undefined
>(undefined)
export const useHasRestricedDirectoryInfoAccess = () =>
  useReactiveVar(hasRestricedDirectoryInfoFeatureAccess)

/** Indicates what features the selected customer has access to */
export const customerFeaturesAccess = makeVar<CustomerFeature[] | undefined>(
  undefined
)
export const useCustomerFeaturesAccess = () =>
  useReactiveVar(customerFeaturesAccess)
export const useHasCustomerFeatureAccess = (feature: CustomerFeature) =>
  (useCustomerFeaturesAccess()?.findIndex((f) => f === feature) ?? -1) >= 0

interface CustomerData {
  /** True if any of the customers offerings is of type MBB */
  customerHasMbbOffering: boolean | undefined
  /** True if the customer has the public agreement flag set */
  isPublicAgreement: boolean | undefined
}
export const customerData = makeVar<CustomerData | undefined>(undefined)
export const useCustomerData = () => useReactiveVar(customerData)
