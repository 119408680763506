import classNames from "classnames"
import css from "./ApprovalStatusFlag.module.scss"

export interface IApprovalStatusFlag {
  status: keyof typeof colorClassMap
}

export const ApprovalStatusFlag = ({
  status,
  className,
  ...rest
}: IApprovalStatusFlag & JSX.IntrinsicElements["div"]) => {
  return (
    <div
      className={classNames(className, css.root, colorClassMap[status])}
      {...rest}
    />
  )
}

const colorClassMap = {
  PENDING: css.blueIndicator,
  APPROVED: css.greenIndicator,
  DECLINED: css.redIndicator,
  CANCELLED: "",
  blue: css.blueIndicator,
  green: css.greenIndicator,
  red: css.redIndicator,
}
