import React, { FC, lazy } from "react"
import {
  PuxButton,
  PuxButtons,
  PuxFooter,
  PuxLabel,
  PuxLogo,
  PuxToolbar,
} from "@phonero/pux-react"
import { phonero } from "@phonero/pux-logos/logos"
import { useAppTranslations, useBreakpointDesktop } from "@phonero/common-ux"
import { buildInfo } from "../../appConstants"
import { AppLink, mapLink } from "../../util/links"
import { useAppRouterDP } from "../../util/useAppRouterDP"

const BuildInfoModal = lazy(
  () => import(/* webpackChunkName: 'BuildInfo' */ "./BuildInfo")
)

const links: AppLink[] = [
  {
    tKey: "Links.HelpCenter",
    href: "https://support.phonero.no/hc/no/",
  },
  {
    tKey: "Links.Contact",
    href: "https://www.phonero.no/kontakt-oss",
  },
  {
    tKey: "Links.GDPR",
    href: "https://www.phonero.no/informasjon/gdpr",
  },
  {
    tKey: "Links.Privacy",
    href: "https://www.phonero.no/informasjon/personvern",
  },
  {
    tKey: "Links.TermsOfService",
    href: "https://www.phonero.no/informasjon/vilkar",
  },
]

export const Footer: FC = () => {
  const { goTo, history } = useAppRouterDP()
  const { t } = useAppTranslations()
  const isDesktop = useBreakpointDesktop()
  if (!isDesktop)
    return (
      <div
        className="spacer"
        style={{ height: 54, paddingBottom: "env(safe-area-inset-bottom)" }}
      />
    )
  return (
    <PuxFooter color="light" slot="end">
      <PuxToolbar>
        <PuxLogo
          logo={phonero}
          slot="start"
          style={{ height: 44, width: 110, paddingLeft: "4px" }}
        />

        <PuxLabel slot="start">
          {t("General.AllRightsReserved", {
            year: new Date(Date.now()).getFullYear(),
          })}
        </PuxLabel>
        <PuxButtons slot="end">
          {links.map(mapLink({ goTo: goTo("footer"), history, t }))}
        </PuxButtons>
      </PuxToolbar>
    </PuxFooter>
  )
}

export const BuildInfoButton = (props: Parameters<typeof PuxButton>[0]) => {
  const [open, setOpen] = React.useState(false)
  function toggleOpen() {
    setOpen((s) => !s)
  }
  return (
    <>
      <PuxButton
        size="small"
        fill="clear"
        onClick={toggleOpen}
        children={buildInfo.tag}
        {...props}
      />
      <React.Suspense fallback={<>...</>}>
        <BuildInfoModal open={open} toggleOpen={toggleOpen} />
      </React.Suspense>
    </>
  )
}
