import { CampaignThemeContextType, CodewordType, DataBoostTheme, DataPackageTheme } from "@phonero/common-ux"

const themes: CampaignThemeContextType = [
  {
    name: "",
  } as DataBoostTheme,
  //Theme name MUST BE the theme value returned by the api!!
  {
    name: "christmas",
    color: "primary",
    band: undefined,
    icon: "/campaign/icon/juleboost_big_light.svg",
    iconCheckoutPage: "/campaign/icon/juleboost_big_dark.svg",
    iconTopping: "/campaign/icon/christmas.svg",
    dataPackageRowIcon: undefined,
    iconImgAlt: "God jul",
    iconSmall: "/campaign/icon/databoostLitenNisselue.svg",
    iconLarge: "/campaign/icon/databoostNisselue.svg",
    ingress:
      "Her er en liten julegave til deg som er Phonerokunde! Husk å ta den i bruk i løpet av jula.",
    type: CodewordType.ExtraBoost,
  } as DataBoostTheme,
  {
    name: "easter",
    band: "/campaign/band/easter.svg",
    bandImgAlt: "God påske",
    iconTopping: undefined,
    dataPackageRowIcon: "/campaign/icon/easter.svg",
    iconImgAlt: "God påske",
    iconSmall: "/campaign/icon/easterLiten.svg",
    iconLarge: "/campaign/icon/easter.svg",
    type: CodewordType.ExtraBoost,
  } as DataBoostTheme,
  {
    name: "Feriesurf",
    actionButtonIcon: "/campaign/icon/actionButton_summer.svg",
    icon: "/campaign/icon/feriesurf_big.svg",
    iconCheckoutPage: "/campaign/icon/feriesurf_big_summary.svg",
    iconImgAlt: "Sommerkampanje",
    color: "summer",
    ingress:
      "Her er en liten sommergave til deg som er Phonerokunde! Husk å ta den i bruk i løpet av juli.",
    description:
      "Som Phonerokunde får du 1 GB Feriesurf gratis - aktiver og bruk pakken i løpet av juli for å få mest mulig glede av den. Vi ønsker deg en fantastisk sommer!",
    type: CodewordType.ExtraDataEEA,
    orderButtonText: "Fyll på med Feriesurf",
  } as DataPackageTheme,
  {
    name: "Summer",
    actionButtonIcon: "/campaign/icon/actionButton_summer.svg",
    icon: "/campaign/icon/sommersurf_big.svg",
    iconCheckoutPage: "/campaign/icon/sommersurf_big_summary.svg",
    iconImgAlt: "Sommerkampanje",
    color: "summer",
    priceLabel: "tilbudspris",
    ingress:
      "Perfekt for deg som planlegger en sommer utenfor hjemmets WiFi-sone! Ekstra lang varighet.",
    description:
      "Den perfekte pakka for å surfe inn i sommeren! Enten du planlegger en spennende biltur, en campingtur eller bare skal være uten tilgang til WiFi og sikre nett, så kan det være lurt å fylle på litt ekstra mobildata. Nå får du 50 GB Sommersurf til rabattert pris og ekstra lang varighet ut august.",
    type: CodewordType.ExtraDataEEA,
  } as DataPackageTheme,
  {
    name: "DittPhonero",
    actionButtonIcon: undefined,
    icon: "/campaign/icon/10GbFree_light.svg",
    iconCheckoutPage: "/campaign/icon/10GbFree_dark.svg",
    iconImgAlt: "DataBonus",
    color: "primary",
    colorOnFree: "tertiary",
    priceLabel: undefined,
    ingress:
      "Psst. Hvem sier vel nei til 10 GB ekstra data? Ikke du vel? Husk å aktivere senest 30. april 2023.",
    description:
      "Hei. Så gøy at du har valg Phonero! Vi vil gjerne starte kundeforholdet med å gi deg 10 GB ekstra data. Du kan aktivere nå - eller når du har behov for ekstra data, senest 30. april 2023. \n\n&nbsp;\n\n Velkommen til oss!",
    type: CodewordType.ExtraDataEEA,
    orderButtonText: "Fyll på gratis data",
  } as DataPackageTheme,
  {
    name: "Verisure25",
    actionButtonIcon: undefined,
    icon: "/campaign/icon/verisure.svg",
    iconImgAlt: "Verisure",
    color: "primary",
    colorOnFree: "tertiary",
    priceLabel: undefined,
    ingress:
      "Verisure, Norge ledende alarmselskap og vår samarbeidspartner, spanderer 25 GB data på alle som ønsker en uforpliktende sikkerhetsbefaring av sitt hjem",
    description:
      "Hei! Så gøy at du er interessert i sikkerhetstjenestene til vår samarbeidspartner Verisure, Norges ledende alarmselskap. Verisure spanderer 25 GB data på alle som gjennomfører en uforpliktende sikkerhetsbefaring. Etter at du har fylt på gratis data vil vi sende ditt mobilnr, navn og postnr til Verisure som tar kontakt for å avtale tid og sted for befaring. Ekstrapakken aktiveres med en gang og varer ut inneværende måned pluss tre måneder.",
    type: CodewordType.ExtraDataEEA,
    orderButtonText: "Fyll på gratis data fra Verisure",
  } as DataPackageTheme,
  {
    name: "Verisure50",
    actionButtonIcon: undefined,
    icon: "/campaign/icon/verisure.svg",
    iconImgAlt: "Verisure",
    color: "primary",
    colorOnFree: "tertiary",
    priceLabel: undefined,
    ingress:
      "Verisure, Norge ledende alarmselskap og vår samarbeidspartner, spanderer 50 GB data på alle som ønsker en uforpliktende sikkerhetsbefaring av sitt hjem",
    description:
      "Hei! Så gøy at du er interessert i sikkerhetstjenestene til vår samarbeidspartner Verisure, Norges ledende alarmselskap. Verisure spanderer 50 GB data på alle som gjennomfører en uforpliktende sikkerhetsbefaring. Etter at du har fylt på gratis data vil vi sende ditt mobilnr, navn og postnr til Verisure som tar kontakt for å avtale tid og sted for befaring. Ekstrapakken aktiveres med en gang og varer ut inneværende måned pluss tre måneder.",
    type: CodewordType.ExtraDataEEA,
    orderButtonText: "Book befaring og få gratis data",
  } as DataPackageTheme,
]

export const getTheme = <T extends DataBoostTheme | DataPackageTheme>(
  theme?: string | null
) => {
  return (
    !theme
      ? themes[0]
      : themes.find((t) => t.name.toLowerCase() === theme.toLowerCase()) ??
        themes[0]
  ) as T
}

export default themes
