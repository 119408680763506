export const serialize = (obj: unknown, forceString = true) => {
    switch (typeof obj) {
      case "undefined":
        return ""
      case "string":
        if (forceString) {
          return obj
        }
        // lots of strings are in fact numbers.
        const n = Number(obj)
        if (!isNaN(n)) {
          return n
        }
        return obj
      case "number":
        if (forceString) {
          return String(obj)
        }
        return obj
      case "boolean":
        return forceString ? String(obj) : obj
      case "function":
        return obj.name || "?func?"
  
      case "object":
        if (Array.isArray(obj)) {
          return obj
            .map((o) => serialize(o, forceString))
            .sort()
            .join(",")
        }
        try {
          return JSON.stringify(obj)
        } catch {
          return "???"
        }
    }
  
    return null
  }
  export const serializeAsObj = (obj: {}) => {
    switch (typeof obj) {
      case "function":
        return {}
      case "object":
        if (Array.isArray(obj)) {
          return obj.reduce((r: any, v, i) => {
            return { ...r, [String(i)]: serialize(v, true) }
          }, {})
        }
        return Object.entries(obj).reduce((r: any, [k, v]) => {
          return { ...r, [k]: serialize(v, true) }
        }, {})
      default: {
        return { value: serialize(obj, true) }
      }
    }
  }