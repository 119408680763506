import {
  PuxButton,
  PuxIcon,
  PuxLabel,
  PuxText,
  PuxTypography,
  useBreakpoint,
} from "@phonero/pux-react"
import css from "./PhoneroCloudDetail.module.scss"
import Price from "../Other/Price"
import {
  useCurrentSubscriptionId,
  graphqlMutationToast,
  useAppTranslations,
} from "../util"
import { action } from "@phonero/pux-icons/icons"
import { ApiProductState } from "@phonero/common-graphql/types"
import { Product } from "../ProductList/ProductItem"
import cx from "classnames"
import { GetPhoneroCloudUrl, PhoneroCloudLinks, PhoneroCloudProductId } from "."
import { useMutation } from "@apollo/client"
import {
  AddPhoneroCloudDocument,
  ResetPollIntervalOnPhoneroCloudDocument,
} from "../queries/AddPhoneroCloud.generated"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

// This component displays the detail of the PhoneroCloud service.
// Usage
// 1. It can be used in a promotional state where more information is displayed.
// 2. It can be used in a carousel with other promotion products.
// 3. It can be used in the standard service list with a list of services.
// Action
// On activating an Available or Registered product the backend activation process is trigged, then the user is redirected to sky.phonero.no/start to complete setting up the service.
// Deactivation is (by design) yet not supported.
export const PhoneroCloudDetail = ({
  product,
  displayMode = "serviceList",
  showPrice = true,
}: {
  product: Product
  displayMode: "promotion" | "promotionCarousel" | "serviceList"
  showPrice: boolean
}) => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const isDesktop = useBreakpoint("md", { useMin: true })
  const price = product?.price || undefined
  const { t } = useAppTranslations()

  const usePhoneroCloudResetPollInterval = useFeatureIsOn(
    "dpw-phonero-cloud-reset-poll-interval"
  )

  const headerMode = displayMode === "serviceList" ? "neutral" : "promotion"

  const [activateCloud] = useMutation(AddPhoneroCloudDocument, {
    ...graphqlMutationToast(t, "phonero-cloud"),
  })

  const [resetPollInterval] = useMutation(
    ResetPollIntervalOnPhoneroCloudDocument,
    {}
  )

  function onActivatePhoneroCloud(product: Product) {
    const productId = product?.productId
    const activateStates = [
      ApiProductState.Available,
      ApiProductState.Registered,
    ]

    if (
      !!product.state &&
      activateStates.includes(product.state) &&
      productId === PhoneroCloudProductId
    ) {
      if (!subscriptionId) {
        throw new Error("no subscriptionId (disable)")
      }

      openCloudApp()
      activateCloud({
        variables: { input: { subscriptionId, productId } },
        refetchQueries: ["SubscriptionServices"],
        awaitRefetchQueries: true,
      })
    } else {
      console.log("onActivatePhoneroCloud - failed", product)
    }
  }

  function onActivatingPhoneroCloud(product: Product) {
    const productId = product?.productId
    const cloudContractProductId = product?.id
    const activatingStates = [
      ApiProductState.Ordered,
      ApiProductState.Startactivation,
      ApiProductState.Activating,
    ]

    if (
      !!product.state &&
      activatingStates.includes(product.state) &&
      productId === PhoneroCloudProductId
    ) {
      if (!subscriptionId) {
        throw new Error("no subscriptionId (disable)")
      }

      openCloudApp()
      resetPollInterval({
        variables: { phoneroCloudId: +cloudContractProductId },
      })
    } else {
      console.log("onActivatingPhoneroCloud - failed", product)
    }
  }

  function openCloudApp() {
    // window.open does not work on IPhone. Trick to use a timeout to make the call execute on the main thread
    setTimeout(() => {
      window.open(GetPhoneroCloudUrl(), "_blank", "noopener noreferrer")
    })
  }

  const getAlignment = (
    displayMode: "promotion" | "promotionCarousel" | "serviceList"
  ) => {
    switch (displayMode) {
      case "promotion":
        return isDesktop ? "start" : "center"
      case "promotionCarousel":
        return "center"
      case "serviceList":
        return "start"
      default:
        return "start"
    }
  }

  const getActivationMode = (state: ApiProductState | undefined) => {
    if (!state) return undefined
    switch (state) {
      case ApiProductState.Registered:
      case ApiProductState.Available:
        return "Registered"
      case ApiProductState.Ordered:
      case ApiProductState.Activating:
      case ApiProductState.Startactivation:
        return "Activating"
      case ApiProductState.Active:
        return "Active"
      default:
        return undefined
    }
  }

  // simplified by reducing the number of states
  const activationMode = getActivationMode(product.state)
  if (activationMode === undefined) return <></>

  // The alignment of the content is different depending on the display mode
  const alignment = getAlignment(displayMode)

  return (
    <>
      <div
        className={cx(
          css.main,
          alignment === "start" ? css.alignStart : css.alignCenter
        )}
      >
        {headerMode === "promotion" && (
          <div>
            <PuxLabel wrap style={{ fontSize: "2.0rem", fontWeight: 900 }}>
              {product.name}
            </PuxLabel>
          </div>
        )}
        {headerMode === "neutral" && (
          <div>
            <PuxLabel wrap style={{ fontSize: "1.7rem" }}>
              {product.name}
            </PuxLabel>
          </div>
        )}
        {!!showPrice && !price && (
          <p style={{ marginBlock: 6 }}>
            <PuxText color="primary" style={{ fontStyle: "italic" }}>
              Tjenesten er inkludert
            </PuxText>
          </p>
        )}
        {!!showPrice && !!price && (
          <div>
            <Price
              value={price}
              action={["freeIfZero"]}
              priceStyle={{
                fontWeight: 900,
                textTransform: "capitalize",
                fontSize: isDesktop ? 19 : 17,
              }}
            />
            <PuxText>{"/mnd."}</PuxText>
          </div>
        )}
        <p style={{ marginBlock: 6 }}>
          <PuxText style={{ opacity: 0.6 }}>{product.description} </PuxText>
        </p>
        {activationMode === "Registered" && (
          // In registered mode we have to activate the product then redirect to the registration page
          <PuxButton
            shape="round"
            onClick={() => onActivatePhoneroCloud(product)}
          >
            <PuxText>{"Aktiver"}</PuxText>
            <PuxIcon icon={action} slot="end" />
          </PuxButton>
        )}
        {activationMode === "Activating" &&
          !!usePhoneroCloudResetPollInterval && (
            // In registered mode we have to activate the product then redirect to the registration page
            <PuxButton
              shape="round"
              onClick={() => onActivatingPhoneroCloud(product)}
            >
              <PuxText>{"Fullfør registrering"}</PuxText>
              <PuxIcon icon={action} slot="end" />
            </PuxButton>
          )}
        {activationMode === "Activating" &&
          !usePhoneroCloudResetPollInterval && (
            // In activating mode we only redirect to the app for setup
            <PuxButton
              shape="round"
              href={GetPhoneroCloudUrl()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PuxText>{"Fullfør registrering"}</PuxText>
              <PuxIcon icon={action} slot="end" />
            </PuxButton>
          )}
        {activationMode === "Active" && (
          <PhoneroCloudLinks
            hasActiveProduct={product?.state === ApiProductState.Active}
            productId={product?.productId || 0}
            styleType="button"
          />
        )}
      </div>
    </>
  )
}
