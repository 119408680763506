import { format, formatDistance } from "date-fns"
import nb from "date-fns/locale/nb"
import { BuildInfo, useAppConstants } from "../AppConstants"

export const useBuildInfo = () => {
  const { buildInfo } = useAppConstants()
  console.log("BUILDINFO:", buildInfo)
  return parseBuildInfo(buildInfo as any) // TODO: Fix any hack
}

export function parseBuildInfo(
  {
    tag = "",
    date = "",
    branch = "",
    number = "",
    hash = "",
  }: Partial<BuildInfo>,
  {
    now = new Date(),
    locale,
  }: { now?: Date; locale?: typeof nb | "nb" | "en" } = {}
) {
  let buildDate: Date | null = new Date(date)
  let buildDateString = ""
  let since = ""
  try {
    buildDateString = format(buildDate, "PPp", { locale: nb })
    since = formatDistance(buildDate, now, {
      addSuffix: true,
      ...(typeof locale === "object" && { locale }),
      ...((locale === "nb" || locale === undefined) && {
        locale: nb,
      }),
    })
  } catch (err) {
    console.warn("Build date formating failed", err)
    buildDate = null
  }

  return {
    hash: hash, //.trim().slice(0, 8),
    since,
    buildDate,
    buildDateString,
    tag: tag.replace(/^([\d].)/, "v$1").replace(/\+.*/, ""),
    branch,
    number,
  }
}
