export declare type ReactComponentArgs<F extends Function> = F extends (
  x: infer T
) => unknown
  ? T
  : never

/** Alias for your type, or undefined or null */
export type Perhaps<T> = T | undefined | null

/** Some products has a type-field which is of type CodewordType on server
 * See https://bitbucket.org/phonero/subscription/src/c659a3594e3fa6b763e61928ff6a1cd768e32692/SubscriptionService/Model/CodewordType.cs?at=develop#CodewordType.cs-3
 * As well as: https://bitbucket.org/phonero/subscription/src/c659a3594e3fa6b763e61928ff6a1cd768e32692/SubscriptionService/Model/Products/ExtraDataPackageProduct.cs?at=develop#ExtraDataPackageProduct.cs-15,30
 */
export enum CodewordType {
  /** 0 Alle EØS land */
  ExtraDataEEA = 0,
  /** 1 Alle land utenfor EØS */
  ExtraDataInternational = 1,
  /** 2 Beløpsgrenser, start kontroll / opphev kontroll mm. */
  DataControl = 2,
  /** 3 Sperre / Oppheve Sperre for mobil data */
  Barring = 3,
  /** 4 Data Boost pakker */
  ExtraBoost = 4,
  /** 5 Speed Boost pakker */
  SpeedBoost = 5,
}
