import { useEffect } from "react"

import { OrderStatusId, OrderType } from "@phonero/common-graphql/types"
import { MyOrdersDocument } from "./MyOrders.generated"
import { useCallbackRef } from "@chakra-ui/hooks"
import { removeOrderQueueItem, useOrderQueueItems } from "../util/useAppStateCommon"
import { useAppLazyQuery } from "../util/gqlClientHooks"

export const OrderEvents = {
  OrderCompleted: "OrderCompleted",
  StatusCheckFailed: "StatusCheckFailed",
}

export type OrderEventsParams = {
  OrderCompleted: { id: string; type: OrderType; status: OrderStatusId }
  StatusCheckFailed: {
    id: string
    type: OrderType | "UNKNOWN"
    errorMessage: string
  }
}
export type OrderEventType = {
  OrderCompleted: CustomEvent<OrderEventsParams["OrderCompleted"]>
  StatusCheckFailed: CustomEvent<OrderEventsParams["StatusCheckFailed"]>
}

export const useAppEvent = <T extends keyof typeof OrderEvents>(
  k: T,
  handler: (event: OrderEventType[T]) => void,
  target: Pick<Document, "addEventListener" | "removeEventListener"> = window
) => {
  const h = useCallbackRef(handler)
  useEffect(() => {
    target.addEventListener(k, h)
    return () => target.removeEventListener(k, h)
  }, [h, k, target])
}

/*
* Currently this badge is not showing any information.
* It just takes care of polling for orders that are added in the OrderQueueItem app var list.
*
* Add orders by using the pushOrderQueueItem method.
*
* When the order is completed it sends out an OrderCompleted event with the given id and order type.
*
* Consumers must register themselves for getting the events when orders are completed.
* Used for i.e. updating data when the order is done. Using refetch in the query might cause an error if
* the refetch method is no long available.
*
* Event listener example:
*
* useEffect(() => {
    const handleOrderCompleted = (event: any) => {
      const { type }: { id: string; type: OrderType } = event?.detail
      if (<check if the id or type is of interest>)
      {
          ie. run the lazy query again as done in releaseSubscriptionStatusBox
      }
    }

    window.addEventListener(
      DinBedriftEvents.OrderCompleted,
      handleOrderCompleted
    )

*   // Make sure to return the removeEventListener method so it will be run when component is removed
    return () => {
      window.removeEventListener(
        DinBedriftEvents.OrderCompleted,
        handleOrderCompleted
      )
    }
  }, [subscriptionId, releaseSubscriptionQuery])
*
* */
export const CustomerOrderBadge = () => {
  const orderQueueItems = useOrderQueueItems()

  const [myOrders, result] = useAppLazyQuery(MyOrdersDocument, {
    fetchPolicy: "network-only",
    variables: {},
    skip: !orderQueueItems.length, // Skip if no orders in queue
  })

  const { refetch, stopPolling, startPolling, called, client, error } = result
  const orders = result?.data?.myOrders?.nodes

  useEffect(() => {
    if (error?.message && !orders?.filter((o) => o !== null)?.length) {
      // Removing order queue items since error and no orders are returned.
      orderQueueItems?.map((o) => {
        window.dispatchEvent(
          new CustomEvent<OrderEventsParams["StatusCheckFailed"]>(OrderEvents.StatusCheckFailed, {
            detail: {
              id: o?.id,
              type: "UNKNOWN",
              errorMessage: error.message,
            },
          })
        )
        removeOrderQueueItem(o)
        return null
      })
    }

    // When order completed, remove from order queue
    orders?.forEach(async (o) => {
      const currentQueueItem = orderQueueItems.find((q) => q.id === o?.id)
      if (!currentQueueItem) return

      if (o?.status && [OrderStatusId.Completed, OrderStatusId.Cancelled].indexOf(o?.status) > -1) {
        if (!currentQueueItem.skipReFetchObservableQueries) {
          await client?.reFetchObservableQueries()
        }

        window.dispatchEvent(
          new CustomEvent<OrderEventsParams["OrderCompleted"]>(OrderEvents.OrderCompleted, {
            detail: {
              id: o?.id,
              type: o?.orderTypeId,
              status: o?.status,
            },
          })
        )
        removeOrderQueueItem(o)
      }
    })
  }, [orders, orderQueueItems, client, error?.message])

  useEffect(() => {
    // If no more pending orders, stop polling only if orders are found (to make sure the order is saved before stop polling)
    const ordersAreCompleted = orders?.map(
      (o) => o?.status && [OrderStatusId.Completed, OrderStatusId.Cancelled].indexOf(o?.status) > -1
    )
    if (ordersAreCompleted?.length && !ordersAreCompleted.some((o) => !o)) {
      stopPolling()
    }
  }, [orders, stopPolling])

  useEffect(() => {
    // Håndter som event.
    if (!orderQueueItems?.length) return

    if (!called) {
      myOrders({
        variables: {
          where: { id: { in: orderQueueItems.map((o) => o.id) } },
        },
      })
    } else {
      if (stopPolling) {
        stopPolling()
      }
      if (refetch) {
        refetch({
          where: { id: { in: orderQueueItems.map((o) => o.id) } },
        })
      }
    }

    if (startPolling) {
      startPolling(1000)
    }

    return (): void => {
      if (stopPolling) stopPolling()
    }
  }, [orderQueueItems, myOrders, startPolling, stopPolling, refetch, called])

  return <></>
}
