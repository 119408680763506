import { makeVar, useReactiveVar } from "@apollo/client"

type Menu = {
  isOpen: false | Date
  isOpening: false | Date
  isClosing: false | Date
}
const rightMenu = makeVar<Menu>({
  isOpening: false,
  isClosing: false,
  isOpen: false,
})

export const rightMenuR = (f: (m: Menu) => Menu) => {
  return rightMenu(f(rightMenu()))
}

export const menu = {
  toggle: () => {
    const m = rightMenu()
    if (m.isOpen || m.isOpening) {
      return menu.close()
    }
    return menu.open()
  },
  open: () =>
    rightMenuR((s) => {
      if (s.isOpen && s.isOpening) {
        failsafe()
        return s
      }
      // if (s.isClosing) {
      //   return s
      // }
      return {
        ...s,
        isClosing: false,
        isOpening: new Date(),
      }
    }),
  close: () =>
    rightMenuR((s) => {
      if (!s.isOpen && s.isClosing) {
        failsafe()
        return s
      }
      // if (s.isOpening) {
      //   return s
      // }
      return {
        ...s,
        isOpening: false,
        isClosing: new Date(),
      }
    }),
  closeWithOffset: (offset = 300) =>
    rightMenuR((s) => {
      const opened = s.isOpen
      if (opened && new Date().getTime() - opened.getTime() < offset) {
        return s
      }
      return { ...s, isOpen: false }
    }),
  var: rightMenu,
}

export const useMenu = () => useReactiveVar(rightMenu)

const failsafe = () => {
  const m = rightMenu()
  const { isOpening, isClosing } = m
  if (!isOpening && !isClosing) {
    return
  }

  const diff =
    new Date().getTime() -
    Math.min(
      isOpening ? isOpening.getTime() : Infinity,
      isClosing ? isClosing.getTime() : Infinity
    )
  if (diff < 1000) {
    return
  }
  rightMenu({ ...m, isClosing: false, isOpening: false })
}

setInterval(failsafe, 1_000)
