export const PhoneroCloudProductId: number = 10998
export const PhoneroCloudExternalUrl = "https://sky.phonero.no/"
export const PhoneroCloudExternalUrlApp = "https://sky.phonero.no/apps"
export const PhoneroCloudExternalUrlStart = "https://sky.phonero.no/start"

// This function is used to determine if the user is on a mobile device
// Do not use this function to determine screen size.
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function GetPhoneroCloudUrl() {
  return PhoneroCloudExternalUrlStart
  // return isMobile() ? PhoneroCloudExternalUrlApp : PhoneroCloudExternalUrl
}
