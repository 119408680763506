import { PuxSheet, useBreakpoint } from "@phonero/pux-react"
import { Product, PuxItemProps } from "./ProductItem"
import { FC } from "react"
import { Perhaps } from "../util/typeHelpers"
import { PromotionPhoneroCloud } from "./PromotionPhoneroCloud"
import { useMutation } from "@apollo/client"
import { SetPromotionProductDocument } from "../queries/SetPromotionProduct.generated"

export interface PromotionProductsProps {
  promotionProducts: Perhaps<(Product & { priceDiff?: number })[]>
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  widestAvailablePackageSize: any
  selected: string | null
  Component: any
}

export const PromotionProducts: FC<PromotionProductsProps & PuxItemProps> = ({
  promotionProducts,
  selected,
  setSelected,
  widestAvailablePackageSize,
  ...puxItemProps
}) => {
  const xs = useBreakpoint("xs", { useMax: true })

  const [setPromotion] = useMutation(SetPromotionProductDocument, {})

  function onUnPromote(product: Product) {
    const contractProductId = +product?.id
    setPromotion({
      variables: { contractProductId, isPromotionProduct: false },
      refetchQueries: ["SubscriptionServices"],
      awaitRefetchQueries: true,
    })
  }

  return (
    <>
      {!!promotionProducts?.length && (
        <PuxSheet style={{ width: "100%", padding: xs ? "20px" : "30px" }}>
          {promotionProducts.map((product) => {
            return (
              <PromotionPhoneroCloud
                key={product?.id}
                product={product as Product}
                mode={promotionProducts.length === 1 ? "single" : "multiple"}
                onUnPromote={onUnPromote}
                {...puxItemProps}
              />
            )
          })}
        </PuxSheet>
      )}
    </>
  )
}
