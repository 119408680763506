import { profile } from "@phonero/pux-icons/icons"
import { PuxIcon } from "@phonero/pux-react"
import classNames from "classnames"
import css from "./MenuProfileButton.module.scss"
import { Required } from "simplytyped"

export const MenuProfileButton = (
  props: Required<JSX.IntrinsicElements["button"], "onClick">
) => {
  return (
    <button
      className={classNames("pux-focusable", css.squareButton, css.center)}
      {...props}
    >
      <div className={classNames(css.circleContainer)}>
        <PuxIcon
          style={{ width: "60%", height: "60%" }}
          className={css.icon}
          icon={profile}
          slot="icon-only"
        />
      </div>
    </button>
  )
}
