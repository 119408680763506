import * as Apollo from "@apollo/client"
import { ExtraDataPackageOfferingProduct } from "@phonero/common-graphql/types"
import { CodewordType, useAppQuery, DataBoostTheme } from "@phonero/common-ux"
import { getTheme } from "../../assets/themes"
import {
  SubscriptionExtraDataPackagesDocument,
  SubscriptionExtraDataPackagesQuery,
  SubscriptionExtraDataPackagesQueryVariables,
} from "../ExtraDataQueries.generated"

// | ({
//     calculations: { theme: DataBoostTheme; isSpeedBoostEnabled: boolean }
//   } & QueryResult<any, SubscriptionExtraDataPackagesQueryVariables>)
// | undefined => {
/**
 * Getting data boost theme and if customer has speed boost enabled
 *
 * Om man har brukt om alle datapakkene sine så vil ikke denne bli satt riktig. vurder derfor å bytte dette kallet til et fra apiet som henter basert på type subscription.
 */
const useGetExtraDataPackageCustomerVariables = (
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionExtraDataPackagesQuery,
    SubscriptionExtraDataPackagesQueryVariables
  >
) => {
  // nosemgrep: semgrep.gql-missing-error-check-warning
  const result = useAppQuery(SubscriptionExtraDataPackagesDocument, {
    skip: !baseOptions.variables?.subscriptionId,
    ...baseOptions,
  })

  if (!result?.data || result?.loading) {
    return undefined
  }

  const products = result.data.mobileSubscription?.products || []
  const theme = getTheme<DataBoostTheme>(
    (
      products.find(
        (p) =>
          "theme" in p &&
          p.theme &&
          p.available?.totalCount &&
          p.__typename === "ExtraDataPackageOfferingProduct"
      ) as ExtraDataPackageOfferingProduct | undefined
    )?.theme
  )
  const isSpeedBoostEnabled = products.some(
    (p) =>
      p &&
      p.__typename === "ExtraDataPackageOfferingProduct" &&
      p.available?.totalCount &&
      p.type === CodewordType.SpeedBoost
  )
  return {
    ...result,
    calculations: {
      theme,
      isSpeedBoostEnabled,
    },
  }
}

export default useGetExtraDataPackageCustomerVariables
