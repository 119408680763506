import {
  PuxContent,
  PuxErrorBoundaryState,
  PuxHeader,
  PuxItem,
  PuxList,
  PuxPage,
  PuxSheet,
} from "@phonero/pux-react"
import { DesktopHeader, Footer, PageTitle } from "../../components/layout"
import { BuildInfo } from "../../components/layout/BuildInfo"
import PageHeader from "../../components/layout/PageHeader"
import React, { FC, lazy } from "react"
import { AnyFunc } from "simplytyped"
import {
  HelpfulError,
  HelpfulSuggestion,
  HelpfulSuggestions,
  MustUpgradeError,
  appStorage,
  platform,
  useBreakpointBelowDesktop,
  useKeycloak,
  logoutClosure,
  DevDetails,
} from "@phonero/common-ux"
import { isDevEnv } from "../../appConstants"
import { InlineSupportLink } from "../../pages/contact"
import i18next from "i18next"
import { keycloakState } from "@phonero/common-ux"
import classNames from "classnames"
import css from "./style.module.scss"
const DevMenu = lazy(
  () => import(/* webpackChunkName: 'DevMenu' */ "../../DevMenu")
)
const AppDevThing = lazy(
  () => import(/* webpackChunkName: 'DevView' */ "../../DevView")
)

const defaultSuggestions: HelpfulSuggestions[] = [
  [HelpfulSuggestion.NavigateBack],
  [HelpfulSuggestion.NavigateHome],
  [HelpfulSuggestion.IDPAccount],
  [HelpfulSuggestion.LogoutLogin],
  [HelpfulSuggestion.ContactSupport],
]
type helpfulHelper = (args: {
  text?: string
  keycloak: ReturnType<typeof useKeycloak>["keycloak"]
}) => { href?: string; onClick?: AnyFunc; text: string }

// Intentionally use window.location here instead of appRouter, since the appRouter may or may not work, or app-state may be corrupt.
const SuggestionTxtMap: Record<HelpfulSuggestion, helpfulHelper> = {
  [HelpfulSuggestion.LogoutLogin]: ({ keycloak, text }) => ({
    text: text || "Logg ut og logg inn igjen",
    onClick: () => logoutClosure(keycloak)(),
  }),
  [HelpfulSuggestion.NavigateBack]: ({ text }) => ({
    text: text || "Gå tilbake",
    onClick: () => window.history.back(),
  }),
  [HelpfulSuggestion.NavigateHome]: ({ text }) => ({
    text: text || "Gå tilbake til hjemskjermen",
    onClick: () => window.location.replace("/"),
  }),
  [HelpfulSuggestion.ClearLocalStorage]: ({ text }) => ({
    text: text || "Slett midlertidig lagring",
    onClick: async () => {
      await appStorage.clear()
      window.location.replace("/")
    },
  }),
  [HelpfulSuggestion.IDPAccount]: ({ text, keycloak }) => ({
    text: text || "Gå til kontoside",
    href: keycloak.createAccountUrl(),
  }),
  [HelpfulSuggestion.ContactSupport]: ({ text }) => ({
    text: text || "Kontakt support",
    onClick: () => window.location.replace("/kontakt"),
  }),
}

const AppError: FC<
  Omit<PuxErrorBoundaryState, "error"> & { error?: HelpfulError | Error }
> = (props) => {
  const { message, type: _type, error } = props
  const isMobile = useBreakpointBelowDesktop()
  const isDesktop = !isMobile
  const color = isDesktop ? "light" : undefined
  const { keycloak } = useKeycloak()
  const secondColor = "dark"
  const suggestions: HelpfulSuggestions[] | undefined | false =
    (!!error && error instanceof HelpfulError && error.suggestions) ||
    defaultSuggestions
  const desc = !!error && error instanceof HelpfulError && error.details
  const type =
    !!error && error instanceof HelpfulError ? "En feil oppstod" : _type

  if (!!error && error instanceof MustUpgradeError) {
    return (
      <>
        <PuxPage>
          <PuxHeader color="dark">{isMobile && <PageHeader />}</PuxHeader>
          <DesktopHeader />
          {isDesktop && <PageTitle backLink={false}>Utdatert app</PageTitle>}
          <PuxContent fullscreen style={{ textAlign: "center" }}>
            <PuxSheet
              color={color}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {message && <p>{message}</p>}
              {platform === "ios" && (
                <>
                  <p>
                    Trykk på lenken nedenfor for å komme til appen i App-store
                  </p>
                  <a
                    href="https://apps.apple.com/us/app/ditt-phonero/id1462254657?itsct=apps_box_badge&amp;itscg=30200"
                    className="app-store-badge"
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1559433600&h=dd63bdc57e3dafde78f53afaec2fdcc3"
                      alt="Download on the App Store"
                    />
                  </a>
                </>
                // <a href="https://apps.apple.com/us/app/ditt-phonero/id1462254657?itsct=apps_box_badge&amp;itscg=30200" style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/no-no?size=250x83&amp;releaseDate=1559433600&h=679d473f9f620aee550b43ce4ff8b2dd" alt="Download on the App Store" style="border-radius: 13px; width: 250px; height: 83px;"></a>
              )}
              {platform === "android" && (
                <a
                  className="play-store-badge"
                  href="https://play.google.com/store/apps/details?id=no.phonero.ditt&hl=no&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                >
                  <img
                    alt="Tilgjengelig på Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png"
                  />
                </a>
              )}
            </PuxSheet>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <p style={{ marginBlockEnd: 0 }}>
                Om du har spørsmål, kan du kontakte oss:
              </p>
              <InlineSupportLink withOpeningHours />
            </div>
            <PuxSheet color={secondColor}></PuxSheet>
          </PuxContent>
        </PuxPage>
      </>
    )
  }
  return (
    <>
      <PuxPage>
        <PuxHeader color="dark">{isMobile && <PageHeader />}</PuxHeader>
        <DesktopHeader />
        {isDesktop && <PageTitle backLink={false}>{type}</PageTitle>}
        <PuxContent fullscreen style={{ textAlign: "center" }}>
          <PuxSheet color={color}>
            <p>{message}</p>
            {!!desc && <p>{desc}</p>}
          </PuxSheet>
          <PuxSheet color={secondColor}>
            <p>Vi beklager det intrufne, og er på saken.</p>
            <p>I mellomtiden kan kanskje dette hjelpe:</p>
            <PuxList style={{ paddingBottom: 80 }}>
              {suggestions.map((s) => {
                let kind: HelpfulSuggestion | undefined = undefined
                let msg: string = ""

                if (Array.isArray(s)) {
                  kind = s[0]
                  if (s[1]) {
                    msg = s[1]
                  }
                } else {
                  kind = s
                }
                if (!kind) {
                  return null
                }
                const f = SuggestionTxtMap[kind]
                if (!f) {
                  return null
                }

                const { text, ...rest } = f({ keycloak, text: msg })
                return (
                  <PuxItem key={kind + String(msg)} button {...rest}>
                    {text}
                  </PuxItem>
                )
              })}
            </PuxList>
            <Statuses />

            {isDevEnv && (
              <>
                <React.Suspense fallback={null}>
                  <DevMenu />
                  <AppDevThing />
                </React.Suspense>
              </>
            )}
            <BuildInfo />
          </PuxSheet>
        </PuxContent>
        <Footer />
      </PuxPage>
    </>
  )
}

const Statuses = () => {
  const translationsOk =
    i18next.isInitialized &&
    i18next.hasResourceBundle(i18next.language, i18next.options.defaultNS?.toString() || "")
  const authOk = keycloakState.ready && keycloakState.errors.length === 0
  const isOk = (ok: boolean, failoverride?: string) =>
    ok ? "✔" : failoverride || "❌"
  return (
    <div className={css.states}>
      <div>
        Autentisering:
        <span className={classNames(authOk ? css.ok : css.fail)}>
          {isOk(authOk, keycloakState.getLastErrorMsg())}
        </span>
      </div>
      <div>
        Tekster:
        <span className={classNames(translationsOk ? css.ok : css.fail)}>
          {isOk(translationsOk)}
        </span>
      </div>
      <DevDetails
        data={{ ...keycloakState, lastError: keycloakState.getLastErrorMsg() }}
      />
    </div>
  )
}

export default AppError
