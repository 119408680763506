import { useIgnoreMessagesBefore } from "./MaintenanceBanner"
import { useEffect } from "react"
import { makeVar, useReactiveVar } from "@apollo/client"
import { useAppConstants } from "../AppConstants"

export enum MessageReceiver {
  DittPhonero = "dittphonero",
  DinBedrift = "dinbedrift",
  SelfService = "selfservice",
}
//
// Fetches maintenance messages from firebase.
//
// To add a firebase message log into console.firebase.google.com.
// Go to the firestore database and alter the rules to allow read ('allow read, write').
// Then send a http patch request with updated data. Remember to remove the 'allow write' after patching.
//
export const MaintenanceMessageFetcher = ({
  receiver,
}: {
  receiver: MessageReceiver
}) => {
  const { phoneroConfig } = useAppConstants()
  const maintenanceMessageUrl =
    useMaintenanceMessagesUrl() ?? phoneroConfig?.maintenanceMessageUrl
  const { ignoreMessagesBefore } = useIgnoreMessagesBefore()
  useEffect(() => {
    if (!maintenanceMessageUrl || !ignoreMessagesBefore) return

    async function getMessages() {
      await fetch(maintenanceMessageUrl ?? "")
        .then((res) => res.json())
        .then((res) => {
          const messages = res as MaintenanceMessages

          const newMessages = Array<Message>()
          messages?.fields?.messages?.arrayValue?.values?.forEach((v) => {
            newMessages.push(v?.mapValue?.fields)
          })
          maintenanceMessages(
            newMessages
              .filter((m) => filterOnReceiver(m, receiver))
              .filter((m) => filterOnDate(m))
          )
        })
        .catch((res) => {
          console.error(
            "failed to get maintenance messages ",
            maintenanceMessageUrl,
            "failed:",
            res
          )
        })
    }

    getMessages()
  }, [maintenanceMessageUrl, ignoreMessagesBefore, receiver])

  const localStorageUrlKey = "maintenanceMessageUrl"

  useEffect(() => {
    const localMaintenanceMessageUrl = localStorage.getItem(localStorageUrlKey)
    if (
      maintenanceMessageUrl &&
      localMaintenanceMessageUrl !== maintenanceMessageUrl
    ) {
      if (localMaintenanceMessageUrl) {
        localStorage.removeItem(localStorageUrlKey)
      }
      localStorage.setItem(localStorageUrlKey, maintenanceMessageUrl)
    }
  }, [maintenanceMessageUrl])

  return null
}

/** Url where maintenance messages are fetched from. Url should be loaded from app.json at startup. */
export const maintenanceMessagesUrl = makeVar<string | undefined>(undefined)
export const useMaintenanceMessagesUrl = () =>
  useReactiveVar(maintenanceMessagesUrl)

/** Url where maintenance messages are fetched from. Url should be loaded from app.json at startup. */
export interface Message {
  body: { stringValue: string }
  created: { timestampValue: string }
  endAt: { timestampValue: string }
  startAt: { timestampValue: string }
  receiver: { stringValue: string }
  summary: { stringValue: string }
  type: { stringValue: string }
  heading: { stringValue: string }
}
export interface MaintenanceMessages {
  createTime: string
  fields: {
    showMessage: boolean
    messages: {
      arrayValue: {
        values: Array<{
          mapValue: { fields: Message }
        }>
      }
    }
  }
  updateTime: string
}

export const maintenanceMessages = makeVar<Array<Message> | undefined>(
  undefined
)
export const useMaintenanceMessages = () => useReactiveVar(maintenanceMessages)

export const ignoreMessagesBeforeDate = makeVar<Date | undefined>(undefined)
export const useIgnoreMessagesBeforeDate = () =>
  useReactiveVar(ignoreMessagesBeforeDate)

const filterOnReceiver = (m: Message, receiver) =>
  !m.receiver?.stringValue ||
  m.receiver?.stringValue === receiver ||
  m.receiver?.stringValue === MessageReceiver.SelfService
const filterOnDate = (m: Message) => {
  const startAt = m.startAt ? Date.parse(m.startAt.timestampValue) : undefined
  const endAt = m.endAt ? Date.parse(m.endAt.timestampValue) : undefined
  const now = Date.now()
  return (!startAt || now > startAt) && (!endAt || now < endAt)
}
