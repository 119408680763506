import { If, StringEqual } from "simplytyped"
import { RouteKeys } from "../routes"
import { UrlHashes } from "@phonero/common-ux"
import { useAppRouterDP } from "../util/useAppRouterDP"

export function Anchor<K extends RouteKeys | keyof typeof UrlHashes>({
  route: _route,
  analyticsButtonName,
  onClick,
  component: Ce = "a",
  ...rest
}: {
  route: K
  analyticsButtonName: string
  component?: keyof JSX.IntrinsicElements | React.FunctionComponent
} & Omit<JSX.IntrinsicElements["a"], "href"> &
  If<
    StringEqual<K, "data">,
    {
      international: boolean
      productId?: string | number | undefined | null
    },
    {}
  >) {
  const { international, productId, ...props } = rest as any
  const { dialogues, goTo, paths } = useAppRouterDP()
  const route = _route as any
  let path = ""
  if (UrlHashes[route]) {
    path = UrlHashes[route]
  } else if (paths[route]) {
    path = paths[route]()
  }
  const handleClick: JSX.IntrinsicElements["a"]["onClick"] = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onClick?.(e)
    if (dialogues[route]) {
      const f = dialogues[route](analyticsButtonName)
      if (!!f && typeof f === "function") {
        f({ international, productId })
      }
      return
    }
    goTo(analyticsButtonName)[route]?.()
  }
  return (
    <>
      <Ce
        {...(Ce === "a" && { href: path })}
        onClick={handleClick}
        {...props}
      />
    </>
  )
}
