import React from "react"
import MarkdownIt from "markdown-it"
import css from "./MarkdownWithApprovedIconForLists.module.scss"

/**
 * @param markdownText Text from db containing markdown text ie. '- 3 GB data inkludert hver måned \n - Data rollover'
 * @constructor
 */
export class MarkdownWithApprovedIconForLists extends React.Component<{
  markdownText: string
}> {
  render() {
    let { markdownText } = this.props
    if (!markdownText) return null

    md.core.ruler.after("inline", "icon-list", function (state) {
      let tokens = state.tokens
      for (let i = 0; i < tokens.length; i++) {
        if (isListItem(tokens[i])) {
          setAttr(tokens[i], "icon", "approved")
        }
      }
    })

    return (
      <span
        className={css.list}
        dangerouslySetInnerHTML={{
          __html: md.render(markdownText.replaceAll("\\n", "\n")),
        }}
      />
    )
  }
}

const md = MarkdownIt().set({ html: true, breaks: true })

function setAttr(token, name, value) {
  const index = token.attrIndex(name)
  const attr = [name, value]

  if (index < 0) {
    token.attrPush(attr)
  } else {
    token.attrs[index] = attr
  }
}

/*
Example:
https://cdn.jsdelivr.net/npm/markdown-it-task-lists@2.1.1/index.js
*/
function isListItem(token) {
  return token.type === "list_item_open"
}
