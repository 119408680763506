import { convertToMegaBytes, sizeMultiplier } from "./dataPackageSizeHelper"
import { getOfferingId } from "./offeringIdHelper";


const _sortDataProducts = (
  packageA: { sizeInMb: number; offeringId: string },
  packageB: { sizeInMb: number; offeringId: string }
): number => {
  // Sort by package size ascending
  const sizeDiff = packageA.sizeInMb - packageB.sizeInMb
  if (sizeDiff === 0)
    // If equal take the lowest offering id
    return +getOfferingId(packageB.offeringId) - +getOfferingId(packageA.offeringId)
  else return sizeDiff
}

export const sortDataProducts = (
  arr: {
    size: number
    sizeType: keyof typeof sizeMultiplier
    offeringId: string
  }[]
) =>
  arr
    .map((a) => ({
      ...a,
      sizeInMb: convertToMegaBytes(a.size, a.sizeType),
    }))
    .sort(_sortDataProducts)

export const sortByNameReverse = (a, b) => {
  if (!a || !b || !("name" in a) || !("name" in b)) {
    return -1
  }

  /* Sorting by the reverse string to get packages for each country next to each other */
  return reverseString(a.name as string) > reverseString(b.name as string)
    ? -1
    : 1
}

function reverseString(str: string | undefined | null) {
  return str?.split("").reverse().join("") ?? ""
}
