import { PredefinedColors } from "@phonero/pux-core"
import {
  abo,
  approved,
  chat,
  clock,
  close,
  databoost,
  dataControl,
  purchase,
  removeServices,
  request,
} from "@phonero/pux-icons/icons"
import { OrderStatusId, OrderType } from "@phonero/common-graphql/types"

export const orderStatusMap: Record<
  OrderStatusId,
  {
    color?: PredefinedColors
    icon: typeof request
    iconProps?: JSX.IntrinsicElements["span"]["style"]
  }
> = {
  // TODO: assign icons to these too
  [OrderStatusId.Cancelled]: {
    color: "danger",
    icon: close,
    iconProps: { transform: "scale(0.8)" },
  },
  [OrderStatusId.Completed]: {
    color: "success",
    icon: approved,
    iconProps: { transform: "translateY(1px)" },
  },
  [OrderStatusId.Registered]: { icon: request },
  [OrderStatusId.Pendingdelivery]: { color: "dark", icon: clock },
  [OrderStatusId.Pendingapproval]: { color: "dark", icon: chat },
}

// The icons below needs to be verified
export const orderTypeMap = {
  [OrderType.Changedatapackage]: { icon: request },
  [OrderType.Addbarringproduct]: { icon: abo },
  [OrderType.Addcostcontrol]: { icon: dataControl },
  [OrderType.Addextradatapackage]: { icon: purchase, iconOffset: 8 },
  [OrderType.Deactivateservice]: {
    icon: removeServices,
    iconOffset: 8,
  },
  [OrderType.Adddatarollover]: { icon: databoost },
  // TODO: assign icons to these too
} as const
