import { ApolloClient, from, ApolloProvider, createHttpLink, InMemoryCache, ApolloLink, Observable, ApolloError, makeVar, InMemoryCacheConfig, HttpOptions } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { RetryLink } from "@apollo/client/link/retry";


import { PropsWithChildren } from "react";
import { useAppConstants } from "../AppConstants";
import { useKeycloak } from "@react-keycloak/web";
import { Capacitor } from "@capacitor/core";

const attempts = 3;

type KeptError = {
  operationName: string;
  traceId: string;
  requestId: string;
  time: Date;
  error: ApolloError;
};

export const latestErrors = makeVar<{ list: KeptError[] }>({ list: [] });

// See https://github.com/apollographql/react-apollo/issues/2059
// Because of this we have to surface extensions via data to make them
// accessible downstream.

export const Apollo = ({ children, generatedIntrospection }: PropsWithChildren<{generatedIntrospection: any}>) => {
  const { authProps, phoneroConfig, graphqlEndpoint, buildInfo } = useAppConstants();
  const { keycloak: keycloakClient} = useKeycloak()
  // FIXME: these hooks will cause rerenders of the whole app

  const ForwardExtensionsLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      const sub = forward(operation).subscribe({
        next: (result) => {
          if (result.data) {
            result.data.extensions = () => result.extensions;
          }
          if (result.errors) {
            const { response } = operation.getContext();
            const headers = getResponseHeaders(response);
            const reqId = headers["x-request-id"];
            const traceId = headers["x-b3-traceid"];
            result.errors = result.errors.map((err) => {
              if (!err.extensions) {
                (err as any).extensions = {};
              }

              err.extensions!["response-headers"] = headers;
              err.extensions!["operation-name"] = operation.operationName;
              err.extensions!["links"] = createLinksFromRequestId(reqId, traceId);
              return err;
            });
            const currentErrors = latestErrors();
            latestErrors({
              ...latestErrors,
              list: [
                ...currentErrors.list,
                {
                  traceId,
                  time: new Date(),
                  requestId: reqId,
                  operationName: operation.operationName,
                  error: new ApolloError({ graphQLErrors: result.errors }),
                },
              ],
            });
          }
          observer.next(result);
        },
        complete: observer.complete.bind(observer),
      });
      return () => {
        if (sub) sub.unsubscribe();
      };
    });
  });
  const getResponseHeaders = (response: any) => {
    const headers: Record<string, string> = {};
    if (!response || !response.headers) {
      return headers;
    }
    (response as Response).headers.forEach((v, k) => {
      // Headers are case insensitive, but various intermediate
      // reverse-proxies, cdn's and some servers does not send them in this
      // condition.
      // This is NOT a problem with the current servers stack, but who knows.
      headers[k.toLowerCase()] = v;
    }, {});
    return headers;
  };
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: attempts,
      retryIf: async (error, _operation) => {
        error.extraField = "whoop";
        error.extraInfo.foo = "whoop";
        error.clientErrors.push("whoop");
        const { cache, getCacheKey, failures = 0, headers, ...context } = _operation.getContext();

        // On failures, we attempt to update the token, and then use the new
        // token for new attempts. We could force the token to update by setting
        // a larger minValidity to updateToken below But we should probably not
        // do that for all errors.
        //
        // If only the server could tell us that there is something wrong with
        // the authentication...
        let refreshed = false;
        if (!authProps.enableMock) {
          refreshed = await keycloakClient.updateToken(60);
        }
        _operation.setContext({
          refreshed,
          failures: failures + 1,
          headers: {
            ...headers,
            Authorization: "Bearer " + keycloakClient.token || headers.Authorization,
          },
        });
        const exp = keycloakClient.tokenParsed?.exp;
        const tokenExpiresDate = !!exp && new Date(exp * 1000);
        const tokenExpiresIn = !!tokenExpiresDate && tokenExpiresDate.getTime() - new Date().getTime();
        console.error("retryIf on retryLink triggered", {
          error,
          // refreshed,
          headers: { headers },
          exp,
          tokenExpiresDate,
          tokenExpiresIn,
          context,
          failures,
          operationName: _operation.operationName,
        });
        return !!error;
      },
    },
  });

  const num = (n: any) => {
    switch (typeof n) {
      case "string": {
        const nm = Number(n);
        if (isNaN(nm)) {
          return null;
        }
        return nm;
      }
      case "number":
        if (isNaN(n)) {
          return null;
        }
        return n;

      default:
        return null;
    }
  };
  const createLinksFromRequestId = (reqId: string, traceId: string) => {
    if (!reqId) {
      return traceId;
    }
    let lokiTemplate = phoneroConfig?.jeagerTemplate;
    if (!lokiTemplate) {
      const gqlEnvIsDev = graphqlEndpoint?.includes("phonero.io");
      if (gqlEnvIsDev) {
        lokiTemplate =
          "https://grafana.observe-dev.phonero.io/explore?orgId=1&left=%7B%22datasource%22:%22P998741A58ED2B411%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22datasource%22:%7B%22type%22:%22tempo%22,%22uid%22:%22P998741A58ED2B411%22%7D,%22queryType%22:%22traceql%22,%22limit%22:20,%22query%22:%22{traceId}%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D";
      } else {
        lokiTemplate =
          "https://grafana.observe-prod.phonero.io/explore?orgId=1&left=%7B%22datasource%22:%22P998741A58ED2B411%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22datasource%22:%7B%22type%22:%22tempo%22,%22uid%22:%22P998741A58ED2B411%22%7D,%22queryType%22:%22traceql%22,%22limit%22:20,%22query%22:%22{traceId}%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D";
      }
    }

    const loki = lokiTemplate.replaceAll("{traceId}", traceId);
    return { loki };
  };

  const httpOptions: HttpOptions = {
    ...(!!graphqlEndpoint && {
      uri: graphqlEndpoint,
    }),
    headers: {
      PuxPlatform: Capacitor.getPlatform(),
      PuxClientVersion: buildInfo?.tag,
      PuxClient: phoneroConfig?.collectorApplicationName, // TODO: Doublecheck if "no.phonero.ditt was used somewhere"
    },
  }

  const httpLink = createHttpLink({
    uri: httpOptions?.uri || (keycloakClient as any).tokenParsed?.graphqlEndpoint, // endpoint is either inserted options, from token, or eventually "/graphql"
    ...httpOptions,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists return the
    // headers to the context so httpLink can read them
    if (!authProps.enableMock) {
      await keycloakClient.updateToken(60);
    }

    // await new Promise(res => setTimeout(res, 10000))
    const now = new Date();
    const tokenExpires = num(keycloakClient.tokenParsed?.exp) || 0;
    return {
      requestStart: now,
      tokenExpires,
      headers: {
        ...headers,
        Authorization: `Bearer ${keycloakClient.token || "null"}`,
      },
    };
  });

  const errorLink = onError((error) => {
    const {
      // graphQLErrors,
      // networkError,
      operation: { operationName, getContext },
    } = error;
    const now = new Date();
    const { requestStart, failures, tokenExpires, response } = getContext();
    const statusCode = response?.status;
    const headers = getResponseHeaders(response);
    const tokenExpiresInSeconds = !!requestStart && !!tokenExpires && tokenExpires - requestStart.getTime() / 1_000;
    const tokenExpiresDate = !!tokenExpires && new Date(tokenExpires * 1000);
    const reqId = headers["x-request-id"];
    const traceId = headers["x-b3-traceid"];
    const durationMs = requestStart && now.getTime() - requestStart.getTime();
    if (durationMs > 20_000) {
      console.warn(`The operation ${operationName} took ${durationMs}ms and failed`);
      console.warn(`Slow and failed operation ${operationName}`, {
        durationMs,
        reqId,
        requestStart,
      });
    }
    const links = createLinksFromRequestId(reqId, traceId) || {};
    console.error("ApolloError: " + operationName, ...Object.values(links), {
      skipSend: true,
      statusCode,
      response,
      reqId,
      durationMs,
      requestStart,
      failures,
      operationName,
      tokenExpires,
      headers,
      tokenExpiresInSeconds,
      tokenExpiresDate,
    });

    if (failures === attempts - 1) {
      // No more attempts will be made.
      switch (statusCode) {
        case 401:
          // As of April 2022, the apigateway uses a global authentication-check. This is subject to change. Below are recorderd example-response, which are all contained within the `www-authenticate`-header
          const errorMessage = headers["www-authenticate"];
          if (process.env.NODE_ENV === "development") {
            if (didAlert) {
              return;
            }
            /* eslint-disable no-alert */
            const ok = window.confirm(
              `DEVELOPMENT-MESSAGE:\n\nYou will be forcebly logged out.\n\nThe reason was:\n${statusCode} ${errorMessage}\n\nFurther details are available in the console.\n\nThis message is  only available in development.`
            );
            didAlert = true;
            setTimeout(() => {
              didAlert = false;
            }, 3000);
            if (!ok) {
              return;
            }
          }
          // We have already tried the graphql-request multiple times, and the api says we are not authorized.
          // Therefore, we forcibly log out the current user, (hope they don't have any unsaved work!)
          keycloakClient.logout();
          break;
      }
    }
  });



  const cacheConfig: InMemoryCacheConfig = {
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      CostSharingEmployee: {
        keyFields: ["phoneNumber"],
      },
      ExpenseSet: {
        fields: {
          expenses: {
            keyArgs: false,
          },
        },
      },
      ExpensesConnection: {
        fields: {
          nodes: {
            keyArgs: ["subscriptionId", "categories", "expenseFilter"],
            merge: RefNodesMerger,
          },
          edges: {
            keyArgs: ["subscriptionId", "categories", "expenseFilter"],
            merge: RefEdgeMerger,
          },
        },
      },
      // DataConsumptionGroup: {
      //   fields: {
      //     // NOTE: For some reason, this does not work atm.
      //     // I dont know why, but it works with cache-policy: cache-only, see: https://github.com/apollographql/apollo-client/issues/9800
      //     /* Why we generate the hash based on the content:
      //       Currently, the api does not return an id for this object, since the object is a calculated value.
      //       However, we need a way for the UI-logic to be able to identify the correct object.
      //
      //       For instance when selecting the item in a dropdown.
  
      //       Using the items index within the array is very flaky, and breaks with sorting, appending items etc.
  
      //       The generated hash can not guarantee uniqueness. Identical objects will create the same hash.
  
      //       The generated hash will change if any properties change.
      //     */
      //     _contentHash: {
      //       read: (existing, options) => {
      //         const fields = [
      //           "description",
      //           "groupType",
      //           "isActive",
      //           "isQueued",
      //           "daysLeft",
      //           "activationDateTime",
      //           "size",
      //         ]
      //         const obj: Record<string, unknown> = {}
      //         for (const field of fields) {
      //           obj[field] = options.readField({ fieldName: field })
      //         }
  
      //         return fastHash(obj)
      //       },
      //     },
      //   },
      // },
      OrderDetail: {
        fields: {
          _lastUpdate: {
            read: (existing, options) => {
              let dates = [
                options.readField<string>({
                  fieldName: "registrationDateTime",
                }),
              ]
              const approvalRef = options.readField<{ _ref: string }>({
                fieldName: "approval",
              })
              if (approvalRef) {
                for (const key of [
                  "assigned",
                  "approved",
                  "approvedDate",
                  "assignedDate",
                  "processed",
                  "rejected",
                  "rejectedDate",
                  "reminderSent",
                ]) {
                  const val = options.readField<string>({
                    fieldName: key,
                    from: approvalRef,
                  })
                  if (!val) {
                    return
                  }
                  dates.push(val)
                }
              }
              return dates
                .filter(Boolean)
                .map((d) => new Date(d!))
                .sort((a, b) => {
                  const A = a.getTime()
                  const B = b.getTime()
                  if (A > B) {
                    return 1
                  }
                  if (A < B) {
                    return -1
                  }
                  return 0
                })[0]
            },
          },
        },
      },
    },
  }  

  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
      },
      query: {
        errorPolicy: "all",
      },
    },
    link: from([errorLink, authLink, retryLink, ForwardExtensionsLink, httpLink]),
    cache: new InMemoryCache(cacheConfig),
    connectToDevTools: process.env.NODE_ENV !== "production",
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

let didAlert = false;



type RefEdge<T extends string = ""> = {
  __typename: T
  node: {
    __ref: string
  }
}
type RefNode<T extends string = ""> = {
  __ref: T
}

/** This will merge edges.
 *
 * If the new items returned have the same ids as the previous, the new items will have precedence
 */
function RefEdgeMerger<T extends string = "">(
  prev: RefEdge<T>[] = [],
  next: RefEdge<T>[]
) {
  // To keep the order from the server correct, the `next` items should be first in the array
  const all = [...next, ...prev]
  const refMap = all.reduce(
    (r, n) => {
      r[n.node.__ref] = n
      return r
    },
    {} as Record<string, RefEdge<T>>
  )
  const uniqueIds = Array.from(new Set(all.map((n) => n.node.__ref)))
  const uniques = uniqueIds.map((id) => refMap[id])

  return uniques
}
/**
 * Merges arrays.
 */
function RefNodesMerger<T extends string = "">(
  prev: RefNode<T>[] = [],
  next: RefNode<T>[]
) {
  // I want to keep the first fetched items on top of the array and add the next on the bottom.
  const all = [...prev, ...next]
  const refMap = all.reduce(
    (r, n) => {
      r[n.__ref] = n
      return r
    },
    {} as Record<string, RefNode<T>>
  )
  const uniqueIds = Array.from(new Set(all.map((n) => n.__ref)))
  const uniques = uniqueIds.map((id) => refMap[id])

  return uniques
}