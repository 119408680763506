import { PuxSheet, useBreakpoint } from "@phonero/pux-react"
import { Product, ProductItem, PuxItemProps } from "./ProductItem"
import { FC } from "react"
import { Perhaps } from "../util/typeHelpers"
import classNames from "classnames"
import { ProductItemCampaign } from "./ProductItemCampaign"
import css from "./DataPackage.module.scss"
import { useAppTranslations } from "../util"

export interface CampaignBoostPackagesProps {
  boostCampaignProducts: Perhaps<(Product & { priceDiff?: number })[]>
  onSelectProduct?: any
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  widestAvailablePackageSize: any
  selected: string | null
  Component: any
}

export const CampaignBoostPackages: FC<
  CampaignBoostPackagesProps & PuxItemProps
> = ({
  boostCampaignProducts,
  selected,
  setSelected,
  widestAvailablePackageSize,
  ...puxItemProps
}) => {
  const xs = useBreakpoint("xs", { useMax: true })

  return (
    <>
      {!!boostCampaignProducts?.length && (
        <PuxSheet style={{ width: "100%", padding: xs ? "20px" : "30px" }}>
          <div
            className={classNames(css.campaignProductsContainer, {
              [css.campaignProductsContainerSingle]:
                boostCampaignProducts.length === 1,
              [css.campaignProductsContainerMultiple]:
                boostCampaignProducts.length > 1,
            })}
          >
            {boostCampaignProducts.map((product) => {
              return (
                <ProductItemCampaign
                  key={product?.id}
                  product={product}
                  onSelectProduct={setSelected}
                  {...puxItemProps}
                />
              )
            })}
          </div>
        </PuxSheet>
      )}
    </>
  )
}

/**
 * @description Temporary ressurection of old campaign render
 */
export const CampaignBoostPackagesTempForEasterCampaign: FC<
  CampaignBoostPackagesProps & PuxItemProps
> = ({
  boostCampaignProducts,
  selected,
  setSelected,
  widestAvailablePackageSize,
  ...puxItemProps
}) => {
  const { i18n } = useAppTranslations()
  const xs = useBreakpoint("xs", { useMax: true })
  const sm = useBreakpoint("sm")

  return (
    <>
      {!!boostCampaignProducts?.length && (
        <PuxSheet
          color={"dark"}
          style={{ width: "100%", padding: xs ? "20px" : "30px" }}
        >
          <span
            style={{
              display: "block",
              textAlign: "center",
              fontWeight: 700,
              paddingBottom: "20px",
            }}
          >
            {(boostCampaignProducts[0] as any)?.campaign} kr{" "}
            <span style={{ fontSize: "2.5rem" }}>
              {i18n.format(boostCampaignProducts[0]?.price, "price")}
            </span>
          </span>
          {boostCampaignProducts?.map((product) => {
            return (
              <ProductItem
                key={product?.id}
                product={product}
                isSelected={product.id === selected}
                xs={!sm}
                setSelected={setSelected}
                widestAvailablePackageSize={widestAvailablePackageSize}
                {...puxItemProps}
              />
            )
          })}
        </PuxSheet>
      )}
    </>
  )
}
