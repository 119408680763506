import { useContext } from "react"
import { FaroClientContext } from "./FaroClientContext"


export const useFaroClient = () => {
  const faroClient = useContext(FaroClientContext)
  return faroClient
  // if (window.Cypress) {
  //   return
  // }
  // if (window.playwright) {
  //   return
  // }
  // if (_IS_TEST) {
  //   return
  // }
  // if (phoneroConfig.collectorDisabled) {
  //   console.debug("Faro is disabled explicitly")
  //   return
  // }
  // if (!phoneroConfig.collectorUrl) {
  //   console.debug("Faro is disabled since there is no collectorUrl")
  //   return
  // }
  // if (!phoneroConfig.collectorApiKey) {
  //   console.debug("Faro is disabled since there is no collectorApiKey")
  //   return
  // }
  // const client = initializeFaro()
  // if (!client) {
  //   console.debug("Faro is disabled for an unknown reason")
  //   return
  // }
  // return client
}