import React, { FC, useEffect, useState } from "react"
import { PuxContent, PuxLoading } from "@phonero/pux-react"
import { HelpfulError, HelpfulSuggestion } from "@phonero/common-ux"

export const LoadingPage: FC = () => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [showDelay, setShowDelay] = useState(false)
  const [timeout, setShowTimeout] = useState(false)

  useEffect(() => {
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 500)
    const delayTimer = setTimeout(() => setShowDelay(true), 9000)
    const timeoutTimer = setTimeout(() => setShowTimeout(true), 28000)
    return () => {
      clearTimeout(spinnerTimer)
      clearTimeout(delayTimer)
      clearTimeout(timeoutTimer)
    }
  }, [])

  if (timeout) {
    throw new HelpfulError(
      "Forespørselen gikk ut på tid.",
      "Vennligst prøv igjen senere",
      [
        HelpfulSuggestion.NavigateHome,
        HelpfulSuggestion.IDPAccount,
        HelpfulSuggestion.ContactSupport,
      ]
    )
  }
  return (
    <PuxContent>
      {showSpinner && <PuxLoading isOpen message="Vent litt..." />}
      {showDelay && (
        <PuxLoading
          isOpen
          message="Hmm, dette tok visst litt lenger enn vi trodde..."
        />
      )}
    </PuxContent>
  )
}
