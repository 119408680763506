import { allLogLevels, BaseInstrumentation, BrowserConfig, ConsoleInstrumentationOptions, Faro, LogLevel, Metas, ReactRouterV4V5Config, ViewInstrumentation } from "@grafana/faro-react";
import { Route } from "react-router-dom";
import objectPath from "object-path";
import { B3InjectEncoding, B3Propagator } from "@opentelemetry/propagator-b3";
import { initializeFaro as coreInit, getWebInstrumentations, ReactIntegration, ReactRouterVersion } from "@grafana/faro-react";

import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { FaroTraceExporter, TracingInstrumentation } from "@grafana/faro-web-tracing";
import { trace, context } from "@opentelemetry/api";
import { Context, SpanStatusCode } from "@opentelemetry/api";
import type { ReadableSpan, Span, SpanProcessor } from "@opentelemetry/sdk-trace-base";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { toSafeJSON } from "../util/circularReplacer";
import { serialize } from "./serialize";
import { PhoneroConfig } from "../AppConstants";

/** Treats any string starting and ending with '/' as a regex
 * Otherwise, simple a string
 * */
const asRegexOrString = (s: string) => {
  if (s.startsWith("/")) {
    try {
      return new RegExp(s.slice(1, -1));
    } catch (err) {
      console.error("collectorIgnoreUrls failed", { err, url: s });
    }
  }
  return s;
};

const matchPattern = (p: string, against: string) => {
  const pattern = asRegexOrString(p);
  if (!pattern) {
    return false;
  }
  if (pattern instanceof RegExp) {
    if (pattern.test(against)) {
      return true;
    }
  } else if (against.includes(pattern)) {
    return true;
  }
  return false;
};

export function initializeFaro(phoneroConfig: PhoneroConfig, buildInfo: any): Faro {
  // const API_KEY = "observe"
  const NAME = phoneroConfig.collectorApplicationName || "dpw";
  const VERSION = buildInfo.tag;
  const ENVIRONMENT = buildInfo.branch;
  const ignoreUrls = [
    /data:/,
    phoneroConfig.collectorUrl,

    ...(phoneroConfig.collectorIgnoreUrls?.map(asRegexOrString) || []),
    "https://firestore.googleapis.com/v1/projects/ditt-phonero-prod/databases/(default)/documents/maintenance-dev/status",
    "https://idp.dev.phonero.io/auth/realms/phonero/protocol/openid-connect/token",
  ].filter(Boolean) as (string | RegExp)[];
  //const propagateTraceHeaderCorsUrls = [/.+/g];
  const propagateTraceHeaderCorsUrls = [/https:\/\/api\.dev\.phonero\.io\/\.*/g, /https:\/\/api\.phonero\.no\/\.*/g];

  // This was added since as of v1.0.0, there i sno way to add anything to the spanc globally
  // except for the sessionID
  // https://github.com/grafana/faro-web-sdk/issues/116#issuecomment-1445969453
  // Copied code from https://github.com/grafana/faro-web-sdk/blob/109-otel-transport/packages/web-tracing/src/sessionSpanProcessor.ts#L17
  // and customized
  class CustomFaroSessionSpanProcessor implements SpanProcessor {
    constructor(private processor: SpanProcessor, private metas: Metas) {}

    forceFlush(): Promise<void> {
      return this.processor.forceFlush();
    }

    onStart(span: Span, parentContext: Context): void {
      const { session, user, page, view } = this.metas.value;

      if (session?.id) {
        // should be the subscription-id.
        span.setAttribute("session_id", session.id);
      }
      if (user?.id) {
        span.setAttribute("enduser.id", serialize(user.id, false));
      }
      if (phoneroConfig.collectorExtraAnnotation && phoneroConfig.collectorExtraAnnotation) {
        for (const [label, objPathStr] of Object.entries(phoneroConfig.collectorExtraAnnotation)) {
          const value = objectPath.get(this.metas.value, objPathStr);
          if (value === undefined) {
            continue;
          }

          const val = serialize(value, false);
          span.setAttribute(label, val);
        }
      }
      if (page?.url) {
        const url = new URL(page?.url);
        span.setAttribute("http_url_path", url.pathname);
        span.setAttribute("http_url_hash", url.hash);
        span.setAttribute("http_url_search", url.search);
      }
      if (view?.name) {
        span.setAttribute("view.name", view.name);
      }

      this.processor.onStart(span, parentContext);
    }

    onEnd(span: ReadableSpan): void {
      this.processor.onEnd(span);
    }

    shutdown(): Promise<void> {
      return this.processor.shutdown();
    }
  }
  /** Custom console instrumentations
   * Based on default faro-implementation, but has a few additions:
   * Serializes items in the log-statement, and pushes objects onto the context with a key
   * */
  class ConsoleInstrumentation extends BaseInstrumentation {
    readonly name = "@phonero/instrumentation-console";
    readonly version = "1.0.0";

    static defaultDisabledLevels: LogLevel[] = [LogLevel.DEBUG, LogLevel.TRACE, LogLevel.LOG];

    constructor(private options: ConsoleInstrumentationOptions = {}) {
      super();
    }

    initialize() {
      this.logDebug("Initializing\n", this.options);

      allLogLevels
        .filter((level) => !(this.options.disabledLevels ?? ConsoleInstrumentation.defaultDisabledLevels).includes(level))
        .forEach((level) => {
          const context = this.api.getTraceContext() || {};
          /* eslint-disable-next-line no-console */
          console[level] = (message: string, ...args: unknown[]) => {
            if (phoneroConfig.collectorIgnoreConsole) {
              const msg = message + toSafeJSON(args);
              for (const p of phoneroConfig.collectorIgnoreConsole) {
                const match = matchPattern(p, msg);
                if (match) {
                  console.debug("console-line was dropped because of pattern", {
                    pattern: p,
                    match,
                  });
                  this.unpatchedConsole[level](message, ...args);
                  return;
                }
              }
            }
            let error: Error | null = null;
            try {
              for (const [i, arg] of args.entries()) {
                switch (typeof arg) {
                  case "string":
                  case "number":
                  case "boolean":
                  case "undefined":
                    context["arg_" + i] = serialize(arg, true);
                    break;
                  case "function":
                    break;
                  case "object":
                    if (arg instanceof Error) {
                      for (const k of Object.getOwnPropertyNames(arg)) {
                        context["arg_+" + i + "_" + k] = serialize(arg[k], true);
                      }
                      context["arg_+" + i + "_name"] = serialize(arg.name, true);
                      continue;
                    }
                    if (!arg || Array.isArray(arg)) {
                      context["arg_" + i] = serialize(arg, true);
                      continue;
                    }
                    for (const k of Object.getOwnPropertyNames(arg)) {
                      if (k === "skipSend" && arg[k] === true) {
                        this.unpatchedConsole[level](message, ...args);
                        return;
                      }
                      const stack = arg[k];
                      if (k === "stack" && stack) {
                        const stackTraceParse = this.api.getStacktraceParser();
                        context[k] = serialize(stackTraceParse?.(stack) || stack, true);
                      }
                    }
                }
              }
              switch (level) {
                case LogLevel.ERROR:
                case LogLevel.WARN:
                  if (!error) {
                    error = new Error("Fake error");
                    const stackTraceParse = this.api.getStacktraceParser();
                    if (stackTraceParse) {
                      const stack = stackTraceParse(error);
                      context["stack"] = serialize(stack, true);
                    } else {
                      const stack = error.stack?.split("\n").slice(2).join("\n") || "";
                      if (stack) {
                        context["stack"] = stack;
                      }
                    }
                  }
              }
              this.api.pushLog([message, ...(!!error ? [new Error().stack] : [])], { level: level, context });
            } catch (err) {
              this.logError(err);
            } finally {
              this.unpatchedConsole[level](message, ...args);
            }
          };
        });
    }
  }

  const fetchInstrumentation = new FetchInstrumentation({
    ignoreUrls,
    ignoreNetworkEvents: true,
    propagateTraceHeaderCorsUrls,
    applyCustomAttributesOnSpan: (span, request, response) => {
      if (request.body) {
        const json = JSON.parse(request.body.toString());
        if (json && typeof json == "object" && "operationName" in json && typeof json.operationName === "string") {
          span.setAttribute("graphql.operationName", json.operationName);
          span.updateName(`operationName ${json.operationName}`);
        }
      }
      if (response.status) {
        if (response.status < 400) {
          span.setStatus({ code: SpanStatusCode.OK });
        } else {
          span.setStatus({ code: SpanStatusCode.ERROR });
        }
      }
    },
  });
  const xmlHttpRequestInstrumentation = new XMLHttpRequestInstrumentation({
    ignoreUrls,
    propagateTraceHeaderCorsUrls,
  });

  const router: ReactRouterV4V5Config = {
    version: ReactRouterVersion.V5,
    dependencies: {
      history: window.history,
      Route,
    },
  };

  const sessionID = new URLSearchParams(window.location.search).get("sessionID");
  //const tokenData = getTokenData();
  const browserConfig: BrowserConfig = {
    url: phoneroConfig.collectorUrl,
    apiKey: phoneroConfig.collectorApiKey,
    ...(Object.keys(phoneroConfig.collectorBeforeSendObjPatterns || {}).length && {
      // return null for the item to remove it
      beforeSend: (item) => {
        if (!phoneroConfig.collectorBeforeSendObjPatterns) {
          return item;
        }
        for (const [path, patterns] of Object.entries(phoneroConfig.collectorBeforeSendObjPatterns)) {
          const objp = objectPath.get(item, path);
          if (!objp) {
            continue;
          }
          for (const p of patterns) {
            if (matchPattern(p, objp)) {
              console.debug("TransportItem was dropped because of pattern in beforeSend-hook ", { pattern: p, objp, item });
              return null;
            }
          }
        }
        return item;
      },
    }),
    ignoreErrors: [...(phoneroConfig.collectorIgnoreErrors?.map(asRegexOrString) || [])],
    //apiKey: API_KEY,
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: false,
        captureConsoleDisabledLevels: [LogLevel.TRACE, LogLevel.DEBUG, LogLevel.LOG],
      }),
      new ConsoleInstrumentation(),
      new ReactIntegration({
        router,
      }),
    ],
    app: {
      name: NAME,
      version: VERSION,
      environment: ENVIRONMENT,
    },
    // ...(tokenData && {
    //   user: {
    //     username: tokenData.preferred_username,
    //     id: tokenData.subscriptionId,
    //   },
    // }),
    ...(sessionID && {
      session: {
        id: sessionID,
      },
    }),
  };
  browserConfig.instrumentations = browserConfig.instrumentations?.filter((ins) => {
    switch (ins.constructor.name) {
      // DocumentLoadInstrumentation is huge, and I don't think we care about it
      case DocumentLoadInstrumentation.name:
        return false;
      // we dont really need to track each user-click
      case ViewInstrumentation.name:
        return false;
    }
    return true;
  });

  const faro = coreInit(browserConfig);

  if (faro) {
    const spanProcessor = new CustomFaroSessionSpanProcessor(new BatchSpanProcessor(new FaroTraceExporter({ api: faro.api })), faro.metas) as any;

    faro.instrumentations.add(
      new TracingInstrumentation({
        resourceAttributes: {
          "service.vcs_hash": buildInfo.hash,
        },
        propagator: new B3Propagator({
          injectEncoding: B3InjectEncoding.MULTI_HEADER,
        }),
        spanProcessor,
        instrumentations: [fetchInstrumentation, xmlHttpRequestInstrumentation],
      })
    );
    // TODO: add ingore patterns here
    faro.transports.addIgnoreErrorsPatterns();
    // faro.transports.
    faro.api.initOTEL(trace, context);
    console.info("session started/continued");
  } else {
    console.debug("Faro not initialized");
  }

  return faro;
}
