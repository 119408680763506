import {
  AsAgreementFragment,
  AsDataPackageOfferingProductFragment,
  AsOfferingFragment,
  ChangeSubscriptionDocument,
} from "./DataPackageQueries.generated"
import {
  ContractType,
  DataPackageProduct,
  OfferingContractCategory,
  OrderStatusId,
  OrderType,
} from "@phonero/common-graphql/types"
import { IDataProduct } from "./IDataProduct"
import { OrdersDocument, OrdersQueryVariables } from "../queries"
import {
  convertToMegaBytes,
  getWidestAvailablePackageSize,
  graphqlQueryToast,
  sizeMultiplier,
  subscriptionName,
  useAppQuery,
  useAppTranslations,
} from "../util"
import { toPackageSizeFlags } from "./PackageSizeFlag"

export const ordersVariables = (
  subscriptionId: string
): OrdersQueryVariables => ({
  first: 1,
  withDetails: true,
  where: {
    status: {
      nin: [OrderStatusId.Cancelled, OrderStatusId.Completed],
    },
    orderTypeId: {
      in: [OrderType.Changedatapackage],
    },
  },
  subscriptionID: subscriptionId,
})

export const useChangeSubscriptionData = ({
  subscriptionId,
}: {
  subscriptionId: string
}) => {
  const { t } = useAppTranslations()

  const { error: ordersError, ...orders } = useAppQuery(OrdersDocument, {
    skip: !subscriptionId,
    variables: ordersVariables(subscriptionId),
  })

  const {
    error: subscriptionPackagesError,
    data: subscriptionPackagesData,
    loading: subscriptionPackagesLoading,
  } = useAppQuery(ChangeSubscriptionDocument, {
    skip: !subscriptionId,
    variables: {
      offeringFilter: {
        priceChange: true,
        contractCategories: [
          OfferingContractCategory.Mobile,
          OfferingContractCategory.Mobilebroadband,
        ],
      },
      subscriptionId,
      validForSubscription: subscriptionId,
    },
    fetchPolicy: "cache-and-network",
    ...graphqlQueryToast(t, "getDataPackages"),
  })

  const mobileSubscription = subscriptionPackagesData?.mobileSubscription
  const currentContractType = mobileSubscription?.contractType
  const activeDataPackage = mobileSubscription?.dataPackages?.length
    ? mobileSubscription.dataPackages[0]
    : undefined
  const showLoading =
    (!subscriptionPackagesData && subscriptionPackagesLoading) ||
    (!orders.data && orders.loading)
  const availableProductList = new Array<IDataProduct>()
  subscriptionPackagesData?.mobileSubscription?.customer?.agreements?.forEach(
    (a: AsAgreementFragment) => {
      a.offerings?.forEach((o: AsOfferingFragment) => {
        if (o?.contractType === currentContractType) {
          const subscriptionProduct = o.subscriptions?.length
            ? o.subscriptions[0]
            : { price: 0 }
          o?.dataProducts?.forEach(
            (p: AsDataPackageOfferingProductFragment) => {
              if (
                p.offeringId !==
                  mobileSubscription?.customerAgreementOfferingId ||
                p?.productId !== activeDataPackage?.productId
              ) {
                availableProductList.push({
                  ...p,
                  price: p.price + subscriptionProduct.price,
                  name: `${subscriptionName(o, p, false)}`,
                  sizeType: (p.sizeType ?? "MB") as keyof typeof sizeMultiplier,
                  packageSizeFlags: toPackageSizeFlags(p.sizeFlags),
                  sizeInMb: convertToMegaBytes(
                    p.size,
                    (p.sizeType ?? "MB") as keyof typeof sizeMultiplier
                  ),
                  offeringName: o.name ?? "",
                  offeringId: o.id,
                  agreementId: parseInt(a.id),
                  contractType: o.contractType,
                  description: p.description ?? "",
                })
              }
            }
          )
        }
      })
    }
  )

  const { changeOrderApprovalRequired = false } =
    subscriptionPackagesData?.mobileSubscription || {}
  const previousActiveOrderIfAny =
    orders.data?.mobileSubscription?.orders?.nodes?.[0]
  const orderCount = orders.data?.mobileSubscription?.orders?.totalCount
  const orderExists = typeof orderCount === "number" && orderCount > 0

  const orderedProduct =
    (!!previousActiveOrderIfAny?.requestedProductId &&
      (availableProductList?.find((p) => {
        if (
          String(p.id) === String(previousActiveOrderIfAny.requestedProductId)
        ) {
          return true
        }

        return false
      }) as unknown as DataPackageProduct)) ||
    null
  // FIXME: hack for name for product
  const orderedName = subscriptionName(
    subscriptionPackagesData?.mobileSubscription,
    {
      ...previousActiveOrderIfAny,
      ...orderedProduct,
    }
  )

  const currentDataProduct: IDataProduct | undefined = activeDataPackage
    ? {
        ...activeDataPackage,
        price: activeDataPackage.price + mobileSubscription.price,
        name: `${subscriptionName(
          mobileSubscription,
          activeDataPackage,
          false
        )}`,
        description: activeDataPackage.description ?? "",
        sizeType: "MB" as keyof typeof sizeMultiplier,
        sizeInMb: convertToMegaBytes(activeDataPackage.packageSize ?? 0, "MB"),
        packageSizeFlags: toPackageSizeFlags(activeDataPackage.sizeFlags),
        offeringId: mobileSubscription?.offerings?.length
          ? mobileSubscription.offerings[0].name
          : "",
        offeringName: mobileSubscription?.offerings?.length
          ? mobileSubscription.offerings[0].name
          : "",
        agreementId: parseInt(mobileSubscription.agreementId),
        contractType: ContractType.Mobile,
        size: activeDataPackage.packageSize ?? 0,
        priceId: 0,
        productId: activeDataPackage.productId,
      }
    : undefined

  const widestAvailablePackageSize =
    getWidestAvailablePackageSize(availableProductList)
  return {
    subscriptionPackagesData,
    subscriptionPackagesLoading,
    availableProductList,
    showLoading,
    changeOrderApprovalRequired,
    orderExists,
    orderedName,
    ordersError,
    subscriptionPackagesError,
    widestAvailablePackageSize,
    previousActiveOrderIfAny,
    currentDataProduct,
    mobileSubscription,
  }
}
