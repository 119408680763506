const circularReplacer = (ignoreKeys?: Set<string>) => {
  const seen = new WeakSet()
  return (key: any, value: any) => {
    if (ignoreKeys && ignoreKeys.has(key)) {
      return
    }
    if (typeof value === "object" && value !== null) {
      // Dont jsonify dom-elements since they are huge.
      if (value instanceof Element) {
        const path = getPath(value)
        return path
      }
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

export default circularReplacer

export const toSafeJSON = (
  data: any,
  pretty: boolean | number = 2,
  /** a set of keys that will be ignored from the object (deeply) */
  ignoreKeys?: Set<string>
) => {
  try {
    let space: string | number | undefined
    switch (typeof pretty) {
      case "boolean":
        space = 2
        break
      case "number":
      case "string":
        space = pretty
    }
    return JSON.stringify(
      data,
      circularReplacer(ignoreKeys),
      space
      // (typeof pretty === 'boolean' && pretty) ? 2 : pretty ?? undefined
    )
  } catch (err) {
    console.error("failed to serialize object", err, data)
  }
  return "failed to serialize"
}
function getPath(el: Element) {
  let act = el.nodeName
  el.classList.forEach((cl) => (act += "." + cl))
  if (el.id) act += "#" + el.id

  if (!el.id && el.parentElement) {
    let res = getPath(el.parentElement)
    res.push(act)
    return res
  } else {
    return [act]
  }
}
