import { ApolloError } from "@apollo/client"
import { PredefinedColors } from "@phonero/pux-core"
import { closeSmall, externalLink, warning } from "@phonero/pux-icons/icons"
import {
  PuxButton,
  PuxIcon,
  PuxSheet,
  PuxText,
  // useAppTranslation,
} from "@phonero/pux-react"
import classNames from "classnames"
import { printError } from "graphql"
import * as React from "react"
import { useAppTranslations } from "../util"
import useOfflineStatus from "../util/useOfflineStatus"
import css from "./styles.module.scss"
import { extractMessageFromApolloError } from "../util/handleGraphqlError"
import { useAppConstants } from "../AppConstants"
import useTimedClickCounter from "../util/useTimedClickCounter"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const markdownPostprocessor = "markdownPostprocessor"

const templates = {
  darkBox: {
    wrapper: PuxSheet,
    wrapperProps: {
      color: "dark",
    },
    textProps: {
      color: "danger",
    },
  },
} as const

type InlineErrorProps = JSX.IntrinsicElements["div"] & {
  error: Error | undefined | null | false
  wrapper?: any
  template?: keyof typeof templates
  /** This should ideally be a unique code that can be used for further investigation  */
  code: string
  context?: string
  variation?: "slim"
  color?: PredefinedColors
}

export function InlineError({
  error,
  code,
  context,
  variation,
  wrapper: Wrapper,
  template: templateKey = "darkBox",
  ...rest
}: InlineErrorProps) {
  const { isDevEnv } = useAppConstants()
  const [isHidden, toggleIsHidden] = React.useState(false)
  const [showDetails, setShowDetails] = React.useState(
    !!isDevEnv && variation !== "slim"
  )
  const { onClick } = useTimedClickCounter({
    timeoutMS: 1000,
    onCount: (clicks, reset) => {
      if (clicks > 5) {
        setShowDetails(true)
        reset()
      }
    },
  })

  const showLokiLinksInError = useFeatureIsOn(
    "selfservice-show-loki-links-in-error"
  )

  React.useEffect(() => {
    toggleIsHidden(false)
  }, [error])

  const { t } = useAppTranslations()
  const { online } = useOfflineStatus()
  if (!error) {
    return null
  }
  if ("networkError" in error) {
    if (error.networkError && !online) {
      return null
    }
  }
  const apolloError = (error instanceof ApolloError && error) || undefined
  const firstError = apolloError?.graphQLErrors[0] || undefined
  const links: { loki: string } | any =
    ((showLokiLinksInError || showDetails) && firstError?.extensions?.links) ||
    {}
  const operationName: any = firstError?.extensions?.["operation-name"]
  if (!code && operationName) {
    code = operationName as any
  }
  const errorMessage = extractMessageFromApolloError(error as any)
  let [errorCode, _errorField] = (
    ((!!error &&
      error instanceof ApolloError &&
      !!error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions?.code) as any) || ""
  ).split(":")
  // not all validation-errors from the api has a code.
  // attempt to parse valid
  if (!errorCode && errorMessage.includes("must have a value")) {
    const [a] = errorMessage.split(" must")
    _errorField = a
    errorCode = "validation"
  }
  const translated = errorCode
    ? error.message
    : t("gqlError.err", {
        context: context || code,
        code,
        errorMessage,
        shortMessage: shortenMessage(errorMessage),
        postProcess: markdownPostprocessor,
      })
  const { wrapper, wrapperProps, textProps }: typeof templates.darkBox =
    (!!templateKey && templates[templateKey]) || ({} as any)
  if (!Wrapper) {
    Wrapper = wrapper || React.Fragment
  }
  const showCloseIcon = variation !== "slim"
  return (
    <>
      {!isHidden && (
        <Wrapper
          onClick={onClick}
          style={{
            ...(wrapperProps && (wrapperProps as any).style),
            ...rest.style,
          }}
          {...wrapperProps}
          {...rest}
          className={classNames(
            css.wrapper,
            css[errorCode],
            variation === "slim" && css.variationSlim,
            rest.className
          )}
        >
          {showCloseIcon && (
            <div
              style={{
                textAlign: "end",
              }}
            >
              <PuxIcon
                style={{ cursor: "pointer" }}
                size="small"
                icon={closeSmall}
                onClick={() => toggleIsHidden(true)}
              ></PuxIcon>
            </div>
          )}
          <div>
            <PuxIcon
              size="small"
              icon={warning}
              style={{ marginBottom: -6, marginRight: 5 }}
              {...textProps}
            ></PuxIcon>
            <PuxText {...textProps}>{translated}</PuxText>
            {showDetails && (
              <DetailedApolloError error={error as any} code={code} />
            )}
          </div>
          {!!links?.loki && (
            <a
              href={links.loki}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "inherit",
                marginBlockStart: 12,
                display: "block",
              }}
            >
              <PuxIcon icon={externalLink} style={{ height: "0.7em" }} />
              Loki-Trace for dette kallet på {operationName}
            </a>
          )}
        </Wrapper>
      )}
    </>
  )
}

export const shortenMessage = (
  s: string,
  maxLength = 40,
  splitChars = ["\n", ":", ";", "."]
) => {
  if (!s) {
    return ""
  }
  s = s.trim()
  if (s.length <= maxLength) {
    return s
  }
  for (const char of splitChars) {
    const firstSentence = s.split(char)[0]
    if (firstSentence.length < maxLength) {
      return firstSentence
    }
  }
  return s.slice(0, maxLength)
}

const DetailedApolloError = ({
  error,
  code,
}: {
  error: Error
  code: string
}) => {
  const [expanded, setExpanded] = React.useState(false)

  if (!error) {
    return null
  }

  if (!expanded) {
    return (
      <PuxButton
        onClick={() => setExpanded(true)}
        size="small"
        color="light"
        fill="clear"
        expand="full"
        style={{ position: "_absolute", left: 8, bottom: 8 }}
      >
        Vis detaljert teknisk informasjon
      </PuxButton>
    )
  }

  if (!(error instanceof ApolloError)) {
    return (
      <div>
        <code>
          Code: {code} - {error.message}
        </code>
      </div>
    )
  }

  return (
    <div>
      {!error.graphQLErrors && (
        <p>
          Code: {code}
          {error.message}
        </p>
      )}
      {code}
      {!!error.networkError && typeof error.networkError === "object" && (
        <div>
          {error.networkError.name}
          <ul>
            {"result" in error.networkError &&
              typeof error.networkError.result !== "string" &&
              error.networkError.result.errors.map((m: any, i: number) => (
                <li key={i}>
                  {typeof m === "string"
                    ? m
                    : "message" in m
                    ? m.message
                    : JSON.stringify(m)}
                </li>
              ))}
          </ul>
        </div>
      )}
      {errorDeduplicator(error.graphQLErrors).map((err, i) => {
        const msg = err[0].message
        const p = printError(err[0])
        const internalErr: any = err[0]?.extensions?.internal
        return (
          <div key={i}>
            {msg}
            {err.length > 1 ? ` (${err.length})` : ""}
            {msg !== p && p}
            {!!internalErr && (
              <details>
                <summary>Internal error</summary>
                <pre
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "start",
                    overflow: "auto",
                    marginInline: -20,
                    paddingBlockEnd: 20,
                  }}
                >
                  {internalErr}
                </pre>
              </details>
            )}
          </div>
        )
      })}
      {(error as any).clientErrors?.map((err: Error, i: number) => {
        return <li key={i}>{err.message}</li>
      })}
    </div>
  )
}

function errorDeduplicator<T extends Error>(
  errs: readonly T[] | undefined
): readonly T[][] {
  if (!errs) {
    return []
  }
  const unique: Record<string, Error[]> = {}

  for (const err of errs) {
    unique[err.message] = [...(unique[err.message] || []), err]
  }

  return Object.values(unique) as any
}

/** Shorthand for InlineError, which takes in multiple errors as a Record.
 * The key in the record is the `code`, while the value is the Error
 *
 * */
export const InlineErrors = ({
  errors,
  ...rest
}: {
  errors: Record<string, Error | undefined>
} & Omit<InlineErrorProps, "code" | "error">) => {
  const entries = Object.entries(errors)
  if (!entries.length) {
    return null
  }
  return (
    <>
      {entries.map(([code, error]) => (
        <InlineError key={code} code={code} error={error} {...rest} />
      ))}
    </>
  )
}
