import { OTELApi } from "@grafana/faro-web-sdk"
import { useEffect, useRef } from "react"
import { useFaroClient } from "."

/** creates a span for a trace.
 * The span is closed on completion
 * */
export const useSpan = (traceName: string, spanName: string) => {
  const ref = useRef<
    | {
        otel: OTELApi
        span: ReturnType<ReturnType<OTELApi["trace"]["getTracer"]>["startSpan"]>
        tracer: ReturnType<OTELApi["trace"]["getTracer"]>
        context: OTELApi["context"]
      }
    | undefined
  >()
  const faroClient = useFaroClient()
  useEffect(() => {
    const otel = faroClient?.api.getOTEL()
    if (!otel) {
      return
    }
    const { trace, context } = otel
    const tracer = trace.getTracer(traceName)
    const span = tracer.startSpan(spanName)
    ref.current = {
      otel,
      span,
      context,
      tracer,
    }

    return () => {
      span.end()
    }
  }, [spanName, traceName])
  return ref
}
