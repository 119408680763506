import { PuxAlert } from "@phonero/pux-react"
import { useState } from "react"
import { useFetchJson } from "./util/fetchJson"
import { useRegisterSW } from "virtual:pwa-register/react"

export const UpdateConfirm = () => {
  const [show, setShow] = useState<boolean>(true)
  let {
    offlineReady: [_offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.debug("SW Registered: ", r)
    },
    onRegisterError(error) {
      console.error("SW registration error", error)
    },
    onNeedRefresh: () => {
      //manifest.refetch() // TODO:
    },
  })
  const close = () => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }
  if (!needRefresh) {
    return null
  }

  return (
    <PuxAlert
      isOpen={show}
      backdropDismiss={false}
      onDidDismiss={() => setShow(false)}
      header={"Oppdatering tilgjengelig!"}
      message={"Vil du laste inn denne versjonen nå?"}
      buttons={[
        {
          text: "Senere",
          role: "cancel",
          cssClass: "secondary",
          handler: close,
        },
        {
          text: "Ja takk",
          handler: () => {
            updateServiceWorker(true)
          },
        },
      ]}
    />
  )
}
