import * as Types from '@phonero/common-graphql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SetPromotionProductMutationVariables = Types.Exact<{
  contractProductId: Types.Scalars['Int']['input'];
  isPromotionProduct: Types.Scalars['Boolean']['input'];
}>;


export type SetPromotionProductMutation = { __typename: 'Mutation', setIsPromotionProduct?: string | null };


export const SetPromotionProductDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setPromotionProduct"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contractProductId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isPromotionProduct"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setIsPromotionProduct"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contractProductId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contractProductId"}}},{"kind":"Argument","name":{"kind":"Name","value":"isPromotionProduct"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isPromotionProduct"}}}]}]}}]} as unknown as DocumentNode<SetPromotionProductMutation, SetPromotionProductMutationVariables>;