import React, { FC } from "react"
import { MobileInsuranceTermsLink } from "./MobileInsuranceTermsLink"
import { MobileInsuranceReportLink } from "./MobileInsuranceReportLink"

import css from "./MobileInsuranceLinks.module.scss"
import { MobileInsuranceCertificateLink } from "./MobileInsuranceCertificateLink"

export interface MobileInsuranceLinksProps {
  hasActiveProduct: boolean
  productId: number
  styleType: "button" | "anchor"
}

const reportAndCertificate = [24711, 24712]

export const MobileInsuranceLinks: FC<MobileInsuranceLinksProps> = (props: MobileInsuranceLinksProps) => {
  return (
    <div className={css.insuranceLinks}>
      <MobileInsuranceTermsLink productId={props.productId} styleType={props.styleType} />
      {props.hasActiveProduct && reportAndCertificate.findIndex((rac) => rac === props.productId) !== -1 && (
        <>
          <MobileInsuranceReportLink productId={props.productId} styleType={props.styleType} />
          <MobileInsuranceCertificateLink productId={props.productId} styleType={props.styleType} />
        </>
      )}
    </div>
  )
}
