export function getWidestAvailablePackageSize(
  availableDataPackages?: Array<any> | null
) {
  return (
    availableDataPackages?.reduce((r, p) => {
      if (!p || !("size" in p)) {
        return r
      }
      const n = Number(p.size)
      const len = String(p.size).length
      if (isNaN(n) || r > len) {
        return r
      }
      if (len > 4) {
        // in case something goes wrong, we dont want to have this be wider than 4 characters.
        return 4
      }
      return len
    }, 1) || 1
  )
}
