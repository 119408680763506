/** opens a link in the in-app-browser if available. Fallbacks to regular window.open */
import { Browser } from "@capacitor/browser"

const closeUrls = [
  "http://localhost",
  "https://ditt.phonero.no",
  "https://ditt-test.phonero.no",
]

export async function openLink(
  url: string,
  target = "_blank",
  options: CordovaOptions | string = defaultCordovaOptions
) {
  // const opts =
  //   typeof options === "string" ? options : formatCordovaOptions(options)
  // if (!window.cordova) {
  //   return window.open(url, target)
  // }
  // var appBrowserRef = window.cordova.InAppBrowser.open(url, target, opts)
  // if (appBrowserRef)
  //   //  Since we use inAppBrowser, an event listener is added to check when we get should close the browser and get back to this app.
  //   appBrowserRef.addEventListener("loadstart", (e: InAppBrowserEvent) => {
  //     if (appBrowserRef && closeUrls.some((url) => e.url.startsWith(url))) {
  //       appBrowserRef.close()
  //     }
  //   })
  // return appBrowserRef
  return await Browser.open({ url, windowName: target })

  //return window.open(url, target)
}

type CordovaOptions = {
  location?: boolean
  hidden?: boolean
  beforeload?: "get" | "post" | "yes"
  clearcache?: boolean
  clearsessioncache?: boolean
  closebuttoncaption?: string
  footer?: boolean
  footercolor?: string
  hardwareback?: string
  hidenavigationbuttons?: boolean
  hideurlbar?: boolean
  navigationbuttoncolor?: string
  toolbarcolor?: string
  lefttoright?: boolean
  zoom?: boolean
  mediaPlaybackRequiresUserAction?: boolean
  shouldPauseOnSuspend?: boolean
  useWideViewPort?: boolean
  fullscreen?: boolean
  // ios-only
  cleardata?: boolean
  closebuttoncolor?: string
  disallowoverscroll?: boolean
  enableViewportScale?: boolean
  allowInlineMediaPlayback?: boolean
  presentationstyle?: "pagesheet" | "formsheet" | "fullscreeen"
  transitionstyle?: "fliphorizontal" | "crossdissolve" | "coververtical"
  toolbarposition?: "top" | "bottom"
  hidespinner?: boolean
  toolbartranslucent?: boolean
}

const cordovaOptions: CordovaOptions = {
  // footer: true,
  // footercolor: "#33ffdd",
  location: false,
  closebuttoncaption: "Lukk",
  //   closebuttoncaption: "bob",
  //   closebuttoncolor: "#D888ff",
  hidenavigationbuttons: false,
  hidespinner: false,
  hidden: false,
  hideurlbar: false,
  fullscreen: true,
  //   toolbartranslucent: false,
}
export const formatCordovaOptions = function (cordovaOptions: CordovaOptions) {
  return Object.keys(cordovaOptions)
    .reduce(function (options, optionName) {
      let value = cordovaOptions[optionName]
      switch (value) {
        case true:
          value = "yes"
          break
        case false:
          value = "no"
          break

        default:
          break
      }
      options.push(optionName + "=" + value)
      return options
    }, [] as String[])
    .join(",")
}
const defaultCordovaOptions = formatCordovaOptions(cordovaOptions)

// copied from https://github.com/dbiele/TypeScript-Cordova-SystemJS-Angular2-beta/blob/master/scripts/typings/cordova/plugins/InAppBrowser.d.ts

/**
 * The object returned from a call to window.open.
 * NOTE: The InAppBrowser window behaves like a standard web browser, and can't access Cordova APIs.
 */
export interface InAppBrowser {
  open: typeof window.open
  onloadstart: (type: InAppBrowserEvent) => void
  onloadstop: (type: InAppBrowserEvent) => void
  onloaderror: (type: InAppBrowserEvent) => void
  onexit: (type: InAppBrowserEvent) => void

  // addEventListener overloads
  /**
   * Adds a listener for an event from the InAppBrowser.
   * @param type      the event to listen for
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  addEventListener(
    type: "loadstart",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Adds a listener for an event from the InAppBrowser.
   * @param type      the event to listen for
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  addEventListener(
    type: "loadstop",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Adds a listener for an event from the InAppBrowser.
   * @param type      the event to listen for
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  addEventListener(
    type: "loaderror",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Adds a listener for an event from the InAppBrowser.
   * @param type      the event to listen for
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  addEventListener(
    type: "exit",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Adds a listener for an event from the InAppBrowser.
   * @param type      the event to listen for
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  addEventListener(
    type: string,
    callback: (event: InAppBrowserEvent) => void
  ): void

  // removeEventListener overloads
  /**
   * Removes a listener for an event from the InAppBrowser.
   * @param type      The event to stop listening for.
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  removeEventListener(
    type: "loadstart",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Removes a listener for an event from the InAppBrowser.
   * @param type      The event to stop listening for.
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  removeEventListener(
    type: "loadstop",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Removes a listener for an event from the InAppBrowser.
   * @param type      The event to stop listening for.
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  removeEventListener(
    type: "loaderror",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Removes a listener for an event from the InAppBrowser.
   * @param type      The event to stop listening for.
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  removeEventListener(
    type: "exit",
    callback: (event: InAppBrowserEvent) => void
  ): void

  /**
   * Removes a listener for an event from the InAppBrowser.
   * @param type      The event to stop listening for.
   *                  loadstart: event fires when the InAppBrowser starts to load a URL.
   *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
   *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
   *                  exit: event fires when the InAppBrowser window is closed.
   * @param callback  the function that executes when the event fires. The function is
   *                  passed an InAppBrowserEvent object as a parameter.
   */
  removeEventListener(
    type: string,
    callback: (event: InAppBrowserEvent) => void
  ): void

  /** Closes the InAppBrowser window. */
  close(): void

  /**
   * Displays an InAppBrowser window that was opened hidden. Calling this has no effect
   * if the InAppBrowser was already visible.
   */
  show(): void

  /**
   * Injects JavaScript code into the InAppBrowser window.
   * @param script    Details of the script to run, specifying either a file or code key.
   * @param callback  The function that executes after the JavaScript code is injected.
   *                  If the injected script is of type code, the callback executes with
   *                  a single parameter, which is the return value of the script, wrapped in an Array.
   *                  For multi-line scripts, this is the return value of the last statement,
   *                  or the last expression evaluated.
   */
  executeScript(script: { code: string }, callback: (result: any) => void): void

  /**
   * Injects JavaScript code into the InAppBrowser window.
   * @param script    Details of the script to run, specifying either a file or code key.
   * @param callback  The function that executes after the JavaScript code is injected.
   *                  If the injected script is of type code, the callback executes with
   *                  a single parameter, which is the return value of the script, wrapped in an Array.
   *                  For multi-line scripts, this is the return value of the last statement,
   *                  or the last expression evaluated.
   */
  executeScript(script: { file: string }, callback: (result: any) => void): void

  /**
   * Injects CSS into the InAppBrowser window.
   * @param css       Details of the script to run, specifying either a file or code key.
   * @param callback  The function that executes after the CSS is injected.
   */
  insertCSS(css: { code: string }, callback: () => void): void

  /**
   * Injects CSS into the InAppBrowser window.
   * @param css       Details of the script to run, specifying either a file or code key.
   * @param callback  The function that executes after the CSS is injected.
   */
  insertCSS(css: { file: string }, callback: () => void): void
}

interface InAppBrowserEvent extends Event {
  /** the eventname, either loadstart, loadstop, loaderror, or exit. */
  type: string
  /** the URL that was loaded. */
  url: string
  /** the error code, only in the case of loaderror. */
  code: number
  /** the error message, only in the case of loaderror. */
  message: string
}
