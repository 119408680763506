import { PuxButton, PuxIcon, PuxSheet, useBreakpoint } from "@phonero/pux-react"
import css from "./PromotionPhoneroCloud.module.scss"
import { cloud } from "@phonero/pux-icons/icons"
import { ApiProductState } from "@phonero/common-graphql/types"
import { Product } from "./ProductItem"
import cx from "classnames"
import { PhoneroCloudDetail } from "../Cloud/PhoneroCloudDetail"

// This component is used in the PromotionProducts component in the DittPhonero.
// This is used as a single promotion component
// In the future (not implemented) this component might be used in a carousel with other promotion product.
export const PromotionPhoneroCloud = ({
  product,
  mode,
  onUnPromote,
}: {
  product: Product
  mode?: "single" | "multiple"
  onUnPromote: (product: Product) => void
}) => {
  const isDesktop = useBreakpoint("md", { useMin: true })

  const icon = cloud
  const isWide = mode === "single" && isDesktop

  const getActivationMode = (state: ApiProductState | undefined) => {
    if (!state) return undefined
    switch (state) {
      case ApiProductState.Registered:
        // case ApiProductState.Available:
        return "Registered"
      case ApiProductState.Ordered:
      case ApiProductState.Activating:
      case ApiProductState.Startactivation:
        return "Activating"
      // case ApiProductState.Active:
      default:
        return undefined
    }
  }

  // simplified by reducing the number of states
  const activationMode = getActivationMode(product.state)
  if (activationMode === undefined) return <></>

  return (
    <>
      <PuxSheet className={cx(css.sheetContainer)} style={{ maxWidth: "600px" }}>
        <div className={cx(css.gridContainer)}>
          <div className={cx(css.gridCloseContainer)}>
            <PuxButton
              onClick={() => onUnPromote(product)}
              style={{ padding: 0, fontSize: "25px" }}
              fill="clear"
              strong
              color="dark"
              slot="end"
            >
              X
            </PuxButton>
          </div>
          <div className={cx(css.gridContentContainer, isWide ? css.contentWide : css.contentSlim)}>
            <div className={cx(isWide ? css.cloudWide : "")}>
              {!!icon && mode === "single" && (
                <PuxIcon icon={icon} size="large" className={isWide ? css.iconBig : ""} color="primary" />
              )}
            </div>
            <PhoneroCloudDetail
              product={product}
              displayMode={mode === "single" ? "promotion" : "promotionCarousel"}
              showPrice={true}
            />
          </div>
        </div>
      </PuxSheet>
    </>
  )
}
