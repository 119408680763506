import {
  PuxButton,
  PuxCard,
  PuxCardContent,
  PuxCardHeader,
  PuxCardTitle,
  PuxSheet,
  PuxIcon,
  useBreakpoint,
} from "@phonero/pux-react"
import { PageContent } from "../../components/layout"

import { request } from "@phonero/pux-icons/icons"
import { ArgsAsTuple } from "simplytyped"
import { useAppTranslations } from "@phonero/common-ux"
import { markdownPostprocessor } from "../../initi18n"

// FIXME: Component has a few overrides. These should be supported natively in upstream pux-library instead.

/** Contact-page, displaying contact-information, like support-links  */
export const Contact = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useAppTranslations()
  return (
    <PageContent>
      <PuxSheet
        color="dark"
        style={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          alignItems: "center",
          // minHeight: "100vh",
        }}
      >
        {children}
        <div style={{ fontSize: 25 }}>
          {t("ContactPage.SubTitle", {
            postProcess: markdownPostprocessor,
          })}
        </div>
        <PuxCard color="error" style={{ maxWidth: 768, width: "100%" }}>
          <PuxCardHeader color="dark">
            <PuxCardTitle
              color="dark"
              style={{ color: "var(--pux-color-contrast)" }}
            >
              {t("ContactPage.SupportHeader")}
            </PuxCardTitle>
          </PuxCardHeader>
          <PuxCardContent>
            <InlineSupportLink withTitle withOpeningHours />
          </PuxCardContent>
        </PuxCard>
      </PuxSheet>
    </PageContent>
  )
}

export const InlineSupportLink = ({
  withOpeningHours,
}: {
  withTitle?: boolean
  withOpeningHours?: boolean
}) => {
  const { t } = useAppTranslations()
  const md = useBreakpoint("sm", { useMax: true })
  const btnProps: ArgsAsTuple<typeof PuxButton>[0] = {
    style: {
      display: "inline-block",
      minWidth: md ? "100%" : 250,
      height: 50,
      color: "white",
    },
    size: "large",
    strong: true,
  }
  return (
    <>
      {withOpeningHours && (
        <p style={{ padding: "25px 0px", fontSize: 17 }}>
          {t("ContactPage.SupportOpeningHours")}
        </p>
      )}
      <div>
        <PuxButton {...btnProps} color="primary" href="tel:04500">
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <PuxIcon slot="start" icon={request} />
            04500
          </div>
        </PuxButton>{" "}
        <PuxButton {...btnProps} color="medium" fill="outline" href="tel:04500">
          {t("ContactPage.OutsideNorway")}: +47 940 04500
        </PuxButton>
      </div>
      <div style={{ marginTop: 20 }}>
        <PuxButton
          color="light"
          fill="clear"
          href="https://phonero.no"
          style={{ color: "white" }}
        >
          {t("General.LinkGoTo")} &thinsp;<strong>phonero.no</strong>
        </PuxButton>
        <hr
          slot="end"
          style={{
            width: 55,
            // color: "red",
            marginTop: -5,
            borderColor: "red",
          }}
        />
      </div>
    </>
  )
}

export const Link = (props: JSX.IntrinsicElements["a"]) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} />
}

export default Contact
