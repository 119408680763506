import { featureFlags, isDevEnv, phoneroConfig } from "./appConstants"
import {
  maintenanceMessagesUrl,
  MustUpgradeError,
  useBuildInfo,
  useCurrentSubscriptionId,
  useTokenData,
} from "@phonero/common-ux"
import { useEffect, useState } from "react"
import semvergte from "semver/functions/gte"
import semverlte from "semver/functions/lte"
import semvergt from "semver/functions/gt"
import semverlt from "semver/functions/lt"
import fetchJson from "./util/fetchJson"

type Feature = {
  versionGte?: string
  versionLte?: string
  versionGt?: string
  versionLt?: string
  subscription?: string[]
  mobileNumbers?: string[]
}

type VersionCheckResult = {
  minimumVersion?: string
  minimumVersionMessaage?: string
  maintenanceUrl?: string
  featureFlags?: Partial<typeof featureFlags>
  /** ONLY USE FOR version v2.19.2 and lower
   *
   * @deprecated this was prior to 2.19.3 mapped wrongly for versionGt, versionGte, versionLt, versionLte
   **/
  featureFlagsVersioned?: Partial<Record<keyof typeof featureFlags, Feature>>
  /** Available for versions v2.19.3 and newer released in the first week of May 2023
   *
   **/
  featureFlag?: Partial<Record<keyof typeof featureFlags, Feature>>
} & typeof window.__phonero__

/**
 * Returns if a feature should be enabled or not, based on input.
 * */
export const resolveFeature = (
  name: string,
  f: Feature,
  {
    currentVersion,
    subscriptionId,
    mobileNumber,
  }: { currentVersion: string; subscriptionId: string; mobileNumber: string }
) => {
  console.debug("checking feature-requirement for feature", name, f, {
    currentVersion,
    subscriptionId,
  })
  if (mobileNumber && f.subscription?.includes(subscriptionId)) {
    console.debug(
      `feature ${name} enabled because it matched against susbscription-ids: current: ${subscriptionId} from list[${f.subscription.join(
        ", "
      )}]`
    )
    return true
  }
  if (mobileNumber && f.mobileNumbers?.includes(mobileNumber)) {
    console.debug(
      `feature ${name} enabled because it matched against mobileNumber: current: ${mobileNumber} from list[${f.mobileNumbers.join(
        ", "
      )}]`
    )
    return true
  }
  if (f.versionGte) {
    const ok = semvergte(currentVersion, f.versionGte)
    console.debug(
      `feature ${name} ${
        ok ? "enabled" : "disabled"
      } because it matched against versionGte: current ${currentVersion} from requirement ${
        f.versionGte
      }`
    )
    return ok
  }

  if (f.versionLte) {
    const ok = semverlte(currentVersion, f.versionLte)
    console.debug(
      `feature ${name} ${
        ok ? "enabled" : "disabled"
      } because it matched against versionLte: current ${currentVersion} from requirement ${
        f.versionLte
      }`
    )
    return ok
  }
  if (f.versionGt) {
    const ok = semvergt(currentVersion, f.versionGt)
    console.debug(
      `feature ${name} ${
        ok ? "enabled" : "disabled"
      } because it matched against versionGt: current ${currentVersion} from requirement ${
        f.versionGt
      }`
    )
    return ok
  }

  if (f.versionLt) {
    const ok = semverlt(currentVersion, f.versionLt)
    console.debug(
      `feature ${name} ${
        ok ? "enabled" : "disabled"
      } because it matched against versionLt: current ${currentVersion} from requirement ${
        f.versionLt
      }`
    )
    return ok
  }
  return false
}

/*
  Fetches app.json in the public catalog and sets appSettings as reactive vars.

  It also performs a version-check agains a server-endpoint which describes what is the minimumVersion-required.

  If the user is on a really old version, we can from the server-side force a message on screen for those users,
  notifying them that if they want to continue using the app, they can upgrade.

  This should only be done as a last resort, and with really old and icompatible versions.

  Ideally, we should never show this message to any users.
*/
const VersionCheck = () => {
  const [state, setState] = useState<
    null | (VersionCheckResult & { mustUpgrade?: boolean })
  >(null)

  const { subscriptionId } = useCurrentSubscriptionId()
  const token = useTokenData()
  const build = useBuildInfo()
  useEffect(() => {
    function check() {
      fetchJson<VersionCheckResult>(
        isDevEnv
          ? "https://ditt.dev.phonero.io/app.json"
          : "https://ditt.phonero.no/app.json"
      )
        .then((s) => {
          if (!s) {
            return
          }
          localStorage.setItem("_appjson", JSON.stringify(s))
          if (!isDevEnv || window.Cypress) {
            if (s.featureFlags) {
              for (const [k, v] of Object.entries(s.featureFlags)) {
                featureFlags[k] = v
              }
            }
            if (s.featureFlag) {
              for (const [k, v] of Object.entries(s.featureFlag)) {
                if (!v) {
                  continue
                }
                featureFlags[k] = resolveFeature(k, v, {
                  mobileNumber: token.preferred_username || "",
                  subscriptionId,
                  currentVersion: build.tag,
                })
              }
            }
          }

          if (build.tag === "in_dev") {
            //Using environment config if in dev env
            maintenanceMessagesUrl(phoneroConfig.maintenanceMessageUrl)
          } else {
            //Setting reactive vars from result
            maintenanceMessagesUrl(s?.maintenanceUrl)
          }

          if (build.tag.includes("dirty") || !s.minimumVersion) {
            return
          }

          try {
            // If an ivalid version-tag is used, this function will throw.
            // We don't want to throw in any case for this, as it is bad UX.
            // We also don't want to force the user to upgrade, unless we are certain
            // that the user must upgrade.
            const mustUpgrade = semvergte(s.minimumVersion, build.tag)

            setState({ mustUpgrade, ...s })
          } catch (err) {
            console.error("error in version-check", err)
          }
        })
        .finally(() => {
          if (!maintenanceMessagesUrl()) {
            console.error("using default maintenanceMessageUrl")
            maintenanceMessagesUrl(phoneroConfig.maintenanceMessageUrl)
          }
        })
    }

    check()
    // We run the check again after 15 minutes. I doubt users are using the app this long
    const interval = setInterval(check, 15 * 60_000)
    return () => {
      clearInterval(interval)
    }
  }, [subscriptionId, token.preferred_username])
  if (!state) {
    return null
  }
  if (state.mustUpgrade) {
    throw new MustUpgradeError(state.minimumVersionMessaage || "")
  }
  return null
}

export default VersionCheck
