import { DialogMessage } from "@phonero/common-ux"
import { PuxButton, PuxSheet } from "@phonero/pux-react"
import React, { FC } from "react"
import { AnyFunc } from "simplytyped"
import { OrderStatusId } from "@phonero/common-graphql/types"
import { OrderStatus } from "../checkout/OrderStatus"
import { Anchor } from "../../../components/Anchor"

export const ChangeSubscriptionCompleted: FC<{
  changeOrderApprovalRequired: boolean
  orderId: string
  subscriptionId: string
  onClose: AnyFunc
  onOrderStatusChange?: (
    prev: OrderStatusId | undefined | null,
    next: OrderStatusId
  ) => any
}> = ({
  changeOrderApprovalRequired,
  orderId,
  subscriptionId,
  onClose,
  onOrderStatusChange,
}) => {
  return (
    <PuxSheet style={{ textAlign: "center", height: "100%" }}>
      <DialogMessage
        title={
          changeOrderApprovalRequired
            ? "Forespørselen er sendt til administratoren i din bedrift"
            : "Endring av abonnement er registrert"
        }
      >
        {changeOrderApprovalRequired
          ? "Når administrator har behandlet ordren vil du få beskjed på SMS."
          : "Endringen vil tre i kraft så raskt som mulig."}
      </DialogMessage>
      <p>Du kan gå til din aktivitetslogg for å se status på dine ordre.</p>
      <Anchor
        route="subscriptionActivity"
        analyticsButtonName="checkout-approval"
        component={PuxButton}
        style={{
          minWidth: 250,
        }}
        {...({
          color: "medium",
        } as Parameters<typeof PuxButton>[0] as any)}
      >
        Aktivitetslogg
      </Anchor>{" "}
      <p>Med vennlig hilsen oss i Phonero.</p>
      <OrderStatus
        /*id={changeQuery.data.changeDataPackage.orderId}*/
        id={orderId}
        subscriptionId={subscriptionId}
        onOrderStatusChange={onOrderStatusChange}
        payUsingVipps={false}
        onClose={onClose}
      />
    </PuxSheet>
  )
}
