import { useReactiveVar } from "@apollo/client"
import { appVars, appStorage, useCurrentSubscriptionId, OrdersQuery } from "@phonero/common-ux"
import React from "react"

export type Order = NonNullable<
  NonNullable<NonNullable<OrdersQuery["mobileSubscription"]>["orders"]>["nodes"]
>[0]

appStorage.get({ key: "orders-last-read" }).then((val) => {
  if (!val || !val.value) {
    return
  }
  try {
    const raw = JSON.parse(val.value)
    const value =
      !!raw &&
      Object.keys(raw).reduce((r, key) => {
        const date = new Date(raw[key])
        if (isNaN(date.getTime())) {
          return r
        }
        return { ...r, [key]: date }
      }, {} as Record<string, Date>)

    if (value) {
      appVars.lastRead(value)
    }
  } catch (error) {
    console.warn("failed to json-parse orders-last-read from appStorage")
  }
})
/** Since this is a new feature, we add a default lastReadtime so that existing users
 * with lots of orders, do not "notified" of old orders as read.
 * This date should perhaps be the install-date for the application.
 */
const defaultLastReadTime = new Date(2021, 8, 17)
export function useOrdersLastRead() {
  const lastReadMap = useReactiveVar(appVars.lastRead)
  const { subscriptionId } = useCurrentSubscriptionId()
  const key = !!subscriptionId ? "order_" + subscriptionId : null
  const lastRead = (!!key && lastReadMap[key]) || defaultLastReadTime

  const setLastRead = React.useCallback(
    (dateOrOrder: string | Date | NonNullable<Order>) => {
      if (!key) {
        return
      }
      const now: Date =
        dateOrOrder instanceof Date
          ? dateOrOrder
          : typeof dateOrOrder === "string"
          ? new Date(dateOrOrder)
          : "_lastUpdate" in dateOrOrder
          ? new Date(dateOrOrder._lastUpdate!)
          : (dateOrOrder as any as Date)
      if (!now) {
        return
      }
      const val = { ...appVars.lastRead(), [key]: now }
      appVars.lastRead(val)
      appStorage.set({ key: "orders-last-read", value: JSON.stringify(val) })
    },
    [key]
  )

  const isUnread = React.useCallback(
    (order: Order) => orderIsUnread(lastRead, order),
    [lastRead]
  )

  return { lastRead, setLastRead, isUnread } as const
}

export function orderIsUnread(lastRead: Date | null, order: Order) {
  if (!lastRead) {
    return true
  }
  if (!order?._lastUpdate) {
    return false
  }
  const lastUpdate = new Date(order?._lastUpdate)
  return (
    lastUpdate &&
    lastUpdate.getTime &&
    lastUpdate.getTime() > lastRead.getTime()
  )
}
