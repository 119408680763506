import i18next, { InitOptions } from "i18next"
import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import numeral from "numeral"
import { i18nFormater, setIntlOptions } from "@phonero/pux-react"
import "numeral/locales/no"
import "numeral/locales/de"
import { phoneroConfig } from "./appConstants"
import I18NextChainedBackend from "i18next-chained-backend"
import I18NextLocalStorageBackend from "i18next-localstorage-backend"
import I18NextHttpBackend from "i18next-http-backend"
// import nb from "./locales/nb.generated.json"
import { i18nMarkdownProcessor } from "@phonero/common-ux"
import { formatRelative, format } from "date-fns"
import nb from "date-fns/locale/nb"

const defaultDateFnsOptions = {
  locale: nb,
  weekStartsOn: 1,
  addSuffix: true,
} as const

setIntlOptions({
  IntlLocale: "nb",
  formatterFunc: (date: Date, formatOption, _lng) => {
    if (!formatOption || formatOption === "auto") {
      const now = new Date()
      // Formatrelative only works for roughly one week in the future/past, but it depends on the loclae.
      // Most(all?) locales will in any other range default to the "P"-format (which is the date-only).
      // We can use this assumption to naively detect whether or not the date was formatted relatively, or with the date only.
      const relative = formatRelative(date, now, defaultDateFnsOptions)
      const regular = format(date, "P", defaultDateFnsOptions)
      if (relative !== regular) {
        return relative
      }
      // return relative + "|" + regular
      return null as any
    }
    // for other cases, let default Intl-lib do the work
    return null as any
  },
})

/*
TODO:
  - [ ] Remove moment (native Intl with possible date-fns)
  - [ ] Consider removing numeral
  - [ ] Upgrade i18next / reacti18next
  - [ ] Remove i18n from pux
*/

const initializeI18n = async () => {
  registerNumeral(numeral)

  i18n.on("languageChanged", (lng: string) => {
    try {
      numeral.locale(lng)
    } catch (e) {
      console.warn("Error configuring moment or numeral locale")
      numeral.locale("en")
    }
  })

  const options: InitOptions = {
    react: {
      useSuspense: false,
    },
    ns: [],
    lng: "nb",
    postProcess: [markdownPostprocessor],
    partialBundledLanguages: true,
    // resources: { nb: { translation: nb } },
    // whitelist: ["nb"],
    fallbackLng: "nb",
    interpolation: {
      escapeValue: false, // react already safes from xss,

      format: i18nFormater({ numeralInstance: numeral }),
    },
    backend: {
      backends: [],
      backendOptions: [],
    },
  }
  i18n.use(i18nMarkdownProcessor())
  i18n.use(I18NextChainedBackend)
  const addBackend = (backend: any, backendOptions: any) => {
    if (_IS_TEST) {
      return
    }
    ;(options as any).backend.backends.push(backend)
    ;(options as any).backend.backendOptions.push(backendOptions)
  }
  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  addBackend(I18NextLocalStorageBackend, {
    // after this amount of time, translations are refetched from the backends after this one
    expirationTime: phoneroConfig.devEnv ? 6 * second : 1 * hour,
  })

  addBackend(I18NextHttpBackend, {
    loadPath: "/locales/{{lng}}.generated.json",
  })
  i18n.use(initReactI18next) // passes i18n down to react-i18next
  // i18n.loadLanguages(['nb'])

  await i18n.init(options)

  // confusingly, when used with addResourceBundle, the backends are not actually called
  // unless we "reload" them. Since we are using localstorage as a "backend", this will often simply load from there.
  i18next.reloadResources()

  return i18n
}

const registerNumeral = (numeral: any) => {
  try {
    numeral.register("locale", "nb", numeral.localeData("no"))
    numeral.register("locale", "nn", numeral.localeData("no"))

    numeral.register("format", "price", {
      regexps: {
        format: /.-/,
        unformat: /.-/,
      },
      format: function (value: any, format: any, _roundingFunction: any) {
        if (value % 1 === 0) {
          return `${numeral(value).format("0,0")},-`
        }
        format = format.replace(/\s?\.-/, "")
        return numeral(value).format(format)
      },
      unformat: function (_string: string) {
        throw new Error("Unformat:price - Not implemented...")
      },
    })

    numeral.register("format", "gigabytes", {
      regexps: {
        format: /GB$/,
        unformat: /GB$/,
      },
      format: function (value: any, format: any, _roundingFunction: any) {
        const divider = 1000000000 // Divide to get GB from bytes
        format = format.replace(/\s?GB/, "") // RM Gb from format
        if (value % 1 === 0) {
          return `${numeral(value).divide(divider).format(format)} GB`
        }

        return `${numeral(value).divide(divider).format(format)} GB`
      },
      unformat: function (_string: string) {
        throw new Error("Unformat:gigabyte - Not implemented...")
      },
    })
  } catch (err) {
    console.error(err)
  }
}

export const markdownPostprocessor = "markdownPostprocessor"

export default initializeI18n
