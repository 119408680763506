import { makeVar, useReactiveVar } from "@apollo/client"
import { isApp } from "./appConstants"
import "capacitor-plugin-app-tracking-transparency" // only if you want web support

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
  AppTrackingStatus,
} from "capacitor-plugin-app-tracking-transparency"
import { platform } from "@phonero/common-ux"

/* Thin wrapper around cordova-plugin to check if the user has allowed tracking */
export const getAppTrackingStatus = async (): Promise<AppTrackingStatusResponse> => {
  const response = await AppTrackingTransparency.getStatus()

  // { status: 'authorized' } for example
  appTrackingVar({
    ...appTrackingVar(),
    status: response.status,
    analyticsEnabled: isAnalyticsEnabled(response.status),
  })
  return response
}

/* Thin wrapper around cordova-plugin to request permission from user to track 

  Note: This functionality is different on iOS-version prior to iOS 14.5, iOS 14.5 and iOS 15.

  Permission should only be requested when:

  - No other permission-checks are open
  - App is in state 'active'.
  - Possible when there are no webviews. *Unconfirmed*. Webviews might change the apps-state. 
*/
export const requestAppTrackingPermission = async (): Promise<AppTrackingStatusResponse> => {
  if (platform !== "ios") {
    return Promise.resolve({ status: "notDetermined" })
  }
  const response = await AppTrackingTransparency.requestPermission()

  appTrackingVar({
    ...appTrackingVar(),
    status: response.status,
    analyticsEnabled: isAnalyticsEnabled(response.status),
    requested: true,
  })

  return response
}

export const appTrackingVar = makeVar(
  isApp
    ? {}
    : ({
        analyticsEnabled: true,
        requested: false,
        status: "authorized",
      } as {
        analyticsEnabled?: null | boolean
        requested?: boolean
        status?: AppTrackingStatus
      })
)

function isAnalyticsEnabled(status: AppTrackingStatus) {
  if (!status) {
    return null
  }
  switch (status) {
    case "denied":
    case "restricted":
      return false
    case "notDetermined":
      return null
    case "authorized":
      return true

    default:
      return null
  }
}

export const useAppTracking = () => useReactiveVar(appTrackingVar)
