import { makeVar, useQuery, useReactiveVar } from "@apollo/client"
import { addHours } from "date-fns"
import {
  PaymentMethod,
  PaymentOperation,
  type PurchaseRequest,
} from "@phonero/common-graphql/types"
import { appStorage } from "./storage"
import { OutstandingPaymentsDocument } from "../queries/OutstandingPayments.generated"
import { pollInterval } from "../providers/apolloOptions"

export const appVars = {
  lastRead: makeVar<Record<string, Date>>({}),
  lastReadReceipts: makeVar<Record<string, Date>>({}),
  /** Used to track purchases to third-parties with redirects.
   *
   * In practicce, they are used for Vipps-payments, where we add an additional failsafe in case of
   * OS-specific failures/misconfigurations in handling app-redirects both to and from Vipps.
   *
   * */
}
const tryJsonParse = (v: string, defaultvalue: any) => {
  if (!v) {
    return defaultvalue
  }
  try {
    return JSON.parse(v)
  } catch (err) {
    console.error("failed to read parse json", v, err)
    return defaultvalue
  }
}
const uncompletedPurchasesVar = makeVar<Record<
  string,
  IncompletePurchase
> | null>(null)

appStorage.get({ key: "uncompletedPurchases" }).then((v) => {
  if (!v.value) {
    return
  }
  const json = tryJsonParse(v.value, null) as Record<string, IncompletePurchase>
  if (!json) {
    return
  }
  const now = new Date()
  const cutoff = addHours(now, -30).getTime()
  const reduced = Object.entries(json).reduce((r, [k, v]) => {
    if (new Date(v.date).getTime() < cutoff) {
      return r
    }
    return { ...r, [k]: v }
  }, {})
  uncompletedPurchasesVar(reduced)
})

type IncompletePurchase = Pick<
  PurchaseRequest,
  "orderID" | "paymentID" | "productID" | "paymentMethod" | "paymentURI"
> & { date: Date; productText: string; amount: number } & {
  source: "localStorage" | "server"
}

const updater = <T extends (set?: any) => any, R = ReturnType<T>>(
  reactiveVar: T,
  ...callbacks: Array<(value: R) => Promise<R>>
): React.Dispatch<React.SetStateAction<R>> => {
  return async (f) => {
    let next = typeof f === "function" ? (f as any)(reactiveVar()) : f
    for (const cb of callbacks) {
      next = await cb(next)
    }
    reactiveVar(next)
    return next
  }
}

const uncompletedPurchasesUpdater = updater(
  uncompletedPurchasesVar,
  async (val) => {
    const str = JSON.stringify(val)
    await appStorage.set({ key: "uncompletedPurchases", value: str })
    return val
  }
)

export const useBadgeCounter = () => {
  // const { purchases } = useUncompletedPurchases()
  // const location = useLocation()
  // const isPaymentCallback = matchPath(location.pathname, {
  //   path: routes.paymentCallbackVipps.path,
  // })

  // let count = 0

  // if (!isPaymentCallback) {
  //   // don't show a badge-count for payments on the payment-callback-page
  //   count += Object.keys(purchases || {}).length
  // }

  // return count
  return 0
}

export const uncompletedPurchases = {
  update: uncompletedPurchasesUpdater,
  remove: (paymentId: string) =>
    uncompletedPurchasesUpdater((state) => {
      if (!state) {
        return {}
      }
      const { [paymentId]: _omit, ...rest } = state
      return rest
    }),
  clear: () => uncompletedPurchasesUpdater({}),
  add: (purchase: IncompletePurchase) =>
    uncompletedPurchasesUpdater((s) => ({
      ...s,
      // @ts-ignore: source is specified more than once
      [purchase.paymentID]: { source: "localStorage", ...purchase },
    })),
}

/** Outstanding payments returns payments that are not yet completed.
 *
 * These are typically Vipps-payments that the user somehow was not redirected correctly to.
 *
 * There are a combination of two sources:
 * - LocalStorage is populated on the device of which the payment is started, before redirecting to vipps.
 * - Server also keeps track of these items
 *
 * The result is combined into unique entries.
 * */
export const useOutstandingPayments = () => {
  const purchases = useReactiveVar(uncompletedPurchasesVar)
  const r = useQuery(OutstandingPaymentsDocument, {
    pollInterval: pollInterval(5000),
    onCompleted: (d) => {
      for (const n of d.outstandingPayments) {
        uncompletedPurchases.remove(n.id)
      }
    },
  })
  const outstanding = r.data?.outstandingPayments?.map((d) => {
    return {
      productID: d.productID,
      paymentMethod: PaymentMethod.Vipps,
      paymentID: d.id,
      date: d.lastState?.time,
      productText: d.product?.name || "",
      // Payments for vipps are recorded as ints with decimals, divide by 100 to get the value in NOK
      amount: (d.lastState?.amount || 0) / 100,
      orderID: d.orderID,
      operation: d.lastState?.operation,
      source: "server",
    } as IncompletePurchase & { operation?: PaymentOperation }
  })

  const first: IncompletePurchase | null = outstanding?.length
    ? outstanding[0]
    : Object.values(purchases || {})[0] || null

  return {
    firstOutstanding: first,
    purchases,
    count: (outstanding?.length || 0) + Object.keys(purchases || {}).length,
    outstanding,
    ...r,

    ...uncompletedPurchases,
  }
}
