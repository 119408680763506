import { ApolloError } from "@apollo/client"
import { pushToast, ToastArgs } from "../utils/toast/pushToast"

export function extractMessageFromApolloError(err: ApolloError) {
  if (err.networkError) {
    // TODO: check internet-connection here.
    return "There was a problem reaching the server"
  }

  if (!err.graphQLErrors || !err.graphQLErrors.length) {
    return err.message
  }

  const [{ message, extensions }] = err.graphQLErrors

  if (extensions) {
    const ext = Object.keys(extensions)
      .sort()
      .map((k) => extensions[k])
      .filter(Boolean)
    return `${message}: ${ext.join(" - ")}`
  }
  return message
}

export function handleApolloError(
  err: ApolloError,
  //* context for the error, preferably a short message to the user describing the action that went wrong
  // @example
  // 'Could not retrieve usage-information at this time. '
  //*/
  context: string,
  toastArgs: Partial<ToastArgs> = { color: "danger" }
) {
  const message = extractMessageFromApolloError(err)

  pushToast({ ...toastArgs, message: `${context}: ${message}` })
}

/** handles errors from GraphQL */
export function handleGraphqlQLError(
  //* context for the error, preferably a short message to the user describing the action that went wrong
  // @example
  // 'Could not retrieve usage-information at this time. '
  //*/
  context: string,
  toastArgs: Partial<ToastArgs> = { color: "danger" }
) {
  return (err: ApolloError) => handleApolloError(err, context, toastArgs)
}