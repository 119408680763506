import { Approver, ChangeDataPackageInput, DataPackageProduct, SortEnumType } from "@phonero/common-graphql/types"
import { useEffect, useState } from "react"
import {
  PuxButton,
  PuxDropdown,
  PuxLabel,
  PuxSheet,
  PuxSkeletonText,
  PuxText,
  PuxTextarea,
  toaster,
} from "@phonero/pux-react"
import ExVatNotice from "./ExVatNotice"
import {
  ApproversQuery,
  ChangeDataPackageBatchDocument,
  ChangeDataPackageBatchMutationVariables,
  ChangeSubscriptionQuery,
  ordersVariables,
} from "@phonero/common-ux"
import {
  graphqlMutationToast,
  InlineError,
  MarkdownWithApprovedIconForLists,
  OrdersDocument,
  ProductDialogDetails,
  useAppMutation,
  useAppTranslations,
  useCurrentSubscriptionId,
} from "@phonero/common-ux"

export const CheckoutApproval = ({
  selected,
  changeOrderApprovalRequired,
  data,
  loading,
  currentSubscriptionProductId,
  currentContractProductId,
  onSelectApprover,
  onOrderSent,
}: {
  onSelectApprover: () => any
  onOrderSent: (orderId: string) => any
  currentSubscriptionProductId: number
  currentContractProductId: number
  selected: Pick<
    DataPackageProduct,
    "productId" | "size" | "sizeType" | "priceId" | "price" | "description" | "name"
  > & { offeringId: string }
  data: ApproversQuery
  loading: boolean | undefined
} & Pick<
  NonNullable<NonNullable<ChangeSubscriptionQuery>["mobileSubscription"]>,
  "name" | "changeOrderApprovalRequired"
>) => {
  const { t, i18n } = useAppTranslations()
  const { subscriptionId } = useCurrentSubscriptionId()
  const [submitBatch, { error: changeQueryError, ...changeQuery }] = useAppMutation(ChangeDataPackageBatchDocument, {
    refetchQueries: [
      // ChangeSubscriptionDialog-query
      {
        query: OrdersDocument,
        variables: ordersVariables(subscriptionId),
      },
      // OrdersBadge-query
      {
        query: OrdersDocument,
        variables: {
          first: 50,
          orderBy: {
            registrationDateTime: SortEnumType.Desc,
          },
          subscriptionID: subscriptionId,
          // TODO: when api supports filtering by date, we should only query those we actually need, and only request the count here
          withDetails: true,
        },
      },
    ],
    ...graphqlMutationToast(t, "updateDataPackage"),
  })

  const orderConfirmations = changeQuery?.data?.submitBatch?.orderConfirmations
  useEffect(() => {
    if (!orderConfirmations?.length) return

    onOrderSent(orderConfirmations[0]?.orderId)
  }, [orderConfirmations, onOrderSent])

  const [approver, setApprover] = useState<Approver | null | undefined>()

  const errors: string[] = []
  if (changeOrderApprovalRequired && !approver) {
    errors.push("ChangeSubscription.ErrNoApproverSelected")
  }
  const isValid = !errors.length
  const isDisabled = changeQuery.loading || !isValid

  function handleSubmit() {
    let requestMessage = ""
    if (approver) {
      const msgEl = document.querySelector<HTMLTextAreaElement>('[name="approver-message"]')
      if (!msgEl) {
        throw new Error("Could not find input-element")
      }
      requestMessage = msgEl.value
    }

    if (!isValid) {
      for (const msg of errors) {
        toaster.error(t(msg))
      }
      return
    }
    if (!subscriptionId) {
      return
    }
    const changeDataProductList: ChangeDataPackageInput[] = [
      {
        subscriptionId: subscriptionId,
        currentProductId: String(currentSubscriptionProductId),
        currentContractProductId: currentContractProductId,
        requestedProductId: String(selected.productId),
        requestedOfferingId: String(selected.offeringId),
        requestedProductPriceId: selected.priceId,
        ...(!!approver && {
          contactId: approver.contactId ? Number(approver.contactId) : undefined,
          approverId: approver.personId ? Number(approver.personId) : undefined,
          requestMessage,
        }),
      },
    ]
    let variables: ChangeDataPackageBatchMutationVariables = {
      input: { changeDataPackage: changeDataProductList },
    }
    submitBatch({ variables })
  }

  return (
    <>
      <PuxSheet color="light">
        <InlineError error={changeQueryError} code="changeQuery" />
        <ExVatNotice />
        <p
          style={{
            textAlign: "center",
            fontSize: "2.2rem",
            margin: "20px 0px 10px",
            fontWeight: 700,
          }}
        >
          Valgt abonnement
        </p>
        {!!selected && (
          <ProductDialogDetails
            size={selected.size}
            sizeType={selected.sizeType || ""}
            name={selected.name || ""}
            price={selected.price}
          >
            {selected.description ? (
              <MarkdownWithApprovedIconForLists markdownText={selected.description} />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>Total månedpris</div>
                <div>{i18n.format(selected.price, "price")}</div>
              </div>
            )}
          </ProductDialogDetails>
        )}
      </PuxSheet>

      {(!changeQuery.data || (!changeQuery.called && changeOrderApprovalRequired)) && (
        <PuxSheet color="light" style={{ display: "flex", flexDirection: "column" }}>
          {!changeQuery.called && changeOrderApprovalRequired && (
            <div style={{ textAlign: "center" }}>
              <p>
                <PuxText>{t("ChangeSubscription.ApproverNotice")} </PuxText>
              </p>
              {loading ? (
                <PuxSkeletonText animated={loading} style={{ height: 65, maxWidth: 300, margin: "auto" }} />
              ) : !data?.mobileSubscription?.approvers?.length ? (
                <PuxText color="danger">
                  Det er ingen godkjennere tilgjengelig. Vennligst kontakt din administrator.
                </PuxText>
              ) : (
                <div
                  style={{
                    paddingBottom: 40,
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      maxWidth: 350,
                      width: "100%",
                    }}
                  >
                    <PuxDropdown
                      placeholder={t("ChangeSubscription.Choose")}
                      useNativePicker={false}
                      onPuxSelectedChange={(e) => {
                        const approver: Approver = (e.detail as any).approver
                        if (!approver) {
                          return
                        }
                        onSelectApprover()
                        setApprover(approver)
                      }}
                      options={
                        data.mobileSubscription?.approvers?.map((a) => {
                          return {
                            title: a.name || "",
                            value: a.personId || a.contactId || "",
                            approver: a,
                          }
                        }) || []
                      }
                    />
                  </div>
                  <div style={{ marginTop: 30, width: "100%" }}>
                    <PuxLabel>
                      Melding {!!approver && `til ${approver.name}`}
                      <PuxTextarea
                        style={{ textAlign: "left" }}
                        disabled={!approver}
                        name="approver-message"
                        placeholder="Kort forklaring om hvorfor du vil endre abonnement"
                      />
                    </PuxLabel>
                  </div>
                </div>
              )}
            </div>
          )}
          {!changeQuery.data && (
            <PuxButton onClick={handleSubmit} disabled={isDisabled} style={{ minWidth: 250, margin: "0 auto" }}>
              Bestill
            </PuxButton>
          )}
        </PuxSheet>
      )}
    </>
  )
}
