import { FC } from "react"
import { Product, PuxItemProps } from "./ProductItem"
import { Perhaps } from "../util/typeHelpers"
import { ProductItemCampaign } from "./ProductItemCampaign"
import css from "./DataPackage.module.scss"
import classNames from "classnames"

export interface CampaignDataPackagesProps {
  dataCampaignProducts: Perhaps<
    (Product & { priceDiff?: number; sortOrder?: number })[]
  >
  onSelectProduct?: any
}

export const CampaignDataPackages: FC<
  CampaignDataPackagesProps & PuxItemProps
> = ({ dataCampaignProducts, onSelectProduct }) => {
  if (!dataCampaignProducts || !dataCampaignProducts.length) {
    return null
  }

  const sortedCampaignDataProducts = dataCampaignProducts.sort((p1, p2) => {
    return (p1.sortOrder ?? 0) - (p2.sortOrder ?? 0)
  })

  return (
    <>
      <div
        className={classNames(css.campaignProductsContainer, {
          [css.campaignProductsContainerSingle]:
            sortedCampaignDataProducts.length === 1,
          [css.campaignProductsContainerMultiple]:
            sortedCampaignDataProducts.length > 1,
        })}
      >
        {sortedCampaignDataProducts.map((product) => {
          return (
            <ProductItemCampaign
              key={product?.id}
              product={product}
              onSelectProduct={onSelectProduct}
            />
          )
        })}
      </div>
    </>
  )
}
