import { isApp } from "./appConstants"
import { platform } from "@phonero/common-ux"

// Returns the current app schema, without the leading '/'
export const getCurrentAppScheme = (
  options: { forApp?: boolean; forPlatform?: typeof platform } = {
    forApp: isApp,
    forPlatform: platform,
  }
) => {
  if (options.forApp) {
    switch (options.forPlatform) {
      case "ios":
        return "ditt://"
      case "android":
        return "no.phonero.ditt://"
      case "web":
        break
    }
  }
  return window.location.origin + "/"
}
