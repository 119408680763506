import React, { Dispatch, FC, useEffect, useState } from "react"
import {
  PuxFooter,
  PuxModalContent,
  PuxSheet,
  PuxSpinner,
} from "@phonero/pux-react"

import {
  ChangeDataPackageInput,
  DataPackageProduct,
  OrderStatusId,
  OrderType,
} from "@phonero/common-graphql/types"
import {
  ApproversDocument,
  DataProductList,
  graphqlQueryToast,
  IDataProduct,
  InlineError,
  sortDataProducts,
  useAnalytics,
  useAppQuery,
  useAppTranslations,
  useCurrentSubscriptionId,
} from "@phonero/common-ux"
import { CheckoutApproval } from "./CheckoutApproval"
import DialogTitle from "../../../components/layout/DialogTitle"
import { FromProductWrapper } from "./FromProductWrapper"
import { ChangeSubscriptionCompleted } from "./ChangeSubscriptionCompleted"
import { useChangeSubscriptionData } from "@phonero/common-ux"
import { OrderStatusInline } from "@phonero/common-ux"
import { useGoBack } from "../../../util/useGoBack"

/*
export const ChangeSubscriptionContext = React.createContext<null | {
  selected?: Pick<DataPackageProduct, "productId"> & { offeringId: string }
  setSelected: Dispatch<any>
  input?: Partial<ChangeDataPackageInput>
  setInput: Dispatch<Partial<ChangeDataPackageInput>>
}>(null)
*/

const analyticsProps = {
  orderKind: "change-subscription",
}

export const ChangeSubscriptionDialog: FC = () => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const { session, logPurchase, logOrderProcess, logOrderProcessCancellation } =
    useAnalytics()
  const [selected, setSelected] = useState<
    (DataPackageProduct & { subscriptionName?: string }) | null
  >(null)
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null)
  const { t } = useAppTranslations()
  const { goBack } = useGoBack({ onlyIfRouteMatch: "edit" })

  const {
    subscriptionPackagesData,
    subscriptionPackagesLoading,
    availableProductList,
    showLoading,
    changeOrderApprovalRequired,
    orderExists,
    orderedName,
    ordersError,
    subscriptionPackagesError,
    widestAvailablePackageSize,
    previousActiveOrderIfAny,
    mobileSubscription,
    currentDataProduct,
  } = useChangeSubscriptionData({ subscriptionId })

  // We want a new session-id every time a user closes the dialog
  useEffect(() => {
    session.newSessionId()
  }, [])

  const onClose = () => {
    logOrderProcessCancellation()
    setSelected(null)
    setCurrentOrderId(null)
    goBack()
  }

  const { error: approversError, ...approversData } = useAppQuery(
    ApproversDocument,
    {
      skip: !subscriptionId,
      variables: { subscriptionId: subscriptionId },
      fetchPolicy: "cache-and-network",
      ...graphqlQueryToast(t, "getApprovers"),
    }
  )

  function onSetSelected(p) {
    logOrderProcess({
      ...analyticsProps,
      stepName: "selected-product",
    })
    setSelected(p)
  }

  const alreadyOrdered = orderExists && !currentOrderId
  const blocksBecauseAlreadyOrdered =
    alreadyOrdered && changeOrderApprovalRequired

  return (
    <>
      <DialogTitle
        onlyIfRouteMatch={["edit"]}
        {...(selected &&
          !currentOrderId && {
            onBackClick: () => {
              setSelected(null)
            },
          })}
      >
        Endre abonnement
      </DialogTitle>
      {showLoading && <PuxSpinner style={{ margin: "auto" }} />}
      <PuxModalContent>
        <InlineError error={ordersError} code="OrdersChangeSub" />
        <InlineError error={approversError} code="ApproversChangeSub" />
        <InlineError
          error={subscriptionPackagesError}
          code="subscriptionPackagesChangeSub"
        />
        {!!mobileSubscription && !!currentDataProduct && (
          <>
            {/* Blocked if previously ordered: If query for orders on this subscription returns > 0 and the change subscription requires approval */}
            {!!blocksBecauseAlreadyOrdered && (
              <FromProductWrapper
                loading={subscriptionPackagesLoading}
                products={availableProductList}
                currentDataProduct={currentDataProduct}
                widestAvailablePackageSize={widestAvailablePackageSize}
                userCannotChangeSubscription={
                  mobileSubscription?.userCannotChangeSubscription
                }
              >
                <div style={{ textAlign: "center" }}>
                  {previousActiveOrderIfAny?.status !==
                    OrderStatusId.Cancelled &&
                    previousActiveOrderIfAny?.status !==
                      OrderStatusId.Completed && (
                      <p>Det finnes en aktiv endringsordre:</p>
                    )}
                  <p>
                    Ønsket bytte: <strong>{orderedName}</strong>
                  </p>
                  {previousActiveOrderIfAny?.status && (
                    <p>
                      Status:{" "}
                      <OrderStatusInline
                        status={previousActiveOrderIfAny.status}
                      />
                    </p>
                  )}
                  {previousActiveOrderIfAny?.status !==
                    OrderStatusId.Cancelled &&
                    previousActiveOrderIfAny?.status !==
                      OrderStatusId.Completed && (
                      <p>Vennligst vent til denne ordren er ferdigbehandlet.</p>
                    )}
                </div>
              </FromProductWrapper>
            )}
            {/* If a new order can be placed */}
            {!blocksBecauseAlreadyOrdered && (
              <>
                {/* When a new subscription is not selected... */}
                {!selected ? (
                  <FromProductWrapper
                    loading={subscriptionPackagesLoading}
                    products={availableProductList}
                    currentDataProduct={currentDataProduct}
                    widestAvailablePackageSize={widestAvailablePackageSize}
                    userCannotChangeSubscription={
                      mobileSubscription?.userCannotChangeSubscription
                    }
                  >
                    {previousActiveOrderIfAny && (
                      <ShowActivationMessage
                        lastActiveOrder={previousActiveOrderIfAny}
                        orderedName={orderedName}
                      />
                    )}
                    <DataProductList
                      onClick={(selectedProduct: IDataProduct) =>
                        onSetSelected(selectedProduct)
                      }
                      packages={
                        (sortDataProducts(availableProductList) as any) ?? []
                      }
                      widestAvailablePackageSize={widestAvailablePackageSize}
                    />
                  </FromProductWrapper>
                ) : (
                  <>
                    {/* If a new subscription is selected and no order is placed */}
                    {!currentOrderId ? (
                      <CheckoutApproval
                        onSelectApprover={() => {
                          logOrderProcess({
                            ...analyticsProps,
                            stepName: "selected-approver",
                          })
                        }}
                        onOrderSent={(orderId: string) => {
                          setCurrentOrderId(orderId)
                          logOrderProcess({
                            ...analyticsProps,
                            stepName: "submit",
                          })
                        }}
                        {...approversData}
                        {...mobileSubscription}
                        currentSubscriptionProductId={
                          currentDataProduct.productId
                        }
                        selected={selected}
                      />
                    ) : (
                      <ChangeSubscriptionCompleted
                        onClose={onClose}
                        subscriptionId={subscriptionId}
                        changeOrderApprovalRequired={
                          changeOrderApprovalRequired
                        }
                        orderId={currentOrderId}
                        onOrderStatusChange={(_prev, next) => {
                          logOrderProcess({
                            ...analyticsProps,
                            stepName: "status-" + next,
                          })
                          switch (next) {
                            case OrderStatusId.Completed:
                              logPurchase({
                                id: selected.productId,
                                name:
                                  selected.subscriptionName || selected.name,
                                __typename: selected.__typename,
                                price: selected.price,
                              })
                              subscriptionPackagesData?.refetch?.()
                              break

                            default:
                              break
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {!currentDataProduct && subscriptionPackagesLoading === false && (
          <PuxSheet style={{ textAlign: "center", paddingBottom: 0 }}>
            {t("ChangeSubscription.ContactAdmin")}
          </PuxSheet>
        )}
      </PuxModalContent>
      <PuxFooter />
    </>
  )
}
const ShowActivationMessage = ({ orderedName, lastActiveOrder }) => (
  <div
    style={{
      textAlign: "center",
      color: "var(--color-primary)",
    }}
  >
    <p>
      Det er allerede registrert en ordre på en endring til: {orderedName}
      {!!lastActiveOrder?.status && (
        <>
          {" "}
          som er{" "}
          <OrderStatusInline
            noIcon={true}
            status={lastActiveOrder.status}
            style={{ color: "inherit", textTransform: "lowercase" }}
          />
          {"."}
        </>
      )}
    </p>
  </div>
)
