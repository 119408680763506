import type { useLocation } from "react-router-dom"
import { RouteKeys, routes } from "../routes"
import { matchRoute } from "./useRouteMatcher"
import { toSafeJSON, UrlHashes } from "@phonero/common-ux"

export type Loc = ReturnType<typeof useLocation>
export type OnlyIfRouteMatch =
  | keyof typeof UrlHashes
  | RouteKeys
  | Partial<Loc>
  | ((location: Loc) => boolean | null)

/** Checks if the OnlyIfRouteMatch returns a match */
function checkOnlyMatch(onlyIfRouteMatch: OnlyIfRouteMatch, location: Loc) {
  if (typeof onlyIfRouteMatch === "string" && UrlHashes[onlyIfRouteMatch]) {
    return location.hash === UrlHashes[onlyIfRouteMatch]
  } else if (typeof onlyIfRouteMatch === "string" && routes[onlyIfRouteMatch]) {
    const match = matchRoute(location.pathname)
    return !!match?.ok
  } else if (typeof onlyIfRouteMatch === "function") {
    return onlyIfRouteMatch(location)
  }
  throw new Error(
    "Not implemented for onlyIfRouteMatch: " + toSafeJSON(onlyIfRouteMatch)
  )
}

export function checkOnlyMatchOneOrMore(
  onlyIfRouteMatch: OnlyIfRouteMatch | OnlyIfRouteMatch[],
  location: Loc
) {
  if (!Array.isArray(onlyIfRouteMatch)) {
    return checkOnlyMatch(onlyIfRouteMatch, location)
  }
  let foundMatch: boolean | undefined | null = undefined
  for (const only of onlyIfRouteMatch) {
    const result = checkOnlyMatch(only, location)
    switch (result) {
      case null:
        // if null, we dont care if any matches
        return null
      case true:
        foundMatch = true
        break
      case false:
        if (foundMatch === undefined) {
          foundMatch = false
        }
        break
      default:
        break
    }
  }
  return foundMatch
}
