import { safeMarkdown, safeMarkdownBlock } from "./markdown"

export const Markdown = ({
  children,
  inline = false,
}: {
  children: string
  inline?: boolean
}) => {
  // we __might__ want to use state /effect here, so we don't reparse the markdown on every render
  if (inline) {
    return <>{safeMarkdown(children)}</>
  }
  return <>{safeMarkdownBlock(children)}</>
}
