import { PredefinedColors } from "@phonero/pux-core"
import { ArgsAsTuple } from "simplytyped"
import type { PuxToast } from "@phonero/pux-react"
import { handleGraphqlQLError } from "../../util/handleGraphqlError"

export type ToastArgs = ArgsAsTuple<typeof PuxToast>[0] & { key: string }

export const puxGlobalEventName = "pux-global-toaster"
export const pushToast = (options: Partial<ToastArgs>) => {
  const e = new CustomEvent<Partial<ToastArgs>>(puxGlobalEventName, {
    detail: options,
  })

  document.dispatchEvent(e)
  return e
}

const toastFactory = (color: PredefinedColors) => {
  return (
    message: string,
    options?: Omit<Partial<ToastArgs>, 'message' | 'color'>
  ) => pushToast({ ...options, message, color })
}

export const toaster = {
  error: toastFactory("danger"),
  success: toastFactory("success"),
  apolloError: handleGraphqlQLError,
}

