import { toaster } from "@phonero/pux-react"

import { useEffect, useRef, useState } from "react"
import { devPrettyJson } from "./devJson"
import copyToClipboard from "./copyToClipboard"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const apolloIgnoreKeys = new Set(["client", "cache", "observable"])
export const DevDetailsAsTabs = (p: Parameters<typeof DevDetails>[0]) => {
  if (!p.data) {
    return null
  }
  return (
    <div className={p.className}>
      {Object.entries(p.data).map(([k, v]) => {
        return <DevDetails key={k} {...p} className="" data={v} label={k} />
      })}
    </div>
  )
}

export const DevDetails = ({
  data,
  label,
  asLogEntries: asLog = false,
  maxSize = 16000,
  removeApolloKeys = true,
  ignoreKeys,
  maxEntries = 100,
  onCopy,
  showOverride: show = true, // isDevEnv, TODO: How can we get isDevEnv in here
  open,
  fixed,
  noRenderCount,
  replaceFunc,
  ...detailsProps
}: {
  showOverride?: boolean
  data: any
  /** each change in data will be recorded as a separate line*/
  asLogEntries?: boolean
  maxSize?: number
  maxEntries?: number
  removeApolloKeys?: boolean
  label?: string
  ignoreKeys?: Set<string>
  onCopy?: () => void
  open?: boolean
  fixed?: boolean
  replaceFunc?: (s: string) => string
  noRenderCount?: boolean
} & JSX.IntrinsicElements["details"]) => {
  const [s, set] = useState<string>()
  const log = useRef<string[]>([])
  const [count, setCounter] = useState(0)
  if (removeApolloKeys && !ignoreKeys) {
    ignoreKeys = apolloIgnoreKeys
  }
  const showPrettyJson = useFeatureIsOn("selfservice-show-developer-info")

  const name =
    label === undefined
      ? typeof data === "object" && Object.keys(data)[0]
      : label
  useEffect(() => {
    if (!show) {
      return
    }
    setCounter((i) => ++i)
    let str = ""
    try {
      str =
        typeof data === "string"
          ? data
          : devPrettyJson(
              data,
              showPrettyJson,
              ignoreKeys,
              asLog ? null : 2,
              ""
            )
    } catch (err) {
      console.error("Failed to stringify in DevDetails", { err, data })
      return
    }
    str = str || ""
    if (maxSize && str.length > maxSize) {
      str = str.substr(0, maxSize) + `...<dropped ${str.length - maxSize}>`
    }
    if (asLog) {
      // skip equal values
      if (log.current[log.current.length - 1] === str) {
        // return
      }
      log.current = [...log.current, str]
      if (maxEntries && log.current.length > maxEntries) {
        log.current = log.current.slice(log.current.length - maxEntries)
      }

      str = "[\n" + log.current.join("\n") + "]"
    }
    if (replaceFunc) {
      str = replaceFunc(str)
    }
    set(str)
  }, [
    data,
    asLog,
    ignoreKeys,
    maxSize,
    maxEntries,
    show,
    showPrettyJson,
    replaceFunc,
  ])
  if (!show) {
    return null
  }

  const details = (
    <div
      style={{
        position: "relative",
        height: 400,
      }}
      onMouseDown={(e) => {
        e.currentTarget.style.maxHeight = "unset"
      }}
      className="resize border-4  group overflow-scroll"
    >
      <pre
        className="text-pink-500 bg-black/80"
        style={{
          color: "hotpink",
          textAlign: "left",
          whiteSpace: "break-spaces",
        }}
      >
        {s}
        <button
          className=" bg-gray-700/80 px-8 py-4 absolute right-0 top-4 group-hover:opacity-100 opacity-0 transition-opacity"
          disabled={!s}
          onClick={() => {
            if (!s) {
              return
            }
            copyToClipboard(s)
            !onCopy && toaster.success(`Kopiert ${s.length} tegn`)
            onCopy?.()
          }}
        >
          Kopier 📋
          {label} {!noRenderCount && `${count} rendringer`}
          {asLog ? ` (${log.current.length} log-linjer)` : ""}
        </button>
      </pre>
    </div>
  )
  if (fixed) {
    return details
  }

  return (
    <details style={{ marginBlock: 40 }} open={open} {...detailsProps}>
      <summary
        style={{ opacity: 0.7 }}
        title="Kun tilgjengelig i utviklings-miljø"
      >
        {name} - Klikk for detailjer
      </summary>
      {details}
    </details>
  )
}
