import { PuxIcon, PuxSpinner } from "@phonero/pux-react"
import { Badge, useAppQuery } from "@phonero/common-ux"
import { useEffect } from "react"
import { failedCircle } from "@phonero/pux-icons/icons"
import { ApprovalBadgeCountDocument } from "./ApprovalBadgeCount.generated"

type BadgeProps = Parameters<typeof Badge>[0]
const ApprovalBadgeCount = ({
  refreshBadgeCount,
  ...rest
}: { refreshBadgeCount: number } & BadgeProps) => {
  const { data, refetch, loading, error } = useAppQuery(
    ApprovalBadgeCountDocument,
    {}
  )
  const approvalBadgeCount = data?.me.approvals?.totalCount
  useEffect(() => {
    refetch()
  }, [approvalBadgeCount, refetch])
  if (data?.me.approvals?.totalCount === 0) {
    return null
  }

  return (
    <Badge style={{ marginLeft: 70, ...rest.style }} {...rest}>
      {!!error && !data && (
        <PuxIcon icon={failedCircle} title={error.message} />
      )}
      {loading ? <PuxSpinner name="circles" /> : approvalBadgeCount}
    </Badge>
  )
}

export default ApprovalBadgeCount
