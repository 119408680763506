import { FC } from "react"
import { ProductLink } from "./ProductLink"
import { GetPhoneroCloudUrl, isMobile, PhoneroCloudProductId } from "./PhoneroCloudSettings"

export interface PhoneroCloudLinkProps {
  productId: number
  styleType: "button" | "anchor"
}

export const PhoneroCloudLink: FC<PhoneroCloudLinkProps> = (props: PhoneroCloudLinkProps) => {
  const url = GetPhoneroCloudUrl()
  const text = isMobile() ? "Phonero Sky-appen" : "Phonero Sky"

  if (props.productId !== PhoneroCloudProductId) return

  return <ProductLink text={text} url={url} styleType={props.styleType} />
}
