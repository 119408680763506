import { ProductUnionType } from "@phonero/common-graphql/types"
import { useCurrentSubscriptionId } from "./useSubscriptionId"
import mixpanel from "mixpanel-browser"
import React, { useEffect } from "react"
import { nanoid } from "nanoid"
import { useAppTracking } from "./useAppTracking"
import { addDebugAnalytic } from "./useAnalyticsDebug"
import { serializeAsObj, useFaroClient } from "../Faro"
const isDinBedrift =
  // eslint-disable-next-line
  !!process.env.REACT_APP_DBW || location.host.startsWith("db.")

// mixpanel is being evaluated at this time.

let didInit = false
const init = () => {
  if (didInit) {
    return Promise.resolve(true)
  }
  didInit = true
  mixpanel.init(
    isDinBedrift
      ? // Token for mix-panel din bedrift
        "35a505fedfc5a9665b02d679e0c5f63c"
      : // Token for mix-panel ditt phonero
        "cd209a6feb539f95f44c00ef636c0f8f"
  )
  return Promise.resolve(true)
}

/* Previous app logged with these names:*/
type LogBucket =
  | "add_to_cart"
  | "app_clear_data"
  | "app_open"
  | "app_remove"
  | "app_update"
  | "click"
  | "dp_error"
  | "dp_pageview"
  | "ecommerce_purchase"
  | "first_open"
  | "first_visit"
  | "os_update"
  | "page_view"
  | "present_offer"
  | "screen_view"
  | "scroll"
  | "search"
  | "session_start"
  | "view_item"
  | "view_item_list"
  // New Buckets
  | "Order Process"
  | "Navigation"

// type FireBase = NonNullable<typeof fireBase>
type ProductType = ProductUnionType["__typename"]
type ID = string | number

type FireBaseLogger = (args: {
  name: string
  params?: Record<string, string | number>
}) => Promise<unknown>

const _log: FireBaseLogger = async (_options) => {}

type BaseArgs = {
  __typename?: ProductType
  id?: ID
  name?: string
  price?: number
  /** any extra information */
  extra?: any
}

export type LogOrderProcessParams = {
  orderKind: string
  stepName: string
  attributes?: Record<string, string>
  productID?: string
  productName?: string
  didCancel?: boolean
}
export type LogOrderProcess = (params: LogOrderProcessParams) => void

const log = (name: LogBucket, params: any) => _log({ name, params })

/** useAnalytics is a small shim over Firebase  */
const useAnalytics = ({
  debug,
  autoSession,
}: { debug?: boolean; autoSession?: boolean } = {}) => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const previousRef = React.useRef<any>()
  const sessionRef = React.useRef<string | null>()
  const { analyticsEnabled } = useAppTracking()
  const faroClient = useFaroClient()
  useEffect(() => {
    if (!analyticsEnabled) {
      return
    }
    init()
  }, [analyticsEnabled])

  useEffect(() => {
    if (!autoSession) {
      return
    }
    const initialSessionId = nanoid()
    sessionRef.current = initialSessionId
  }, [autoSession])

  function setSessionId(sId: string | null) {
    sessionRef.current = sId
  }
  function resetSessionId() {
    setSessionId(null)
  }
  function newSessionId() {
    setSessionId(nanoid())
  }
  const shouldDebug = debug || process.env.REACT_APP_ANALYTICS_HOOK_DEBUG
  const logEventRaw: typeof log = async (name, params) => {
    if (process.env.NODE_ENV === "development") {
      addDebugAnalytic({ name, params })
    }    
    // if (window.__Cypress__) {
    //   return
    // }
    if (!analyticsEnabled) {
      return
    }
    const parameters = {
      ...(!!sessionRef.current && {
        sessionId: sessionRef.current,
      }),
      ...params,
      ...params?.extra,
    }
    faroClient?.api.pushEvent(name, serializeAsObj(params))
    console.debug("analytics", name, params)
    if (shouldDebug) {
      console.groupCollapsed(
        `%c[Analytics ${name}]`,
        "color: green; font-weight:bold"
      )
      console.groupEnd()
    }
    previousRef.current = parameters
    mixpanel.track(name, {
      $distinctId: subscriptionId,
      ...parameters,
    })
    _log({
      name,
      params: {
        subscriptionId,
        ...parameters,
      },
    })
  }
  const logNavigation = (params: { buttonName: string; href: string }) => {
    // TODO: this should call FireBaseAnalytics.setScreenName (should be available as firebase.SetScreenName)
    // See https://github.com/capacitor-community/firebase-analytics
    logEventRaw("Navigation", params)
  }
  const logOrderProcess: LogOrderProcess = ({ attributes, ...rest }) => {
    logEventRaw("Order Process", { ...attributes, ...rest })
  }

  return {
    /** A session is a short-lived session that is used to track the same order of events.
     *
     * For instance, if a user opens a dialog, we typically want to track each subsequent event
     * in the same session. If the user closes the dialog, we may wish to click close the session.
     */
    session: {
      newSessionId,
      resetSessionId,
      setSessionId,
      getSessionId: () => sessionRef.current,
      _ref: sessionRef,
    },
    /** User views a single item */
    logViewItem: (params: BaseArgs) => logEventRaw("view_item", params),

    getPrevious: () => previousRef.current,

    /** User views a list of items */
    logViewItemList: (
      params: Pick<BaseArgs, "__typename" | "name" | "extra"> & {
        count?: number
      }
    ) => logEventRaw("view_item_list", params),

    logOrderProcess,
    /** User performs a search */
    logSearch: (params: {
      returnCount?: number
      filters?: any
      searchQuery: string
      kind: string
    }) => logEventRaw("search", params),
    logNavigation,
    logOrderProcessCancellation: () => {
      const previous = previousRef.current
      if (!previous) {
        return
      }
      const { stepName } = previous
      if (!stepName) {
        return
      }
      logOrderProcess({ ...previous, didCancel: true })
    },
    onHrefClick:
      (buttonName: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        const href = e?.currentTarget?.href
        if (!href) {
          return
        }
        logNavigation({ buttonName, href })
      },

    /** User performed a purchase */
    logPurchase: ({ id, __typename: type, name, price, extra }: BaseArgs) => {
      logEventRaw("ecommerce_purchase", {
        name: "purchase",
        params: {
          subscriptionId,
          name,
          id,
          type,
          price,
          extra,
        },
      })
    },

    /** General log. */
    logEventRaw,

    /** Whether or not analytics is available. Note that it is not needed to check this parameter before logging
     *
     * If analytics is not available (most likely deactivated) the calls to these log-methods will silently fail.
     */
    available: analyticsEnabled,
  }
}

const blackhole = () => {}
const useAnalyticsMock: typeof useAnalytics = (_) => {
  return {
    session: {
      newSessionId: blackhole,
      resetSessionId: blackhole,
      setSessionId: blackhole,
      getSessionId: () => nanoid(),
      _ref: { current: "" },
    },
    logViewItem: Promise.resolve,

    getPrevious: blackhole,

    logViewItemList: Promise.resolve,
    logOrderProcess: blackhole,
    logSearch: Promise.resolve,
    logNavigation: blackhole,
    logOrderProcessCancellation: blackhole,
    onHrefClick: () => blackhole,

    logPurchase: blackhole,

    logEventRaw: Promise.resolve,

    available: false,
  }
}
if (process.env.NODE_ENV === "test") {
  // return useAnalyticsMock()
}

export default process.env.NODE_ENV === "test" ? useAnalyticsMock : useAnalytics
