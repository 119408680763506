import React from "react"
import { PuxDialogTitle } from "@phonero/pux-react"
import { AnyFunc } from "simplytyped"
import { useGoBack } from "../../util/useGoBack"

function DialogTitle({
  onClose,
  onWillClose,
  children,
  onBackClick,
  withGoBack,
  ...rest
}: {
  children?: any
  onClose?: AnyFunc
  onWillClose?: AnyFunc
  onBackClick?: AnyFunc
  withGoBack?: boolean
} & Parameters<typeof useGoBack>[0]) {
  const { goBack } = useGoBack(rest)
  function handleOnlick(e: any) {
    if (onClose) {
      return onClose(e)
    }
    if (onWillClose) {
      onWillClose(e)
    }
  }
  const showBackButton = !!withGoBack || !!onBackClick
  return (
    <PuxDialogTitle
      {...(showBackButton && {
        withBackButton: showBackButton,
        onBackClick: onBackClick ? onBackClick : goBack,
      })}
      withBackButton={showBackButton}
      withDismissButton={true}
      onDismissClick={handleOnlick}
    >
      {children}
    </PuxDialogTitle>
  )
}

export default DialogTitle
