import {PropsWithChildren, createContext } from "react";
import { Faro } from "@grafana/faro-react";
import { initializeFaro } from "./initializFaro";
import { useAppConstants } from "../AppConstants";

export const FaroClientContext = createContext<Faro | null>(null);

export const FaroClientProvider = ({ children }: PropsWithChildren) => {
    const { phoneroConfig, buildInfo} = useAppConstants()
    const faroClient = phoneroConfig && !phoneroConfig.collectorDisabled ? initializeFaro(phoneroConfig, buildInfo) : null
    return <FaroClientContext.Provider value={faroClient}>{children}</FaroClientContext.Provider>
}