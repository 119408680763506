import { PhoneroConfig } from "@phonero/common-ux"

export const isDev = import.meta.env.MODE === "development"
export const isProd = import.meta.env.MODE === "production"

export const isApp = !!window.cordova
const maintenanceMessageUrl = window["env"]["MAINTENANCE_MESSAGE_URL"]
const authEndpoint = window["env"]["AUTH_ENDPOINT"] || process.env.AUTH_ENDPOINT //"https://idp.dev.phonero.io/auth"
export const graphqlEndpoint =
  window["env"]["GRAPHQL_ENDPOINT"] || process.env.GRAPHQL_ENDPOINT //"https://api.dev.phonero.io/graphql"

export const growthBookApi = window["env"]["GB_API"]
export const growthBookClientKey = window["env"]["GB_CLIENT_KEY"]

export const phoneroConfig: PhoneroConfig = {
  devEnv: isDev,
  collectorIgnoreConsole: [
    // ignore these error-messages
    "Each child in a list should have a unique",
    // Cordova logs some annoying messages when running in a browser in produciton-build..
    "cordova_not_available",
    "but Cordova is not available",
    // Pux-gauge logs an error about this, but it is not important
    "received value was NaN",
    "Cannot read properties of null",
  ],
  // collectorBeforeSendObjPatterns: {
  //   "payload.message": [
  //   ],
  // },
  authEndpoint,
  maintenanceMessageUrl,
  collectorApplicationName: "dpw",
  // the collector should be available directly on load.
  // therefore, we need the variables before we load app.json and such
  collectorDisabled: true,
  collectorApiKey: "pFL3l/dacZIkAARMpelUqzMtZt153OSSjiCOnMfqjiE=",
  collectorUrl: "https://faro.dev.phonero.io/collect",
}
export const buildInfo = {
  number: BUILD.VERSION,
  branch: process.env.GIT_BRANCH,
  date: process.env.GIT_AUTHOR_DATE, //"2021-04-16T08:27:14Z",
  tag: BUILD.VERSION,
  hash: process.env.GIT_HASH,
}

// Note: The configuration has grown too advanced. We need something simpler
// Requirements:
// 1. The configuration should come from the server, somewhere.
// 2. The configuration needs to be served fast, so the user do not need to wait for it.
// 3. The configuration should probably be cached somehow, but currently this is causing some headache.
//
// Perhaps we should just stick to loading `app.json` and forcing a spinner until it is configured.

/** Indicator for if graphql-toasts should be enabled or not.
 *
 * Defaults to opt-in, but is planned to later be opt-out.
 *
 * Developers are encouraged to set this to `opt-out` during local development.
 */
export const graphqlToastBehaviour: "opt-in" | "opt-out" =
  import.meta.env.VITE_APP_GRAPHQL_TOAST_BEHAVIOUR === "opt-out"
    ? "opt-out"
    : "opt-in"

export const isDevEnv = phoneroConfig.devEnv
export const isStaging =
  phoneroConfig.stagingEnv ?? window.location.host.includes("staging")
export const isProductionApi = () =>
  (phoneroConfig.graphqlEndpoint || "0").includes("//api.phonero.no/")

export const featureFlags = {
  /** Enables the "new" paymentmethod introduced in MOIT-886 */
  vipps: true,
  /** Enables the legacy paymentmethod before MOIT-886 */
  vippsLegacy: false,
  showCostProfileMesssageInBuyDialog: true,
  costTransferArchiveView: isDevEnv,
  buildInfo: true,
  showOrderBadge: true,
  showMyReceipts: true,
  allowChangeSubscriptionInDifferentOffering: isDevEnv,
  devVippsCallbackRedirectstoHome: false,
  allowEditSimName: isDevEnv,
  /**
    If set, the user can click on any translated value within the application and change that translation +++.
    Enabling this feature may have performance-issues
  */
  ...phoneroConfig.featureFlags,
}

export const keycloakOptions = {
  url: authEndpoint,
  clientId: "ditt.phonero.no",
  realm: "phonero",
  ...phoneroConfig.keycloak,
}

export const authProps = {
  url: authEndpoint,
  enableMock: false,
  mock: {} as any,
  env: "prod",
  options: keycloakOptions,
}

export const graphqlEndpointIsMockable = (
  endpoint: string | null | undefined
) => false

export function setAuthProps() {
  console.info("DEPRICATING this, pending new solution")
  return false
}

export const sheetStyles = { maxWidth: 1200 }
