import { generatePath } from "react-router-dom"
import { RouteKeys, routes } from "../routes"
import { formatPeriodForUrl, parenRegex } from "@phonero/common-ux"
import {
  CostSharingProfileRuleType,
  ExpenseSubCategory,
} from "@phonero/common-graphql/types"
import { GetKey, HasKey, If } from "simplytyped"

/** Returns a route with a path ready for use with hrefs.
 * NOTE: Not all paths have typed parameters.
 * You can add a type in RouteParams
 * */
export const createRoutePath = <K extends RouteKeys>(key: K, params: P<K>) => {
  let languageIndex = 1
  let route = routes[key]
  let path = route.path.replace(parenRegex, (paren) => {
    const items = paren.slice(1, -1).split("|")
    return items[languageIndex] || items[items.length - 1]
  })
  if (params && path.includes(":")) {
    try {
      path = generatePath(
        path,
        key in RouteParams ? RouteParams[key as any](params) : params
      )
    } catch (err) {}
  }
  return { ...route, path }
}

/** used to map of params used for navigation. Also works as typings for createRoutePath */
const RouteParams = {
  costTransferArchiveMonthDetailPage: (props: {
    /** IS THIS TYPE CORRECT? */
    period: string | Date | undefined
    /** IS THIS TYPE CORRECT? */
    category: ExpenseSubCategory | CostSharingProfileRuleType
  }) => {
    const shortPeriod = formatPeriodForUrl(
      props.period ? new Date(props.period) : new Date()
    )
    return {
      ...props,
      period: shortPeriod,
      category: props.category.toLowerCase(),
    }
  },
  costTransferArchiveMonthPage: (props: {
    period: string | Date | undefined
  }) => {
    const shortPeriod = formatPeriodForUrl(
      props.period ? new Date(props.period) : new Date()
    )
    return {
      ...props,
      period: shortPeriod,
    }
  },
  costTransferPeriodMonthDetailPage: (props: {
    category: ExpenseSubCategory | CostSharingProfileRuleType
    period: string | Date | undefined
  }) => {
    const shortPeriod = formatPeriodForUrl(
      props.period ? new Date(props.period) : new Date()
    )
    return {
      ...props,
      category: props.category.toLowerCase(),
      period: shortPeriod,
    }
  },
} as const

type P<K extends string> = If<
  HasKey<typeof RouteParams, K>,
  Parameters<GetKey<typeof RouteParams, K>>[0],
  Record<string, any>
>
