import { FunctionComponent } from "react"
import css from "./Loading.module.scss"
import classNames from "classnames"

export interface ILoading {
  noPadding?: boolean
  size?: "small" | undefined
}

export const Loading: FunctionComponent<ILoading> = ({ noPadding, size }) => {
  return (
    <div
      className={classNames(css.loader, {
        [css.loaderSmall]: size === "small",
      })}
      data-pux-no-padding={noPadding}
    ></div>
  )
}
