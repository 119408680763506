import React, { useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import { checkOnlyMatchOneOrMore, OnlyIfRouteMatch } from "./checkRouteMatch"
import { matchRoute } from "./useRouteMatcher"
import { useParams } from "react-router-dom"
import { useAppConstants } from "@phonero/common-ux"

let lastGoBack: Date

// WIP.

/** provides a goBack-function that should *never* navigate the user out of the application
 *
 * It should navigate the user upwards in the hierarchy, using history.goBack if that was the previous location
 * otherwise it uses history.push. This reduces the history-size, so users do not feel like they have to click goBack
 * too many times.
 *
 * On mobile, it keeps seperate stacks per tab. If the current path is the root-path of the tab, it will not do a navigation, since that would chagne the active tab.
 */
export function useGoBack({
  didGoBack,
  ignoreHash,
  onlyIfRouteMatch,
}: {
  /** if set, will only perform actions if the route matches */
  onlyIfRouteMatch?: OnlyIfRouteMatch | OnlyIfRouteMatch[]

  ignoreHash?: boolean
  didGoBack?: (nextPath: string) => void
} = {}) {
  const location = useLocation()
  const history = useHistory()
  const lastLocation = useLastLocation()
  const params = useParams()
  let willGoBack = false
  let nextPath = ""

  const { isDevEnv } = useAppConstants()
  const root = isRootPath(
    location.pathname,
    window.innerWidth < 768,
    isDevEnv ?? false
  )
  let ignore = false

  if (onlyIfRouteMatch) {
    const result = checkOnlyMatchOneOrMore(onlyIfRouteMatch, location)
    switch (result) {
      case null:
        ignore = true
        break
      case true:
        ignore = false
        break
      case false:
        ignore = true
        break

      default:
        break
    }
  }
  if (!ignore) {
    // Checking params to support back from receipt-view to both activityPage and my receipts
    if (location.search || !!Object.keys(params).length) {
      willGoBack = true
    } else if (location.hash && !ignoreHash) {
      if (
        lastLocation &&
        lastLocation.pathname === location.pathname &&
        !lastLocation.hash
      ) {
        willGoBack = true
      } else {
        nextPath = location.pathname
      }
    } /* else if (
      !!lastLocation &&
      /^\/?(aktivitet|foresporsler)\/?$/.test(location.pathname)
    ) {
      willGoBack = true
    } */ else if (!root) {
      const split = location.pathname.replace(/(^\/?|\/$)/, "").split("/")
      nextPath = "/" + split.slice(0, split.length - 1).join("/")
      if (nextPath === lastLocation?.pathname) {
        nextPath = ""
        willGoBack = true
      }
    }
  }
  const goBack = useCallback(
    (
      eventIsh:
        | { onDidGoBack?: (nextPath: string) => void; amount?: number }
        // We allow passing in a MouseEvent, since it very convenient for the developer.
        | React.MouseEvent = {}
    ) => {
      if (!nextPath && !willGoBack) {
        return
      }
      const onDidGoBack =
        ("onDidGoBack" in eventIsh && eventIsh.onDidGoBack) || undefined
      // in case the function is triggered twice. This can happen if there are multiple callers,
      // for instance if listening to PuxModals onWillDismiss and a different close-key.
      const now = new Date()
      if (lastGoBack && now.getTime() - lastGoBack.getTime() < 150) {
        return
      }

      lastGoBack = now
      if (willGoBack) {
        const amount = eventIsh && "amount" in eventIsh && eventIsh.amount
        if (amount) {
          history.go(-amount)
        } else {
          history.goBack()
        }
      } else {
        history.push(nextPath)
      }
      didGoBack?.(nextPath)
      onDidGoBack?.(nextPath)
    },
    [nextPath, history, didGoBack, willGoBack]
  )
  const nextLocation: typeof lastLocation = willGoBack
    ? lastLocation
    : {
        pathname: nextPath,
        hash: "",
        search: "",
        state: undefined,
      }

  const nextRoute = !!nextLocation ? matchRoute(nextLocation?.pathname) : null
  return {
    nextRoute,
    canNavigate: !!nextPath || willGoBack,
    nextLocation,
    goBack,
    lastLocation,
    willGoBack,
  }
}

function isRootPath(s: string, isMobile: boolean, isDevEnv: boolean) {
  // intentionally using localStorage instead of storage
  // This is just a switch used for development.
  if (!isDevEnv || !localStorage.getItem("mobileNavUseRoot")) {
    // This is suggested to be the default behaviour for end-users
    // This is more in line with the old behaviour
    return s === "/"
  }
  if (s === "/") {
    return true
  }
  if (!isMobile) {
    return false
  }
  // This behaviour does not allow 'goBack' to exit the current tab on mobile.
  // It is more in line with how back-buttons work in general on ios.
  const r = /^\/?(abonnement|tjenester|forbruk)\/?$/.test(s)
  return r
}
