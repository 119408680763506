import isEqual from "lodash.isequal"
import { useHistory, useLocation } from "react-router-dom"

function parseTo<T>(val: T, defaultValue: T) {
  if (val === undefined || (Array.isArray(val) && !val.length)) {
    return defaultValue
  }
  const v = Array.isArray(val) ? val[0] : val
  switch (typeof defaultValue) {
    case "string":
      return v
    case "boolean":
      return v === "1" || v === "false" || v === "y" || v === "Y"
    case "object":
      if (Array.isArray(defaultValue) && Array.isArray(val)) {
        return val.map((v2) => parseTo(v2, defaultValue[0]))
      }
      console.warn("Could not parse this defaultValue", defaultValue)
      break
    case "number":
      const i = Number(v)
      if (!isNaN(i)) {
        return i
      }
      break

    default:
      console.warn("Could not parse this defaultValue", defaultValue)
      return defaultValue
  }
}

interface Options {
  /** If set, will not show default-values */
  skipDefaultValues?: boolean
}

/** Parses and sets SearchParams, with defaults and object-structure
 *
 * @todo: Decode/encode uri-key/values. Fot now, only handles simple values.
 */
export function objectAsURLSearchParams<T extends Object>(
  searchString: string,
  historyPusher: (s: string) => any,
  structure: T,
  { skipDefaultValues = true }: Options = {}
) {
  const q = new URLSearchParams(searchString)
  const params = Object.keys(structure).reduce((r, key) => {
    r[key] = structure[key]
    const v = q.getAll(key)
    if (!v.length) {
      return r
    }
    r[key] = parseTo(v, r[key])
    return r
  }, {} as T)

  function setAllRaw(t: T) {
    const r = new URLSearchParams()
    for (const key of Object.keys(t)) {
      const vv = t[key]
      const def = structure[key]
      // Skip values that are the default-value
      if (skipDefaultValues && isEqual(def, vv)) {
        continue
      }
      if (def !== undefined) {
        switch (typeof def) {
          case "string":
          case "number":
            r.set(key, vv)
            break
          case "boolean":
            r.set(key, vv ? "y" : "n")
            break
          default:
            //TODO: handle nested array, objects, etc.
            if (Array.isArray(vv)) {
              for (const val of vv) {
                if (typeof val === "string") {
                  r.append(key, val)
                }
              }
            }
            break
        }
        continue
      }
      console.warn(
        `Got URLSearchParam (${key}) that is not set in defaultValues. It will be ignored.`
      )
    }
    const str = r.toString()
    historyPusher(str ? "?" + r.toString() : "")
  }
  function set(input: ((t: T) => T) | T) {
    if (typeof input === "function") {
      const n = (input as any)(params)
      return setAllRaw(n)
    }
    setAllRaw(input)
  }
  return {
    ...params,
    params,
    set,
    q,
  }
}

/** Simplified usage of URLSearchParams
 *
 * Takes in an object with default-values for the structure that you wish to use.
 * This structure will be returned, along with `set`-method which can be used to set
 * the params as you wish.
 *
 * @example
 *
 * ```typescript
 * const {
 *   search, // foo
 *   checked, // false
 *   set
 * } = useURLSearchParams({search: "foo", checked: false})
 * // (url should now be / because it ignores defaultvalues, but `search` is 'foo')
 *
 * // later, user searches for 'bar'
 * function handleSearch(value: string) {
 *   set(state => ({...state, search: value}))
 *   // (url should now be /?search=bar, and `search` is updated to 'foo')
 * }
 * ```
 */
function useURLSearchParams<T extends Object>(
  structure: T,
  options: Options = {}
) {
  const location = useLocation()
  const history = useHistory()
  const { pathname, hash, state } = location

  function historyPusher(s: string) {
    const newRoute = pathname + s + hash
    history.push(newRoute, state)
  }
  return objectAsURLSearchParams(
    location.search,
    historyPusher,
    structure,
    options
  )
}
export default useURLSearchParams
