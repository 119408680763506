interface Options {
  country: keyof typeof countryVatMap
}

const countryVatMap = {
  no: 1.25,
}
export const calcVat = (
  n: number,
  add: boolean,
  { country }: Options = { country: "no" }
) => {
  const f = countryVatMap[country]
  if (!f) {
    throw new Error("The specified country does not exist in our vat-mapping.")
  }
  if (add) {
    return n * f
  }
  return n / f
}

export const addVat = (priceExcludingVat: number, options?: Options) =>
  calcVat(priceExcludingVat, true, options)
export const removeVat = (priceIncludingVat: number, options?: Options) =>
  calcVat(priceIncludingVat, false, options)
