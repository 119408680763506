export function getOfferingId(compositId: string) {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const parts = compositId.split(":")

  if (parts.length > 2) return 0
  if (parts.length === 1) return parts

  const source = parts[0]

  if (source === "PO") {
    //Potential offering with offeringId = 0, but CPT > 0
    return 0
  } else if (source === "O") {
    //Actual offering with offeringid > 0
    return parts[1]
  } else return 0
}

export function isPotentialOffering(compositId: string) {
  return compositId.includes("PO")
}
