import {
  PuxList,
  PuxItem,
  PuxSheet,
  PuxLabel,
  PuxButton,
} from "@phonero/pux-react"
import classNames from "classnames"
import { AnyFunc } from "simplytyped"
import { AvatarInitials } from "./AvatarInitials"
import css from "./MenuProfile.module.scss"
import { useAppTranslations } from "@phonero/common-ux"
import { openLink } from "../../../util/openLink"

export const MenuProfile: React.FC<{
  keycloak: Keycloak.KeycloakInstance
  given_name: string
  family_name: string
  onLogout: AnyFunc
}> = ({ keycloak, given_name, family_name, onLogout }) => {
  const { t } = useAppTranslations()
  const accountUrl = keycloak.createAccountUrl()

  return (
    <PuxSheet className={css.menuPadding}>
      <div className={css.userMenuProfile}>
        <PuxLabel>
          {given_name} {family_name}
        </PuxLabel>

        <AvatarInitials
          style={{
            marginTop: "20px",
            fontSize: "3rem",
            width: "66px",
            height: "66px",
          }}
          theme={"light"}
          givenName={given_name}
          familyName={family_name}
        ></AvatarInitials>

        <PuxButton
          onClick={(e) => {
            e.preventDefault()
            openLink(accountUrl)
          }}
          href={accountUrl}
          fill="outline"
          size="default"
          class={css.showAccountButton}
          style={{
            "--background": "#FFFFFF",
            "--background-hover": "var(--pux-color-primary)",
            "--border-color": "var(--pux-color-primary)",
            "--color-hover": "#FFFFFF",
          }}
        >
          {t("General.ShowAccount")}
        </PuxButton>
      </div>
      <PuxList>
        <hr className={css.hr} />
        <PuxItem
          button
          className={classNames("puxButton", css.lineContainer)}
          onClick={onLogout}
          color="light"
        >
          <span>{t("General.Logout")}</span>
          <span className={css.line} />
        </PuxItem>
      </PuxList>
    </PuxSheet>
  )
}
