import React, { lazy } from "react"
import { useKeycloak, useAppTranslations } from "@phonero/common-ux"

import {
  PuxButton,
  PuxCard,
  PuxCardContent,
  PuxCardHeader,
  PuxCardTitle,
  PuxSheet,
} from "@phonero/pux-react"
import { Footer, PageContent } from "../../components/layout"
import { isDevEnv } from "../../appConstants"
import { isCapacitor } from "@phonero/common-ux"

const AppDevThing = lazy(
  () => import(/* webpackChunkName: 'DevView' */ "../../DevView")
)
const DevMenu = lazy(
  () => import(/* webpackChunkName: 'DevMenu' */ "../../DevMenu")
)
/** see https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-inappbrowser/ */

const cordovaOptions = {
  hidenavigationbuttons: "yes",
  closebuttoncaption: "Lukk",
  closebuttoncolor: "#ffffff",
  keyboardDisplayRequiresUserAction: "yes",
  transitionstyle: "fliphorizontal",
  suppressesIncrementalRendering: "yes",
}

export const SplashScreen = () => {
  const { keycloak } = useKeycloak()
  const { t } = useAppTranslations()
  const scope = isCapacitor ? "offline_access" : ""

  return (
    <PageContent title="" backLink={false}>
      <PuxSheet>
        <PuxCard>
          <PuxCardHeader>
            <PuxCardTitle>{t("Welcome.Login")}</PuxCardTitle>
          </PuxCardHeader>
          <PuxCardContent>
            {t("Welcome.LoginDescription", {
              mdInline: false,
            })}
            <PuxButton
              size="large"
              color="dark"
              onClick={() => keycloak.login({ cordovaOptions, scope })}
            >
              {t("Welcome.Login")}
            </PuxButton>
          </PuxCardContent>
        </PuxCard>
        {isDevEnv && (
          <React.Suspense fallback="...">
            {keycloak.authServerUrl}
            <DevMenu />
            <AppDevThing />
          </React.Suspense>
        )}
      </PuxSheet>
      <Footer />
    </PageContent>
  )
}
export default SplashScreen
