import { makeVar, useReactiveVar } from "@apollo/client"

type Analytic = {
  name: string
  params: NonNullable<unknown>
  date: Date
  id: string
}

export const lastAnalytics = makeVar<Analytic[]>([])

export const addDebugAnalytic = (analytic: { name: string; params: NonNullable<unknown> }) => {
  const last = lastAnalytics()
  const a = {
    ...analytic,
    date: new Date(),
    id: String(last.length),
  }
  lastAnalytics([a, ...last])
}

/** Used to debug analytics */
export const useLastAnalytics = () => useReactiveVar(lastAnalytics)
