import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { isApp } from "../appConstants"
import { toLocalUrl } from "./localUrl"

export const appinfo: {
  isActive?: boolean
  launchUrl?: string
  info?: {
    id: string
    version: string
    name: string
    build: string
  }
  initialLocation: string
  /** Used for debugging launch-urls in app */
  appUrlOpen: string[]
  /** Used for debugging launch-urls in app */
  appUrlSlugs: string[]
} = { appUrlOpen: [], appUrlSlugs: [], initialLocation: window.location.href }

if (isApp) {
  App.getLaunchUrl().then((launchUrl) => (appinfo.launchUrl = launchUrl?.url))
  App.getInfo().then((inf) => (appinfo.info = inf))
}

const useAppUrl = () => {
  const history = useHistory()
  useEffect(() => {
    // if (!isApp) {
    //   return
    // }
    const listenerPromises = [
      App.addListener("appStateChange", (event) => {
        appinfo.isActive = event.isActive
      }),
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        // For Vipps får vi inn en url som:
        // "https://ditt.phonero.no/paymentCallback/Vipps/V0wHQ7MTENq6PA9Xf9BbBD-BC3-bDuc2?status=202"
        // Vi må fjerne alt før første slash, så url'en ovenfor blir til:
        // "/paymentCallback/Vipps/V0wHQ7MTENq6PA9Xf9BbBD-BC3-bDuc2?status=202"
        // Android bruker 'ditt.phoneoro.no://'
        // iOS bruker 'ditt://'
        if (!event.url) {
          return
        }
        const slug = toLocalUrl(event.url)
        appinfo.appUrlOpen.push(event.url)
        appinfo.appUrlSlugs.push(slug)
        if (slug && event.url !== slug) {
          history.push(slug)
        }
        // If no match, do nothing - let regular routing
        // logic take over
      }),
    ]
    return () => {
      listenerPromises.forEach((p) => p.then((l) => l.remove()))
    }
  }, [history])

  return null
}

export default useAppUrl
