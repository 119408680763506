import { OrderStatusId } from "@phonero/common-graphql/types"
import { PredefinedColors } from "@phonero/pux-core"
import { PuxIcon, PuxText } from "@phonero/pux-react"
import { orderStatusMap } from "./dataMaps"
import { useAppTranslations } from "../util"

export function OrderStatusInline({
  status,
  noIcon,
  color,
  ...rest
}: {
  noIcon?: boolean
  status: OrderStatusId
  color?: PredefinedColors
} & Omit<JSX.IntrinsicElements["span"], "ref" | "color">) {
  const { t } = useAppTranslations()
  const b = orderStatusMap[status]
  const Container = color ? PuxText : "span"
  return (
    <Container color={b.color} {...rest}>
      {!noIcon && <PuxIcon size="small" icon={b.icon} style={{ marginRight: 4, verticalAlign: "top" }} />}
      {t("orders.status", { context: status })}
    </Container>
  )
}
