import React, { FC } from "react"
import { LoadingPlaceholder } from "./LoadingPlaceholder"
import { useBreakpoint } from "@phonero/pux-react"
import { isEmpty, CodewordType, getWidestAvailablePackageSize } from "../util"
import { DialogMessage } from "./DialogMessage"
import { Product, ProductItem, PuxItemProps } from "./ProductItem"
import { CampaignDataPackages } from "./CampaignDataPackages"
import { CampaignBoostPackages, CampaignBoostPackagesTempForEasterCampaign } from "./CampaignBoostPackages"
import { ApiProductState } from "@phonero/common-graphql/types"
import { PromotionProducts } from "./PromotionProducts"
import { PhoneroCloudProductId } from "../Cloud/PhoneroCloudSettings"

export interface ProductListProps {
  products: (Pick<Product, "id" | "price"> & { priceDiff?: number })[]
  placeholders: number
  Component: any
  onSelectProduct?: (product: Product) => void
  animated: boolean
  disabled?: boolean
  emptyTitle?: React.ReactNode
  emptyMsg?: React.ReactNode
  accordian?: boolean | React.ReactNode | ((product: Product) => React.ReactNode)
}

export const ProductList: FC<ProductListProps & PuxItemProps> = ({
  products,
  placeholders,
  animated,
  emptyMsg = "Her er det foreløpig ingen produkter tilgjengelig på ditt abonnement.",
  emptyTitle = "Ingen tilgjengelig",
  ...puxItemProps
}) => {
  const [selected, setSelected] = React.useState<string | null>(null)
  const sm = useBreakpoint("sm")

  if (isEmpty(products)) {
    if (animated) {
      return <LoadingPlaceholder count={placeholders} animated={animated} />
    }
    return <DialogMessage title={emptyTitle as any}>{emptyMsg}</DialogMessage>
  }
  const widestAvailablePackageSize = getWidestAvailablePackageSize(products)

  const stateFilter = (p: Product, state: ApiProductState) => p && "state" in p && p["state"] === state
  const productIdFilter = (p: Product, productId: number) => p && "productId" in p && p["productId"] === productId
  const isPromotionFilter = (p: Product) => p && "isPromotion" in p && p["isPromotion"] === true
  const promotionProducts = products?.filter(
    (p) =>
      (stateFilter(p, ApiProductState.Registered) ||
        stateFilter(p, ApiProductState.Ordered) ||
        stateFilter(p, ApiProductState.Startactivation) ||
        stateFilter(p, ApiProductState.Activating)) &&
      productIdFilter(p, PhoneroCloudProductId) &&
      isPromotionFilter(p)
  )

  const boostCampaignProducts = products?.filter(
    (p) => p && "campaign" in p && p["campaign"] && p["type"] === CodewordType.ExtraBoost && p["theme"] !== "Easter"
  )

  //Temp reconstruct old Easter campaign rendering
  const easterBoostCampaignProducts = products?.filter(
    (p) => p && "campaign" in p && p["campaign"] && p["type"] === CodewordType.ExtraBoost && p["theme"] === "Easter"
  )

  const extraDataCampaignProducts = products?.filter(
    (p) => p && "campaign" in p && p["campaign"] && p["type"] === CodewordType.ExtraDataEEA && p["sortOrder"]
  )

  const promotionProductIds = promotionProducts.reduce((r, p) => {
    r[p.id] = true
    return r
  }, {} as Record<string, boolean>)

  const nonCampaignProducts = products?.filter((p) => {
    if (promotionProductIds[p.id]) {
      return false
    }

    return p && (!("campaign" in p) || !p["campaign"])
  })

  return (
    <>
      <PromotionProducts
        promotionProducts={promotionProducts}
        selected={selected}
        setSelected={setSelected}
        widestAvailablePackageSize={widestAvailablePackageSize}
        {...puxItemProps}
      />

      <CampaignDataPackages dataCampaignProducts={extraDataCampaignProducts} {...puxItemProps} />

      <CampaignBoostPackages
        boostCampaignProducts={boostCampaignProducts}
        selected={selected}
        setSelected={setSelected}
        widestAvailablePackageSize={widestAvailablePackageSize}
        {...puxItemProps}
      />

      <CampaignBoostPackagesTempForEasterCampaign
        boostCampaignProducts={easterBoostCampaignProducts}
        selected={selected}
        setSelected={setSelected}
        widestAvailablePackageSize={widestAvailablePackageSize}
        {...puxItemProps}
      />

      {nonCampaignProducts?.map((product) => {
        return (
          <ProductItem
            key={product?.id}
            product={product}
            isSelected={product.id === selected}
            xs={!sm}
            setSelected={setSelected}
            widestAvailablePackageSize={widestAvailablePackageSize}
            {...puxItemProps}
          />
        )
      })}
    </>
  )
}
