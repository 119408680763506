import { generatePath, useHistory } from "react-router-dom"
import { useCurrentSubscriptionId } from "./useSubscriptionId"
import useAnalytics from "./useAnalytics"
import { useTranslation } from "react-i18next"
import { AnyFunc, objectKeys } from "simplytyped"
import { UrlHashes, useDialoges } from "./useDialoges"

export type AppRoute = {
  /** The path used by the router */
  path: string
  /** A string representing the name of the route. Will be used in titles, etc. */
  name: string
  /** If true, the route will not require authentication */
  isPublic?: boolean
  /** If true, no back-button will be present */
  noBackLink?: boolean
  /** If set, can be used to verify access before entering the page. */

  restrictions?: (isDev: boolean) =>
    | boolean
    | React.ReactNode
    | {
        /** If set, will redirect to the page. The value should be a RouteKey.*/
        redirect: string
        /** If set, will send to user to the login-flow */
        forceLogin: boolean
      }
}

type Route = { [key: string]: AppRoute }

export function getRouteKeys(routes: Route): string[] {
  const keys = objectKeys(routes).sort((a, b) => {
    let A = routes[a].path.split("/").length
    let B = routes[b].path.split("/").length
    if (A > B) {
      return -1
    }
    if (A < B) {
      return 1
    }
    A = routes[a].path.split(":").length
    B = routes[b].path.split(":").length
    if (A > B) {
      return 1
    }
    if (A < B) {
      return -1
    }
    return 0
  })
  return keys.map((k) => k as string)
}

/** Typesafe appRouter for each path
 *
 * To add a path, please @see routes.ts
 * @example
 *
 * Navigates to the current subscription
 * <button onClick={() => goTo.subscription()} >Subscription (current)</button>
 *
 * Navigates to the chosen subscription
 * @example
 *
 * <button onClick={() => goTo.subscription(1234)} >Subscription 1234</button>
 *
 * Navigates to the chosen subscriptions-activity
 * @example
 * <button onClick={() => goTo.subscriptionActivity(1234)} >Activity (1234)</button>
 *
 * If you only want to get the path directly
 */
export function useAppRouter<Params extends NonNullable<unknown>>(
  routes: Route
) {
  // TODO: since the routes no longer include the subscription
  // we should remove the dependency on the subscriptionId. It was a bad idea on my part. (subscriptionId should at most be prop)
  const history = useHistory()
  const analytics = useAnalytics({ debug: true })
  const dialogues = useDialoges()
  const { i18n } = useTranslation()
  const languageIndex = languageMap[i18n.language] || 0
  const { token, tokenSubscriptionId, subscriptionId } =
    useCurrentSubscriptionId<Params>()

  const args = {
    subscriptionId: tokenSubscriptionId,
  }
  const routeKeys = getRouteKeys(routes)

  /**  Go to */
  const goTo = (
    buttonName: string,
    /** @deprecated Please use the url-only */
    state?: any,
    additionalArgs?: Record<string, any>,
    replace?: boolean
  ) => {
    return goToCreator(
      (path) => {
        analytics.logNavigation({ buttonName, href: path })
        if (replace) {
          return history.replace(path, state)
        }
        return history.push(path, state)
      },
      languageIndex,
      { ...args, ...additionalArgs },
      routeKeys,
      routes
    )
  }
  const paths = goToCreator(null, languageIndex, args, routeKeys, routes)
  return {
    paths,
    history,
    subscriptionId,
    tokenSubscriptionId,
    goTo,
    token,
    urlHash: (hash: UrlHashes) => history.push(hash),
    dialogues,
  }
}
type RoutePusher = (s: string, state?: any) => any

export const parenRegex = /\([^)]*\)/g
function goToCreator(
  push: RoutePusher | null,
  languageIndex: number,
  params: Record<string, any>,
  routeKeys: (string | number)[],
  routes: Route
): Record<keyof typeof routes, AnyFunc> {
  return routeKeys.reduce(
    (r, key) => {
      r[key] = (...args: string[]) => {
        // Because some paths uses language-specific names (like
        // `(activity|aktivitet)`), we must replace the parenthesis and
        // choose one of the routes TODO: consider changing the original
        // path-function to an object.
        let path = (routes[key] as any).path.replace(parenRegex, (paren) => {
          const items = paren.slice(1, -1).split("|")
          return items[languageIndex] || items[items.length - 1]
        })
        if (params && path.includes(":")) {
          try {
            path = generatePath(path, params)
          } catch (err) {}
        }
        if (path[0] !== "/") {
          throw new Error(
            `Path must start with a '/', received '${path}' for routeKey '${key}'`
          )
        }
        if (args && args.length) {
          path = [path, ...args].join("/")
        }
        if (push) {
          if (path.includes(":")) {
            path = path.replace(/:\w*/g, "")
          }
          if (!path.includes("//")) {
            push(path)
          }
        }
        return path
      }
      return r
    },
    {} as {
      [key in keyof typeof routes]: (...args: string[]) => any
    }
  )
}

export const languageMap = {
  en: 0,
  nb: 1,
}
