import { FC } from "react"
import { PhoneroCloudLink } from "./PhoneroCloudLink"
import css from "./PhoneroCloudLinks.module.scss"

export interface PhoneroCloudLinksProps {
  hasActiveProduct: boolean
  productId: number
  styleType: "button" | "anchor"
}

export const PhoneroCloudLinks: FC<PhoneroCloudLinksProps> = (props: PhoneroCloudLinksProps) => {
  if (!props.hasActiveProduct) return <></>
  return (
    <div className={css.cloudLinks}>
      <PhoneroCloudLink productId={props.productId} styleType={props.styleType} />
    </div>
  )
}
