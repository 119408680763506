/** Hack for displaying the "correct" name for a subscription
 *
 * The name returned directly by the API is not translated.
 *
 * As a compromise, this currently uses the current mobileSubscriptions name as base for the naming
 * and adds size/sizetype as needed.
 *
 * Note that this means that it cannot be used with any other user.
 *
 * This also means that the name of the subscription really bears no meaning.
 */
export function subscriptionName(
  currentSubscription: { name?: any } | undefined | null,
  orderedProduct:
    | {
        size?: any
        sizeType?: any
        name?: any
        requestedProductName?: any
      }
    | undefined
    | null,
  showGbSuffix?: boolean
) {
  const { name: currentName = "" } = currentSubscription || {}
  const {
    size = 0,
    sizeType = "GB",
    //requestedProductName = "",
    name: productName = "",
  } = orderedProduct || {}
  if ((size || sizeType) && showGbSuffix) {
    return `${currentName} ${size}${sizeType}`
  }
  if (productName.startsWith("Ubegrenset")) {
    // Info about the unlimited subscriptin is on the product
    return productName.replace(" abonnement", "")
  }

  return `${currentName.replace(" abonnement", "")}`
  // return `${currentName} ${/[\d]*[KMGTP]B/.exec(requestedProductName)?.[0]}`// Removing XX GB/TB/... suffix if present
}
