import { AppVersion } from "@ionic-native/app-version"
import React from "react"

export type AppLaunchInfoType = {
  isActive?: boolean
  launchUrl?: string
  info?: {
    id: string
    version: string
    name: string
    build: string
  }
  initialLocation: string
  /** Used for debugging launch-urls in app */
  appUrlOpen: string[]
  /** Used for debugging launch-urls in app */
  appUrlSlugs: string[]
}

export const appLaunchInfo: AppLaunchInfoType = {
  appUrlOpen: [],
  appUrlSlugs: [],
  initialLocation: window.location.href,
}

export type AppInfoType = {
  versionCode: string | number
  versionNumber: string
  appName: string
  packageName: string
  set: boolean
  available: boolean | null
}

export const appInfo: AppInfoType = {
  versionCode: 0,
  appName: "",
  packageName: "",
  versionNumber: "",
  set: false,
  available: null,
}

async function getAppInfo() {
  if (appInfo.available === false) {
    return appInfo
  }

  if (appInfo.set) {
    return appInfo
  }
  try {
    // TODO: Fix this appstuff better in the future
    if (this.platform.is("cordova")) {
      const [versionCode, versionNumber, appName, packageName] =
        await Promise.all([
          AppVersion.getVersionCode(),
          AppVersion.getVersionNumber(),
          AppVersion.getAppName(),
          AppVersion.getPackageName(),
        ])
      appInfo.versionCode = versionCode
      appInfo.versionNumber = versionNumber
      appInfo.appName = appName
      appInfo.packageName = packageName
      appInfo.set = true
    }
  } catch (err) {
    appInfo.available = false
    return appInfo
  }

  return appInfo
}

export const useAppInfo = () => {
  const [state, setState] = React.useState(appInfo)
  const set = state.set
  React.useEffect(() => {
    if (set) {
      return
    }
    getAppInfo().then(setState)
  }, [set])
  return state
}
