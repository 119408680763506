import { close } from "@phonero/pux-icons/icons"
import { PuxIcon } from "@phonero/pux-react"
import { ApprovalStatusFlag, useAppQuery, MyReceiptsDocument } from "@phonero/common-ux"
import { PredefinedColors } from "@phonero/pux-core"
import { addMonths, addYears } from "date-fns"
import { useOrdersLastReadReceipts } from "../pages/receipts/useReceipts"
function MyReceiptsUnreadBadge(
  props: { color?: PredefinedColors; slot?: string } & Pick<
    JSX.IntrinsicElements["span"],
    "style"
  >
) {
  const aYearAgo = addYears(new Date(), -1)
  const tomorrow = addMonths(new Date(), 1)
  const { error, data, loading } = useAppQuery(MyReceiptsDocument, {
    variables: {
      from: aYearAgo.toISOString().substr(0, 7),
      to: tomorrow.toISOString().substr(0, 7),
      includePdf: false,
      includeFirstReceipt: true,
    },
  })

  const { isUnread } = useOrdersLastReadReceipts()

  if (error && !data) {
    return <PuxIcon icon={close} color="danger" {...props} />
  }
  if (!data && loading) {
    return null
  }
  const receipts = data?.me?.receipts?.nodes || []
  const receiptsUnread = receipts?.filter(isUnread) || []
  if (!receiptsUnread.length) {
    return null
  }

  return <ApprovalStatusFlag status="APPROVED" {...props} />
}

export default MyReceiptsUnreadBadge
