import { close } from "@phonero/pux-icons/icons"
import { PuxIcon } from "@phonero/pux-react"
import { OrderStatusId, SortEnumType } from "@phonero/common-graphql/types"
import * as React from "react"
import { ApprovalStatusFlag, useCurrentSubscriptionId, useAppQuery, OrdersDocument } from "@phonero/common-ux"
import { pollInterval } from "@phonero/common-ux"
import { PredefinedColors } from "@phonero/pux-core"
import { useOrdersLastRead } from "../util/useOrders"

function OrdersUnreadBadge(
  props: { color?: PredefinedColors; slot?: string } & Pick<
    JSX.IntrinsicElements["span"],
    "style"
  >
) {
  const { subscriptionId } = useCurrentSubscriptionId()
  const { data, loading, error, startPolling, stopPolling, client } =
    useAppQuery(OrdersDocument, {
      errorPolicy: "ignore",
      skip: !subscriptionId,
      variables: {
        first: 50,
        orderBy: {
          registrationDateTime: SortEnumType.Desc,
        },
        subscriptionID: subscriptionId,
        // TODO: when api supports filtering by date, we should only query those we actually need, and only request the count here
        withDetails: true,
      },
    })
  const allOrders = data?.mobileSubscription?.orders?.nodes
  const prev = React.useRef<string>("")
  const arePending =
    allOrders?.filter((o) => {
      switch (o?.status) {
        case OrderStatusId.Pendingapproval:
        case OrderStatusId.Pendingdelivery:
          return true
      }
      return false
    }) || false
  const pendingIds = arePending ? arePending.map((o) => o?.id).join(";") : ""
  React.useEffect(() => {
    // This useEffect has the responsibility of detecting when orders go from pending to not pendinging.
    // If it detects this, it should refresh certain (all?) queries so that their state is updated
    // Order matters
    const transitionedFromNonPendingtoPending = !prev.current && !!pendingIds
    const transitionedFromPendingtoNonPending = !!prev.current && !pendingIds
    switch (true) {
      case transitionedFromNonPendingtoPending:
        startPolling(pollInterval(2000))
        break
      case !!prev.current && prev.current?.length > pendingIds.length:
        // TODO: we should probably refetch just a selection of queries, instead of all of them.
        client.reFetchObservableQueries(false)
        break
      case transitionedFromPendingtoNonPending:
        stopPolling()
        break
    }
    prev.current = pendingIds
  }, [pendingIds, stopPolling, startPolling, client])
  const { isUnread } = useOrdersLastRead()

  if (error && !data) {
    return <PuxIcon icon={close} color="danger" {...props} />
  }
  if (!data && loading) {
    return null
  }
  const { nodes } = data?.mobileSubscription?.orders || {}
  const orders = nodes?.filter(isUnread) || []
  if (!orders.length) {
    return null
  }

  return <ApprovalStatusFlag status="APPROVED" {...props} />
}

export default OrdersUnreadBadge
