import { makeVar, useReactiveVar } from "@apollo/client"

export const offlineStatus = makeVar<{ online: boolean; lastUpdate: Date }>({
  online: navigator.onLine,
  lastUpdate: new Date(),
})

window.addEventListener("online", () =>
  offlineStatus({ online: navigator.onLine, lastUpdate: new Date() })
)
window.addEventListener("offline", () =>
  offlineStatus({ online: navigator.onLine, lastUpdate: new Date() })
)

const useOfflineStatus = () => useReactiveVar(offlineStatus)

export default useOfflineStatus
