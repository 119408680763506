import React, { FunctionComponent } from "react"
import { AnyFunc } from "simplytyped"
import { useBreakpoint } from "@phonero/pux-react"
import { IDataProduct, ProductList } from "../ProductList"
import { DataPackage } from "./DataPackage"

export interface IDataProductList {
  packages: IDataProduct[]
  onClick?: AnyFunc
  isActiveProduct?: boolean
  widestAvailablePackageSize: number
}

export const DataProductList: FunctionComponent<IDataProductList> = ({
  onClick = null,
  isActiveProduct = false,
  packages = [],
  widestAvailablePackageSize,
}) => {
  const xs = useBreakpoint("xs", { useMax: true })

  if (!packages) {
    return null
  }

  return (
    <ProductList
      products={packages}
      placeholders={3}
      animated={!packages}
      {...(onClick && {
        onSelectProduct: onClick,
      })}
      Component={(props) => (
        <DataPackage
          hasIconEnd={!xs}
          key={props.id}
          active={isActiveProduct}
          widestAvailablePackageSize={widestAvailablePackageSize}
          subscriptionName={props.name}
          {...props}
        />
      )}
    />
  )
}
