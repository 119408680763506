import { PredefinedColors } from "@phonero/pux-core"
import { info } from "@phonero/pux-icons/icons"
import {
  PuxButton,
  PuxIcon,
  PuxModal,
  PuxModalContent,
} from "@phonero/pux-react"
import * as React from "react"
import { Markdown } from "../util/markdownComponent"
import css from "./style.module.scss"
import { BrowserRouter as Router } from "react-router-dom"
import { LastLocationProvider } from "react-router-last-location"
import { Apollo } from "./Apollo"
import { Auth } from "./Auth"
import { AppConstantContextType, AppConstantProvider } from "../AppConstants"
import { CampaignThemeContextType, CampaignThemeProvider } from "../Themes"
import { FaroClientProvider } from "../Faro"
import { useEffect } from "react"

interface DetailMessage {
  message: string
  description: string
}
interface DetailOk {
  ok: boolean
}

function requestNavigationConfirmation(m: DetailMessage) {
  const event = new CustomEvent<DetailMessage>("request-confirm-navigation", {
    detail: m,
  })
  document.dispatchEvent(event)
}

const defaultMessage = "Er du sikker på at du vil forlate denne siden?"

export const dirtyState = {
  dirty: {},
}

export function Providers({
  children,
  appConstants,
  fragmentTypes,
  campaignThemes,
}: {
  children: React.ReactNode
  appConstants: AppConstantContextType
  fragmentTypes: any
  campaignThemes: CampaignThemeContextType
}) {
  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      if (!dirtyState.dirty || !Object.values(dirtyState.dirty).some(Boolean)) {
        return
      }
      // This message will not show on modern browsers, but is kept for backwards-compatilbility
      event.returnValue = defaultMessage
      event.preventDefault()
    })
  }, [])

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        const [firstLine, ...desc] = message.split("\n")
        requestNavigationConfirmation({
          message: firstLine,
          description: desc.join("\n"),
        })

        document.addEventListener(
          "confirm-navigation",
          (ev: CustomEvent<DetailOk>) => {
            callback(ev.detail.ok)
          }
        )
      }}
    >
      <LastLocationProvider>
        <AppConstantProvider value={appConstants}>
          <CampaignThemeProvider value={campaignThemes}>
            <Auth>
              <Apollo generatedIntrospection={fragmentTypes}>
                <FaroClientProvider>{children}</FaroClientProvider>
                <Confirm />
              </Apollo>
            </Auth>
          </CampaignThemeProvider>
        </AppConstantProvider>
      </LastLocationProvider>
    </Router>
  )
}

const Confirm = () => {
  const [msg, setMessage] = React.useState<DetailMessage | null>(null)
  const didClose = React.useRef(false)
  useEffect(() => {
    document.addEventListener(
      "request-confirm-navigation",
      (e: CustomEvent<DetailMessage>) => setMessage(e.detail)
    )
  })

  function onOk() {
    const event = new CustomEvent("confirm-navigation", {
      detail: { ok: true },
    })
    document.dispatchEvent(event)
    didClose.current = true
    setMessage(null)
  }
  function onClose() {
    if (didClose.current) {
      didClose.current = false
    } else {
      const event = new CustomEvent("confirm-navigation", {
        detail: { ok: false },
      })
      document.dispatchEvent(event)
    }
    setMessage(null)
  }
  return (
    <PuxModal
      isOpen={!!msg}
      onWillDismiss={onClose}
      cssClass={css.confirmModal}
    >
      <PuxModalContent>
        <div>
          <PuxIcon
            icon={info}
            color={"orange-dark" as PredefinedColors}
            className={css.confirmIcon}
          />
          <h4>{msg?.message || defaultMessage}</h4>
          {!!msg?.description && (
            <Markdown inline={false}>{msg.description}</Markdown>
          )}
          <div className={css.buttonRow}>
            <PuxButton fill="clear" onClick={onClose} color="primary">
              Bli på siden
            </PuxButton>
            <PuxButton onClick={onOk}>Forlat denne siden</PuxButton>
          </div>
        </div>
      </PuxModalContent>
    </PuxModal>
  )
}
