import React, { useEffect, useRef } from "react"
import { CodewordType } from "../util/typeHelpers"
import {
  ExtraDataPackageOfferingProduct,
  IOfferingProduct,
  ProductUnionType,
} from "@phonero/common-graphql/types"
import { UrlHashes, useAppTranslations, useDialoges } from "../util"
import { useLocation } from "react-router-dom"
import {
  PuxButton,
  PuxIcon,
  PuxInput,
  PuxItem,
  PuxModalContent,
  PuxSheet,
  PuxText,
  PuxTypography,
  useBreakpoint,
} from "@phonero/pux-react"
import { useDebouncedCallback } from "use-debounce"
import { closeSmall, search } from "@phonero/pux-icons/icons"
import { ProductList } from "./ProductList"
import { CountriesQuery } from "./Countries.generated"
import { QueryResult } from "@apollo/client"
import { ExtraDataPackage } from "./ExtraDataPackage"
import "./style.css"
import { useBreakpointBelowDesktop } from "../util/platform"
import { InlineError } from "../Error/InlineError"

export const urlHashMap = {
  [UrlHashes.data]: CodewordType.ExtraDataEEA,
  [UrlHashes.dataSubscription]: CodewordType.ExtraDataEEA,
  [UrlHashes.dataInternational]: CodewordType.ExtraDataInternational,
  [UrlHashes.dataInternationalSubscription]:
    CodewordType.ExtraDataInternational,
}

type Product =
  | ProductUnionType
  | (IOfferingProduct & { additionalSearchmatches?: string })
export const SearchAndProductList = <T extends Product>({
  onSelectProduct,
  searchMode = "filter",
  minSearchItems = 7,
  forceSearchTab = {
    [CodewordType[CodewordType.ExtraDataInternational]]: true,
    [CodewordType[CodewordType.ExtraDataEEA]]: false,
  },
  loading,
  countryList,
  products,
  includeBoostProducts,
  emptyDomesticMsg,
}: {
  /* With sort-mode, all items are visible, but the closes matching are at the top */
  searchMode?: "sort" | "filter"
  /* If there are less than this amount of items, no search-input will be visible */
  minSearchItems?: number
  /* Can be used to override the tabs that should show or hide the search-input */
  forceSearchTab?: Partial<Record<CodewordType, boolean>>
  onSelectProduct: (p: T) => any
  loading: boolean
  countryList: QueryResult<CountriesQuery>
  products: T[]
  exVatNotice?: string
  includeBoostProducts: boolean
  emptyDomesticMsg?: React.ReactNode
}) => {
  const { t } = useAppTranslations()

  const dialogues = useDialoges()
  const { hash } = useLocation()
  const searchRef = useRef<HTMLPuxInputElement>(null)
  const xs = useBreakpoint("xs", { useMax: true, useMin: false })
  const lg = useBreakpoint("lg")
  const [searchInput, setSearchInput] = React.useState("")
  const handleSearchInputChange = useDebouncedCallback((e: any) => {
    const value = e?.detail?.value
    if (value === searchInput) {
      return
    }
    setSearchInput(value)
  }, 150)

  const activeTab = urlHashMap[hash]
  const codeword = CodewordType[activeTab]
  const isSingleSubscription =
    hash === UrlHashes.dataInternationalSubscription ||
    hash === UrlHashes.dataSubscription
  const isMobile = useBreakpointBelowDesktop()
  const sortByNameFirst = CodewordType.ExtraDataInternational === activeTab

  useEffect(() => {
    if (isMobile) {
      return
    }
    if (searchRef.current) {
      searchRef.current.setFocus()
    }
  }, [activeTab, isMobile])

  function handleNav(tab: CodewordType) {
    if (tab === activeTab) {
      return
    }
    setSearchInput("")
    switch (tab) {
      case CodewordType.ExtraDataEEA:
        dialogues.data(
          "choose-product-tab",
          true
        )({ international: false, singleSubscription: isSingleSubscription })
        return
      case CodewordType.ExtraDataInternational:
        dialogues.data(
          "choose-product-tab",
          true
        )({ international: true, singleSubscription: isSingleSubscription })
        return
    }
    console.warn(`no match for tab '${tab}'`)
  }

  const searchInputLower = searchInput.toLowerCase()

  const shouldBeVisible = (
    p: ExtraDataPackageOfferingProduct,
    activeTab: 0 | 1
  ) => {
    // Abroad packages only visible in abroad tab
    if ((activeTab === 0 && p.type === 1) || (activeTab === 1 && p.type !== 1))
      return false

    // When using add data on customer level, boost products shall not be included.
    if (!includeBoostProducts && ![0, 1].some((i) => i === p.type)) return false

    return true
  }

  const tabProducts = (
    products.filter((p) => {
      if (p.__typename !== "ExtraDataPackageOfferingProduct") {
        return false
      }

      return shouldBeVisible(p as ExtraDataPackageOfferingProduct, activeTab)
    }) as ExtraDataPackageOfferingProduct[]
  ).map((a) => {
    // Consider using a fuzzy-search-library here, but for now the search is
    // not very important because of the small number of products
    const __normalized = [
      (a as any).additionalSearchmatches,
      a.name,
      a.description,
      ...(a.countries || []),
    ]
      .filter(Boolean)
      .join(" ")
      .toLowerCase()
    const matchIndex = __normalized.indexOf(searchInputLower)
    return {
      ...a,
      __normalized,
      __matches: matchIndex >= 0,
      __matchIndex: matchIndex,
    }
  })
  const searchEnabled =
    forceSearchTab[codeword] !== undefined
      ? forceSearchTab[codeword]
      : tabProducts.length >= minSearchItems

  const countryMatches =
    searchInput.length > 2 &&
    countryList.data?.countries
      ?.map((c) => {
        const lower = (c.name || "").toLowerCase()
        const matchIndex = lower.indexOf(searchInputLower)
        const match = matchIndex >= 0
        return {
          ...c,
          lower,
          match,
          matchIndex,
        }
      })
      .filter((c) => c.match)

  const bestCountryMatches =
    countryMatches &&
    countryMatches.sort((a, b) => {
      if (a.match && !b.match) {
        return -1
      }
      if (!a.match && b.match) {
        return 1
      }
      if (a.matchIndex < b.matchIndex) {
        return -1
      }
      if (a.matchIndex > b.matchIndex) {
        return 1
      }
      return 0
    })

  const bestCountryMatch = !!bestCountryMatches && bestCountryMatches[0]
  const countryRegion =
    bestCountryMatch &&
    products.find(
      (p) =>
        "countries" in p &&
        (p as ExtraDataPackageOfferingProduct).countries?.find((c) => {
          return bestCountryMatch.name === c
        })
    )

  const filteredProducts = (
    searchEnabled && searchMode === "filter"
      ? tabProducts.filter((p) => {
          if (searchMode !== "filter") {
            return true
          }
          if (!bestCountryMatch) {
            return true
          }

          return p.__normalized.includes(bestCountryMatch.lower)
        })
      : tabProducts
  ).sort((a, b) => {
    if ((a.__matches || b.__matches) && a.__matchIndex !== b.__matchIndex) {
      if (a.__matches && !b.__matches) {
        return -1
      }
      if (!a.__matches && b.__matches) {
        return 1
      }
      // both matches, prefer match at start
      /* if (a.__matchIndex < b.__matchIndex) {
        return -1
      }
      if (a.__matchIndex > b.__matchIndex) {
        return -1
      }*/
    }
    if (sortByNameFirst) {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
    }
    if (a.size > b.size) {
      return 1
    }
    if (a.size < b.size) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  })

  return (
    <PuxModalContent>
      <InlineError error={countryList.error} code="countryList" />
      <PuxSheet className={"container"} color="light">
        <small
          style={{
            display: "block",
            paddingBottom: 6,
            fontSize: "1.7rem",
            fontWeight: 500,
            paddingTop: 10,
            textAlign: "center",
            borderBottom: "0.5px solid rgb(211, 211, 211)",
            marginBottom: 20,
            maxWidth: 350,
            marginInline: "auto",
          }}
        >
          {t("ExtraData.ExVatNotice")}
        </small>
        <div
          className="tab-buttons"
          style={{
            ...(activeTab === CodewordType.ExtraDataEEA && {
              marginBottom: xs ? "3rem" : "6rem",
            }),
          }}
        >
          {[CodewordType.ExtraDataEEA, CodewordType.ExtraDataInternational].map(
            (k) => {
              const active = activeTab === k
              return (
                <PuxButton
                  key={k}
                  color="primary"
                  // size="small"
                  {...(lg && {
                    size: "large",
                  })}
                  {...(active && {
                    className: "arrow-bottom",
                  })}
                  {...(!active && {
                    color: "dark",
                  })}
                  onClick={() => handleNav(k)}
                >
                  {t("ExtraData.CodeWord", { context: CodewordType[k] })}
                </PuxButton>
              )
            }
          )}
        </div>
        {!loading && searchEnabled && (
          <div className="data-package-search">
            <h3>
              {" "}
              {/*{t("ExtraData.SearchText")
                .split("\n")
                .map((s) => (
                  <div key={s} style={{ fontWeight: 600 }}>
                    {s}
                  </div>
                ))}*/}
              {t("Services.SearchText", {
                postProcess: "markdownPostprocessor",
              })}{" "}
            </h3>
            <PuxItem className="search-item">
              <PuxInput
                ref={searchRef}
                inputmode="search"
                type="text"
                defaultValue={searchInput}
                placeholder={t("General.Search")}
                onPuxChange={handleSearchInputChange}
              />
              {!searchInput ? (
                <PuxIcon
                  slot="end"
                  icon={search}
                  style={{
                    color: "rgba(34, 36, 50, 0.8)",
                    fontSize: "3rem",
                    marginBottom: "16px",
                  }}
                />
              ) : (
                <PuxButton
                  fill="clear"
                  size={"large"}
                  slot={"end"}
                  onClick={() => {
                    setSearchInput("")
                    if (searchRef?.current) {
                      searchRef.current.value = ""
                    }
                  }}
                >
                  <PuxIcon
                    slot="icon-only"
                    icon={closeSmall}
                    style={{
                      color: "rgba(34, 36, 50, 0.8)",
                      fontSize: "3rem",
                      marginBottom: "16px",
                    }}
                  />
                </PuxButton>
              )}
            </PuxItem>
            {searchInput?.length > 3 && !bestCountryMatch && (
              <>
                <PuxTypography variant={"info-small"}>
                  <PuxText color={"danger"}>Ingen treff</PuxText>
                </PuxTypography>
              </>
            )}
            {!!bestCountryMatch ? (
              !!bestCountryMatch.inEEA ? (
                <p>
                  <strong>
                    {t("Services.CountryPartOfCurrentSubscriptionRegion", {
                      countryName: bestCountryMatch.name,
                      regionName: "EU/EØS",
                    })}
                  </strong>
                </p>
              ) : (
                !filteredProducts.length && (
                  <p>
                    <strong>
                      <PuxText color="danger">
                        {t("Services.MissingCountryPackage", {
                          countryName: bestCountryMatch.name,
                        })}
                      </PuxText>
                    </strong>
                  </p>
                )
              )
            ) : null}
            {!!bestCountryMatch && !!countryRegion && (
              <>
                {bestCountryMatch.inEEA === false ? (
                  <>
                    {bestCountryMatch.continent === "Europa" ? (
                      <p>
                        <strong>
                          <PuxText color="danger">
                            {t("Services.CountryNotEEA", {
                              countryName: bestCountryMatch.name,
                            })}
                          </PuxText>
                        </strong>
                      </p>
                    ) : (
                      ""
                    )}
                    <p>
                      <PuxText style={{ fontSize: "15px" }}>
                        {t("Services.CountryPartOfRegion", {
                          countryName: bestCountryMatch.name,
                          // This is a bit naive
                          regionName: countryRegion.name,
                        })}
                      </PuxText>
                    </p>
                  </>
                ) : (
                  ""
                )}
                <p>
                  <small>
                    {t(bestCountryMatch.inEEA ? "Services.NeedMoreData" : "")}
                  </small>
                </p>
              </>
            )}
          </div>
        )}
        {(!bestCountryMatch ||
          (!bestCountryMatch.inEEA && !!filteredProducts.length)) && (
          <>
            {!loading && searchEnabled && (
              <PuxTypography variant={"h5"} style={{ marginInline: "auto" }}>
                Våre utenlandspakker
              </PuxTypography>
            )}
            <ProductList
              key={"products" + activeTab}
              products={filteredProducts}
              placeholders={3}
              Component={ExtraDataPackage}
              onSelectProduct={onSelectProduct as any}
              animated={loading}
              {...{
                [CodewordType.ExtraDataEEA]: {
                  emptyMsg: emptyDomesticMsg,
                },
              }[activeTab]}
              {...{
                [CodewordType.ExtraDataInternational]: {
                  placeholders: 17,
                  Component: ExtraDataPackage,
                },
              }[codeword]}
            />
          </>
        )}
      </PuxSheet>
    </PuxModalContent>
  )
}
