/* 
  This class is used for operations on DataPackagSize.
  Intension is write once and reuse these operations  
 */

import { ByteUsage } from "@phonero/common-graphql/types"

/* converts from size and unit to bytes */
export function convertToBytes(
  size: number,
  sizeUnit: keyof typeof sizeMultiplier
) {
  const unit = sizeUnit as keyof typeof sizeMultiplier

  if (!unit) {
    console.error("Unhandled sizeUnit: ", { sizeUnit, unit })
    return 0
  }

  const multiplier = sizeMultiplier[unit]
  return size * multiplier
}

export function convertToMegaBytes(
  size: number,
  sizeUnit: keyof typeof sizeMultiplier
) {
  return convertToBytes(size, sizeUnit) / sizeMultiplier["MB"]
}

/* converts from bytes to size and unit */
export function convertToSizeAndUnit(bytes: number) {
  if (bytes === 0) return { size: 0, sizeUnit: "MB" }

  // start from top and find first unit where size is greater or equal to one
  // definition: divisor / dividend = quotient
  for (let index = sortedSizeKeys.length - 1; index > 0; index--) {
    const sizeUnit = sortedSizeKeys[index]
    const dividend = sizeMultiplier[sizeUnit]

    const quotient = bytes / dividend
    if (quotient >= 1) return { size: quotient, sizeUnit }
  }

  return { size: 0, sizeUnit: "MB" }
}

/* normalize size and unit to 3 digits */
export function normalizeSizeAndUnit(size: number, sizeUnit: string) {
  return convertToSizeAndUnit(convertToBytes(size, sizeUnit as any))
}

/**  packages that are "empty" have 1mb returned from the API
 *
 * This is a hack as the API should really return this a 0
 * */
export const isAirQuetesEmptySize = (
  size: number,
  sizeUnit: keyof typeof sizeMultiplier
) => {
  // packages that are "empty" have 1mb, and we cannot view a bar then
  const bytes = convertToBytes(size, sizeUnit)
  return bytes <= 1048576
}

export const sizeMultiplier = {
  B: 1,
  KB: 1024,
  MB: 1024 * 1024,
  // TODO: These values depend on what PCRF backend is used
  // Should get these from the API and not hardcode them here
  // On SAPC we use 1000MB = 1GB, while new PCRF (Oracle) will have 1024MB = 1GB
  GB: 1024 * 1024 * 1000,
  TB: 1024 * 1024 * 1000 * 1000,
  PB: 1024 * 1024 * 1000 * 1000 * 1000,
}

const sortedSizeKeys: Array<keyof typeof sizeMultiplier> = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
]

const bytesToSize = (bytes: number, size: keyof typeof sizeMultiplier) => {
  const result = bytes / sizeMultiplier[size]
  return result
}

export const byteSize = (bytes: number) => {
  return {
    get bytes() {
      return bytes
    },
    get kilobytes() {
      return bytesToSize(bytes, "KB")
    },
    get megabytes() {
      return bytesToSize(bytes, "MB")
    },
    get gigabytes() {
      return bytesToSize(bytes, "GB")
    },
    get terrabytes() {
      return bytesToSize(bytes, "TB")
    },
    get PB() {
      return bytesToSize(bytes, "PB")
    },
  }
}

const getBiggestPackageBySize = (
  list: { productSize: number; productSizeType: string }[]
) => {
  let biggestInBytes = 0
  let smallestInBytes = Infinity
  let biggestMultiplier: keyof typeof sizeMultiplier = "B"
  let smallestMultiplier: keyof typeof sizeMultiplier = "B"
  for (const { productSize, productSizeType } of list) {
    const multiplier = sizeMultiplier[productSizeType]
    if (!multiplier) {
      return { biggestMultiplier: "B" as const, biggestInBytes: 0 }
    }
    const bytes = productSize * multiplier
    if (biggestInBytes < bytes) {
      biggestInBytes = bytes
      biggestMultiplier = productSizeType as any
    }
    if (smallestInBytes > bytes) {
      smallestInBytes = bytes
      smallestMultiplier = productSizeType as any
    }
  }
  return {
    biggestInBytes,
    biggestMultiplier,
    smallestMultiplier,
    smallestInBytes,
  }
}

export const uniformByteUsage = (
  usageData: ByteUsage,
  forcedSize?: keyof typeof sizeMultiplier
) => {
  if (
    forcedSize === "TB" ||
    usageData.sizeGigaBytes > 1000 ||
    usageData.usedGigaBytes > 1000
  ) {
    return {
      list: [
        (usageData.usedGigaBytes + usageData.reservedGigaBytes) / 1000,
        usageData.sizeGigaBytes / 1000,
      ],
      wantedSize: "TB",
    }
  }
  if (
    forcedSize === "GB" ||
    usageData.sizeGigaBytes >= 1 ||
    usageData.usedGigaBytes >= 1
  )
    return {
      list: [
        usageData.usedGigaBytes + usageData.reservedGigaBytes,
        usageData.sizeGigaBytes,
      ],
      wantedSize: "GB",
    }
  return {
    list: [
      usageData.usedMegaBytes + usageData.reservedMegaBytes,
      usageData.sizeMegaBytes,
    ],
    wantedSize: "MB",
  }
}

// For a list of dataSizes in bytes(or use fromSizeType to convert all), it finds the largest and converts all of them to the best fitting size
// and outputs that sizetype
export const uniformSizes = (
  listOfDataSizes: number[],
  fromSizeType?: keyof typeof sizeMultiplier,
  forcedSize?: keyof typeof sizeMultiplier
) => {
  const listInBytes = fromSizeType
    ? listOfDataSizes.map((d) => convertToBytes(d, fromSizeType))
    : listOfDataSizes
  const biggest = Math.max(...listInBytes)
  let wantedSize = forcedSize
  if (!wantedSize) {
    wantedSize = bestFittingSize(biggest).size
    const wantedSizeMaxindex = sortedSizeKeys.findIndex(
      (d) => d === wantedSize
    )!
    const highestIndex = sortedSizeKeys.findIndex((d) => d === highest)!
    if (wantedSizeMaxindex > highestIndex) {
      wantedSize = highest
    }
  }

  return {
    list: listInBytes.map((b) => bytesToSize(b, wantedSize!)),
    wantedSize,
  }
}

/**
 For use with package-sizes where one wants to display items with varying sizes as the same size.

 This uses SI binary sizes, like MegaBytes (1e6), not MebiBytes (2^20) or any other special phonero-way of calculating these sizes

*/
export const uniformProductSizes = <
  T extends { productSize: number; productSizeType: string }
>(
  list: T[],
  wantedSize?: keyof typeof sizeMultiplier
) => {
  if (!list) {
    return list
  }
  if (!wantedSize) {
    const { biggestInBytes } = getBiggestPackageBySize(list)
    if (!biggestInBytes) {
      return list
    }
    wantedSize = bestFittingSize(biggestInBytes).size
  }
  const multiplier = sizeMultiplier[wantedSize]
  if (!multiplier) {
    return null
  }

  const newList: T[] = new Array(list.length)
  for (let index = 0; index < list.length; index++) {
    const item = list[index]
    const divider = sizeMultiplier[item.productSizeType]
    if (!divider) {
      console.error("unhandled sizeMultiplier for productSizeType: ", item)
      return null
    }
    const size = (item.productSize * divider) / multiplier
    newList[index] = {
      ...item,
      productSize: size,
      productSizeType: wantedSize,
    }
  }
  return newList
}

const bestFittingSize = (bytes: number, offset = 1) => {
  // skip over B and KB +1, then backtrack
  for (let index = 3; index < sortedSizeKeys.length; index++) {
    const size = sortedSizeKeys[index]
    const multiplier = sizeMultiplier[size]
    if (bytes < multiplier * offset) {
      const size = sortedSizeKeys[index - 1]
      if (size === highest) {
        break
      }

      return { size, multiplier: sizeMultiplier[size] }
    }
  }
  return { size: highest, multiplier: sizeMultiplier[highest] }
}

/** Higbest size allowed. */
const highest: keyof typeof sizeMultiplier = "GB"
