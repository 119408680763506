import { useReactiveVar } from "@apollo/client"
import { appVars, appStorage, useCurrentSubscriptionId, ReceiptFragment, OrdersQuery } from "@phonero/common-ux"
import React from "react"

export type Order = NonNullable<
  NonNullable<NonNullable<OrdersQuery["mobileSubscription"]>["orders"]>["nodes"]
>[0]

appStorage.get({ key: "last-read-receipts" }).then((val) => {
  if (!val || !val.value) {
    return
  }
  try {
    const raw = JSON.parse(val.value)
    const value =
      !!raw &&
      Object.keys(raw).reduce((r, key) => {
        const date = new Date(raw[key])
        if (isNaN(date.getTime())) {
          return r
        }
        return { ...r, [key]: date }
      }, {} as Record<string, Date>)

    if (value) {
      appVars.lastReadReceipts(value)
    }
  } catch (error) {
    console.warn("failed to json-parse last-read-receipts from appStorage")
  }
})
/** Since this is a new feature, we add a default lastReadtime so that existing users
 * with lots of orders, do not "notified" of old orders as read.
 * This date should perhaps be the install-date for the application.
 */
const defaultLastReadReceiptsTime = new Date(2021, 8, 17)
export function useOrdersLastReadReceipts() {
  const lastReadMap = useReactiveVar(appVars.lastReadReceipts)
  const { subscriptionId } = useCurrentSubscriptionId()
  const key = !!subscriptionId ? "order_" + subscriptionId : null
  const lastReadReceipts =
    (!!key && lastReadMap[key]) || defaultLastReadReceiptsTime

  const setLastReadReceipts = React.useCallback(
    (dateOrOrder: Date | NonNullable<ReceiptFragment>) => {
      if (!key || !dateOrOrder) {
        return
      }
      const now =
        "purchased" in dateOrOrder
          ? dateOrOrder.purchased
          : (dateOrOrder as Date)
      if (!now) {
        return
      }
      const val = { ...appVars.lastReadReceipts(), [key]: new Date(now) }
      appVars.lastReadReceipts(val)
      appStorage.set({
        key: "last-read-receipts",
        value: JSON.stringify(val),
      })
    },
    [key]
  )

  const isUnread = React.useCallback(
    (order: ReceiptFragment) => receiptIsUnread(lastReadReceipts, order),
    [lastReadReceipts]
  )

  return { lastReadReceipts, setLastReadReceipts, isUnread } as const
}

export function receiptIsUnread(
  lastRead: Date | null,
  receipt: ReceiptFragment
) {
  if (!lastRead) {
    return true
  }
  if (!receipt?.purchased) {
    return false
  }
  const lastUpdate = new Date(receipt?.purchased)
  return (
    lastUpdate &&
    lastUpdate.getTime &&
    lastUpdate.getTime() > lastRead.getTime()
  )
}
