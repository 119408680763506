import { useTokenData } from "./useTokenData"

type SubscriptionPayload<Params> = {
  /** The token used, if any */
  token: ReturnType<typeof useTokenData>
  /** The subscriptionID the user has chosen */
  subscriptionId: string
  /** The subscriptionID extracted from the token */
  tokenSubscriptionId: string
} & Params

/** Returns the currently chosen subscriptionID */
export function useCurrentSubscriptionId<
  // Placeholder for later use
  Params
>(): SubscriptionPayload<Params> {
  const token = useTokenData()
  const tokenSubscriptionId = token.subscriptionId 
  const subscriptionId = token.subscriptionId  
  return {
    token,
    subscriptionId,
    tokenSubscriptionId,
  } as any
}
