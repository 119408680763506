import * as React from "react"
import { AnyFunc, ArgsAsTuple, Required } from "simplytyped"
import { PuxButton } from "@phonero/pux-react"
import { RouteKeys, routes } from "../routes"
import {
  AppRoute,
  dataCy,
  ReactComponentArgs,
  UrlHashes,
  useKeycloak,
} from "@phonero/common-ux"

export type InternalLink = {
  internalRoute: RouteKeys
  tKey?: string
}
export type IdpLink = {
  idp: "account"
  tKey: string
}
export type Dialog = {
  dialog: UrlHashes
  tKey: string
}
export type ExternalLink = {
  tKey: string
} & Required<ArgsAsTuple<typeof PuxButton>[0], "href">

export type AppLink = (InternalLink | ExternalLink | Dialog | IdpLink) & {
  order?: number
  isNew?: boolean
}

function AccountLink({ children, ...props }: { children?: React.ReactNode }) {
  const k = useKeycloak()

  return (
    <PuxButton
      fill="clear"
      href={k.keycloak.createAccountUrl()}
      color="dark"
      {...props}
    >
      {children}
    </PuxButton>
  )
}

interface HistoryPusher {
  push: (path: any, state?: any) => void
}

export function AppLinker({
  link: l,
  goTo,
  history,
  t,
  internalMapper,
  externalMapper,
  dialogMapper,
  idpMapper,
}: {
  link: AppLink
  goTo: Record<RouteKeys, AnyFunc>
  history: HistoryPusher
  t: AnyFunc
  internalMapper?: (
    link: InternalLink & AppLink,
    route: AppRoute
  ) => React.ReactNode
  dialogMapper?: (link: Dialog & AppLink) => React.ReactNode
  externalMapper?: (link: ExternalLink & AppLink) => any
  idpMapper?: (link: IdpLink & AppLink) => any
}) {
  if ("idp" in l) {
    if (idpMapper) {
      return idpMapper(l)
    }
    return <AccountLink {...dataCy("link-account")}>{t(l.tKey)}</AccountLink>
  }
  if ("internalRoute" in l) {
    const route = routes[l.internalRoute]
    if (internalMapper) {
      return internalMapper(l, route)
    }
    return (
      <PuxButton
        fill="clear"
        color="dark"
        {...dataCy("link-int-" + l.internalRoute)}
        onClick={() => goTo[l.internalRoute]()}
      >
        {t(l.tKey || route.name)}
      </PuxButton>
    )
  }
  if ("dialog" in l) {
    if (dialogMapper) {
      return dialogMapper(l)
    }
    return (
      <PuxButton
        fill="clear"
        color="dark"
        {...dataCy("link-dialog-" + l.dialog)}
        onClick={() => history.push(l.dialog)}
      >
        {t(l.tKey)}
      </PuxButton>
    )
  }
  const { tKey: _, ...rest } = l
  if (externalMapper) {
    return externalMapper(l)
  }
  return (
    <PuxButton
      fill="clear"
      color="dark"
      {...dataCy("link-" + l.tKey)}
      {...rest}
    >
      {t(l.tKey)}
    </PuxButton>
  )
}

export function mapLink(
  props: Omit<ReactComponentArgs<typeof AppLinker>, "link">
) {
  return (link: AppLink) => (
    <AppLinker
      key={
        link.tKey +
        ("href" in link
          ? link.href
          : "dialog" in link
          ? link.dialog
          : "internalRoute" in link
          ? link.internalRoute
          : link.idp)
      }
      link={link}
      {...props}
    />
  )
}
