import MarkdownIt from "markdown-it"
import parse, { HTMLReactParserOptions } from "html-react-parser"
import DOMPurify from "dompurify"

let md = MarkdownIt()

export const markdownPostprocessor = "markdownPostprocessor"

export const i18nMarkdownProcessor = (
  mdInstance = md,
  options?: HTMLReactParserOptions
) => {
  return {
    name: markdownPostprocessor,
    type: "postProcessor" as const,

    process(value: any, _key: any, moreOptions: any) {
      return safeMarkdown(value, mdInstance, options, moreOptions)
    },
  }
}

/* Used to override the global default options for markdown.

Note that it is important to keep XSS in mind
*/
export const configureMarkdown = (options: MarkdownIt.Options) => {
  md = MarkdownIt(options)
}

/* Renders a html-string safely from markdown. See options for replacement-functionality */
export function safeMarkdown(
  markdown: string,
  mdInstance: typeof md = md,
  options?: HTMLReactParserOptions,
  moreOptions?: { mdInline?: boolean; skipMarkdown?: boolean }
) {
  const { mdInline = true, skipMarkdown } = moreOptions || {}
  if (skipMarkdown) {
    // override for skipping rendering markdown
    return markdown
  }

  // const renderer = md.render  //inline ? mdInstance.renderInline : mdInstance.render
  const mark = mdInline
    ? mdInstance.renderInline(markdown ?? "")
    : mdInstance.render(markdown ?? "")
  const html = safeHtml(mark, options)
  return html
}

export function safeMarkdownBlock(markdown: string) {
  return safeMarkdown(markdown, undefined, undefined, { mdInline: false })
}
/* Renders a html-string safely. See options for replacement-functionality */
export function safeHtml(html: string, options?: HTMLReactParserOptions) {
  return parse(DOMPurify.sanitize(html), options)
}
