import { HTMLAttributes } from "react"
import cx from "classnames"
import css from "./Badge.module.scss"

export interface IBadgeProps extends HTMLAttributes<HTMLDivElement> {}

export const Badge = ({
  children,
  className,
  ...props
}: JSX.IntrinsicElements["span"]) => {
  return (
    <span className={cx(css.badge, className)} {...props}>
      <span>{children}</span>
    </span>
  )
}
