import { Capacitor } from "@capacitor/core"
import { isPlatform, MediaQueryName } from "@phonero/pux-core"
import { useBreakpoint } from "@phonero/pux-react"

export const isHybrid = isPlatform("hybrid")
export const isCapacitor = isPlatform("capacitor")
export const platform: "ios" | "android" | "web" =
  Capacitor.getPlatform() as any

export const useBreakpointBelow = (q: MediaQueryName) => {
  return useBreakpoint(q, { useMin: false, useMax: true })
}

export const useBreakpointDesktop = () =>
  useBreakpoint("lg", { useMin: true, useMax: false })
export const useBreakpointBelowDesktop = () => useBreakpointBelow("lg")
export const useBreakpointBelowTablet = () => useBreakpointBelow("md")
