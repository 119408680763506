import { useEffect, useState } from "react"
import {
  useAppQuery,
  graphqlToast,
  useAppTranslations,
} from "@phonero/common-ux"
import { useHasCostTransferAccess } from "@phonero/common-ux"
import {
  SubscriptionProfileDocument,
  SubscriptionProfileQuery,
} from "../../pages/usage/components/SubscriptionProfile.generated"
import { featureFlags } from "../../appConstants"
import {
  CostSharingExtraDataCoverage,
  CostSharingTrafficFreeAmountCoverage,
} from "@phonero/common-graphql/types"

export enum paymentVariation {
  /** Variant 0: Either unknown, or not set. Check the load-state or error */
  VariantUnknown,
  /** Variant 1: Uten Kostnadsdeling eller Bedriften dekker alt */
  Variant1BelasteJobb,
  /** Variant 2: Kun mulig å betale med Vipps */
  Variant2KunPrivatBetalingSomFeksVipps,
  /** Variant 3: Om innrapportering er slått på, og valgt ansatt må dekke selv/maksbeløp/fribeløp */
  Variant3KjøpMedKostnadsDeling,
  /** Variant 4: Om innrapportering er slått av, og valgt ansatt må dekke selv */
  Variant4KjøpMedLønnstrekk,
  /** Variant 5: Om innrapportering er slått av, og satt maksbeløp/fribeløp */
  Variant5KjøpMedKostnadsDeling,
  /** The legacy variation. Should be set if featureflag is not set. */
  VariantLegacy,
}

export type calculateVariationProps = {
  kind: "abroad" | "domestic"
  data: SubscriptionProfileQuery | undefined
  privateOnly: boolean
  hasCostTransferAccess: boolean | "loading" | Error | undefined
  legacy?: boolean
}

/** returns a paymentvariation according to https://phoneroas.atlassian.net/browse/DBW-1582
 *
 * @note I believe there may be somme conditions when this does not return correctly.
 * Please see the accompanying tests (./calculatePaymentVariation.spec.ts)[./calculatePaymentVariation.spec.ts]
 * */
const calculateVariation = ({
  kind,
  data,
  privateOnly,
  hasCostTransferAccess,
  legacy = !featureFlags.showCostProfileMesssageInBuyDialog,
}: calculateVariationProps) => {
  if (privateOnly) {
    return paymentVariation.Variant2KunPrivatBetalingSomFeksVipps
  }
  if (legacy) {
    return paymentVariation.VariantLegacy
  }
  if (!data) {
    return paymentVariation.VariantUnknown
  }
  if (typeof hasCostTransferAccess !== "boolean") {
    return paymentVariation.VariantUnknown
  }
  if (!data.subscriptionProfile) {
    return paymentVariation.Variant1BelasteJobb
  }
  const covers =
    (kind === "abroad" && data.subscriptionProfile.extraDataInternational) ||
    (kind === "domestic" && data.subscriptionProfile.extraDataDomestic)
  let companyCovers = false

  if (covers && covers.isEnabled) {
    switch (covers.coverage) {
      case CostSharingExtraDataCoverage.EmployeePaysPrivately:
        return paymentVariation.Variant2KunPrivatBetalingSomFeksVipps
      case CostSharingExtraDataCoverage.CompanyCoversAll:
        companyCovers = true
        break
      case CostSharingExtraDataCoverage.CompanyCoversX:
        // TODO: Should we check if the limit has been reached?
        companyCovers = !!covers.limit
        break
    }
  }
  if (companyCovers) {
    return paymentVariation.Variant1BelasteJobb
  }
  // Variant 1,2 should now be covered

  const freeAmount = data.subscriptionProfile?.trafficRules?.freeAmount
  const hasMaxOrFree =
    !!freeAmount &&
    ((freeAmount.coverage ===
      CostSharingTrafficFreeAmountCoverage.CompanyCoversX &&
      freeAmount.limit &&
      freeAmount.limit > 0) ||
      freeAmount.coverage ===
        CostSharingTrafficFreeAmountCoverage.CompanyCoversAll)

  if (hasCostTransferAccess) {
    // Variant 3: Om innrapportering er slått på, og valgt ansatt må dekke selv/maksbeløp/fribeløp
    if (hasMaxOrFree && !companyCovers) {
      return paymentVariation.Variant3KjøpMedKostnadsDeling
    }
  } else {
    if (hasMaxOrFree) {
      return paymentVariation.Variant5KjøpMedKostnadsDeling
    }
    if (!companyCovers) {
      return paymentVariation.Variant4KjøpMedLønnstrekk
    }
  }
  return paymentVariation.VariantUnknown
}

/** returns a paymentvariation according to https://phoneroas.atlassian.net/browse/DBW-1582 */
export const usePaymentVariation = ({
  kind,
  privateOnly,
}: {
  privateOnly: boolean
  kind: "abroad" | "domestic"
}) => {
  const { t } = useAppTranslations()
  const [variation, setVariation] = useState(
    privateOnly
      ? paymentVariation.Variant2KunPrivatBetalingSomFeksVipps
      : paymentVariation.VariantUnknown
  )

  const hasCostTransferAccess = useHasCostTransferAccess()
  // nosemgrep: semgrep.gql-missing-error-check-warning
  const result = useAppQuery(SubscriptionProfileDocument, {
    skip: privateOnly,
    onError: graphqlToast(t, "SubscriptionProfile", {}).onError,
  })

  useEffect(() => {
    setVariation(
      calculateVariation({
        kind,
        data: result.data,
        privateOnly,
        hasCostTransferAccess,
      })
    )
  }, [kind, result.data, privateOnly, hasCostTransferAccess])

  return {
    ...result,
    hasCostTransferAccess,
    variation,
  }
}

export default calculateVariation
