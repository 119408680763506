/** Used to add datacy to elements for e2e-testing
 *
 * TODO: Strip this function when building
 */
export function dataCy(s: string, others: {} = {}) {
  return {
    "data-cy": s,
    "data-testid": s,
    ...Object.keys(others).reduce((r, k) => {
      return {
        ...r,
        ["data-" + k]: String(others[k]),
      }
    }, {}),
  }
}