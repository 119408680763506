import { useHistory } from "react-router-dom"
import useAnalytics from "./useAnalytics"

export enum UrlHashes {
  dataInternational = "#data-int",
  dataInternationalSubscription = "#data-int-sub",
  data = "#data",
  dataSubscription = "#data-sub",
  change = "#change",
  edit = "#endre",
  move = "#flytt",
  moveSubscriptionCancellation = "#move-cancellation",
  innrapportering = "innrapportering",
  boost = "#boost",
  devView = "#dev-view",
  releaseSubscription = "#release",
  releaseSubscriptionCancellation = "#release-cancellation",
  sumAmountLimit = "#sum-limit",
  sendReceipt = "#send-receipt",
  terminateSubscription = "#terminateSubscription",
  terminateSubscriptionCancellation = "#terminateSubscriptionCancellation",
}

export const useDialoges = () => {
  const history = useHistory()
  const analytics = useAnalytics()

  const push =
    (
      buttonName: string,
      href?: string,
      replace?: boolean,
      keepSearchParams?: boolean
    ) =>
    (path?: string, state?: any) => {
      if (!href && !path) {
        return
      }
      let p = path || href || ""
      if (p.startsWith("#")) {
        p = window.location.pathname + p
        if (keepSearchParams === undefined && !p.includes("?")) {
          keepSearchParams = true
        }
      }
      if (keepSearchParams) {
        p = p.replace("#", window.location.search + "#")
      }
      analytics.logNavigation({ buttonName, href: p })
      if (replace) {
        history.replace(p, state)
      } else {
        history.push(p, state)
      }
    }

  return {
    data:
      (analyticsButtonName: string, replace?: boolean) =>
      ({
        international,
        singleSubscription,
        productId,
      }: {
        international?: boolean
        singleSubscription?: boolean
        productId?: string
      } = {}) => {
        const hash = international
          ? singleSubscription
            ? UrlHashes.dataInternationalSubscription
            : UrlHashes.dataInternational
          : singleSubscription
          ? UrlHashes.dataSubscription
          : UrlHashes.data

        const q = productId ? `?pid=productId` : ""
        push(analyticsButtonName, "<productId>" + hash, replace, true)(q + hash)
      },
    edit: (buttonName: string) => push(buttonName)(UrlHashes.edit),
    move: (buttonName: string) => push(buttonName)(UrlHashes.move),
    sendReceipt: (buttonName: string) =>
      push(buttonName)(UrlHashes.sendReceipt),
    change: (buttonName: string) => push(buttonName)(UrlHashes.change),
    devView: (buttonName: string) => push(buttonName)(UrlHashes.devView),
    boost: (buttonName: string) => push(buttonName)(UrlHashes.boost),
    costReporting: (buttonName: string) =>
      push(buttonName)(UrlHashes.innrapportering),
    releaseSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.releaseSubscription),
    cancelReleaseSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.releaseSubscriptionCancellation),
    terminateSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.terminateSubscription),
    cancelTerminateSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.terminateSubscriptionCancellation),
    cancelMoveSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.moveSubscriptionCancellation),
    costAmountLimit: (buttonName: string) =>
      push(buttonName)(UrlHashes.sumAmountLimit),
  }
}
