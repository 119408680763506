import * as Types from '@phonero/common-graphql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddPhoneroCloudMutationVariables = Types.Exact<{
  input: Types.AddPhoneroCloudInput;
}>;


export type AddPhoneroCloudMutation = { __typename: 'Mutation', addPhoneroCloud?: { __typename: 'OrderConfirmation', orderId: any } | null };

export type ResetPollIntervalOnPhoneroCloudMutationVariables = Types.Exact<{
  phoneroCloudId: Types.Scalars['Int']['input'];
}>;


export type ResetPollIntervalOnPhoneroCloudMutation = { __typename: 'Mutation', resetPollIntervalOnPhoneroCloud?: string | null };


export const AddPhoneroCloudDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddPhoneroCloud"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddPhoneroCloudInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addPhoneroCloud"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"orderId"}}]}}]}}]} as unknown as DocumentNode<AddPhoneroCloudMutation, AddPhoneroCloudMutationVariables>;
export const ResetPollIntervalOnPhoneroCloudDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPollIntervalOnPhoneroCloud"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phoneroCloudId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPollIntervalOnPhoneroCloud"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contractProductId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phoneroCloudId"}}}]}]}}]} as unknown as DocumentNode<ResetPollIntervalOnPhoneroCloudMutation, ResetPollIntervalOnPhoneroCloudMutationVariables>;