import {
  PuxItem,
  PuxSheet,
  PuxText,
  PuxTypography,
  useBreakpoint,
} from "@phonero/pux-react"
import classNames from "classnames"
import * as React from "react"
import css from "./CheckoutProductHeader.module.scss"

import { PredefinedColors } from "@phonero/pux-core"
import { DataBoostTheme, DataPackageTheme } from "../Themes/themes"
import { useAppTranslations } from "../util"
import { addVat, removeVat } from "../util/vat"

const num = (raw: any) => {
  const n = Number(raw)
  if (isNaN(n)) {
    return null
  }
  return n
}

const nullable = (...args: Array<any>) => {
  for (const n of args) {
    if (typeof n !== "number") {
      continue
    }
    if (isNaN(n)) {
      continue
    }
    return n
  }
  return null
}

function getPrice({
  exVat: _exVat,
  incVat: _incVat,
  offsetExVat,
  offsetIncVat,
}: {
  exVat?: number | null
  incVat?: number | null
  offsetExVat?: number | null
  offsetIncVat?: number | null
}) {
  const exVat = num(_exVat)
  const incVat = num(_incVat)
  let priceExVat = nullable(exVat, incVat !== null && removeVat(incVat))
  let priceIncVat = nullable(incVat, exVat !== null && addVat(exVat))
  if (priceExVat === null && priceIncVat === null) {
    return null
  }
  const offset = getPrice({ exVat: offsetExVat, incVat: offsetIncVat })
  if (offset?.exVat && priceExVat !== null) {
    priceExVat += offset.exVat
  }
  if (offset?.incVat && priceIncVat !== null) {
    priceIncVat += offset.incVat
  }
  return {
    exVat: priceExVat,
    incVat: priceIncVat,
  }
}

function CheckoutPrice({
  exVat,
  incVat,
  showExVat,
  showIncVat,
  showVatLabel,
  colorFree,
  isPriceFrom,
}: {
  exVat?: number | null
  incVat?: number | null
  showExVat?: boolean
  showIncVat?: boolean
  showVatLabel?: boolean
  colorFree?: PredefinedColors
  isPriceFrom?: boolean
}) {
  if (
    showVatLabel === undefined &&
    showExVat === undefined &&
    showIncVat === undefined
  ) {
    showVatLabel = true
    showExVat = true
    showIncVat = false
  }
  const { t, i18n } = useAppTranslations()
  const price = getPrice({
    exVat,
    incVat,
  })
  if (!price) {
    return null
  }

  const isZero = !exVat

  return (
    <div className="price">
      {isZero && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <PuxText
              color={colorFree ? colorFree : undefined}
              style={{ fontWeight: 600, fontSize: "1.9rem", paddingBottom: 10 }}
            >
              Gratis
            </PuxText>
            <PuxTypography variant={"info-small"} component={"div"}>
              * Kan kun benyttes en gang
            </PuxTypography>
          </div>
        </>
      )}
      {!isZero && showIncVat && price.incVat !== null && (
        <div
          className="inc-vat"
          style={{
            fontWeight: 500,
          }}
        >
          {isPriceFrom ? <>Fra </> : ""} {i18n.format(price.incVat, "price")}{" "}
          <div
            style={{
              display: "inline-block",
              fontSize: "1.5rem",
            }}
          >
            {showVatLabel && t("General.IncVat")}
          </div>
        </div>
      )}
      {!isZero && showExVat && price.exVat !== null && (
        <div className={"ex-vat"}>
          <strong>{i18n.format(price.exVat, "price")}</strong>{" "}
          <div
            style={{
              fontWeight: 500,
              display: "inline-block",
              fontSize: "1.5rem",
            }}
          >
            {showVatLabel && t("General.ExVat")}
          </div>
        </div>
      )}
    </div>
  )
}

export function CheckoutProductHeader({
  title,
  descriptions: _desc,
  size,
  sizeType = "GB",
  showExVat,
  variant,
  showVatLabel,
  showIncVat,
  dataTheme,
  boostTheme,
  ...rest
}: {
  size?: number
  sizeType?: string | null
  title: React.ReactNode
  variant?: "boxed"
  priceExVat?: number
  priceIncVat?: number
  isPriceFrom?: boolean
  priceOffsetExVat?: number
  priceOffsetIncVat?: number
  totalPriceExVat?: number
  totalPriceIncVat?: number
  /**
   * Supply an array of tuples [string, number] to have a line with description and a cost
   */
  descriptions?:
    | React.ReactNode
    | Array<React.ReactNode | [React.ReactNode, number]>
  showExVat?: boolean
  showIncVat?: boolean
  showVatLabel?: boolean
  dataTheme?: DataPackageTheme
  boostTheme?: DataBoostTheme
}) {
  if (
    showVatLabel === undefined &&
    showExVat === undefined &&
    showIncVat === undefined
  ) {
    showVatLabel = true
    showExVat = true
    showIncVat = true
  }
  const { t, i18n } = useAppTranslations()
  const xs = useBreakpoint("xs", { useMax: true })
  const sm = !useBreakpoint("sm")
  const variantBoxed = variant === "boxed"
  const total = getPrice({
    exVat: rest.totalPriceExVat,
    incVat: rest.totalPriceIncVat,
    offsetExVat: rest.priceOffsetExVat,
    offsetIncVat: rest.priceOffsetIncVat,
  })
  const descriptions = (Array.isArray(_desc) ? _desc : [_desc]).filter(Boolean)
  const dataIcon = dataTheme?.iconCheckoutPage ?? dataTheme?.icon

  return (
    <PuxSheet noPadding={sm} style={{ "--padding": "0" }}>
      <PuxItem
        color="dark"
        style={{
          "--padding-end": 0,
          "--padding-top": 0,
          ...(sm && { "--border-radius": 0 }),
        }}
      >
        {!!boostTheme?.band && (
          <img
            src={boostTheme?.band}
            height={xs ? "100" : "120px"}
            style={{ top: "0", right: "0", position: "absolute", zIndex: 200 }}
            alt={boostTheme?.bandImgAlt}
          />
        )}
        <div className={classNames(css.infoWrapper)}>
          <div
            className={classNames(
              "grid-data-row",
              variantBoxed && "variant-boxed",
              !!boostTheme?.band && css.rightSideTopImage
            )}
          >
            {!dataIcon && (
              <>
                <div
                  className="title"
                  style={{
                    minWidth: "150px",
                    paddingRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {size && (
                    <>
                      {size} {sizeType}
                    </>
                  )}{" "}
                  {title}
                </div>
                <CheckoutPrice
                  exVat={rest.priceExVat}
                  incVat={rest.priceIncVat}
                  showExVat={showExVat}
                  showIncVat={showIncVat}
                  showVatLabel={showVatLabel}
                  isPriceFrom={rest.isPriceFrom}
                />
              </>
            )}
            {!!dataIcon && (
              <div className={css.campaignTitleContainer}>
                <div>
                  <div
                    className="title"
                    style={{
                      minWidth: "150px",
                      paddingRight: "10px",
                      paddingBottom: 10,
                    }}
                  >
                    {size && (
                      <>
                        {size} {sizeType}
                      </>
                    )}{" "}
                    {title}
                  </div>
                  {!!dataTheme?.priceLabel && (
                    <div className={css.priceLabel}>
                      <PuxTypography
                        variant={"body2"}
                        component={PuxText}
                        color={dataTheme?.color}
                      >
                        {dataTheme?.priceLabel}
                      </PuxTypography>
                    </div>
                  )}
                  <CheckoutPrice
                    exVat={rest.priceExVat}
                    incVat={rest.priceIncVat}
                    showExVat={showExVat}
                    showIncVat={showIncVat}
                    showVatLabel={showVatLabel}
                    colorFree={dataTheme?.colorOnFree ?? dataTheme?.color}
                  />
                </div>
                <div>
                  <img
                    src={dataIcon}
                    height={xs ? "100" : "120px"}
                    style={{}}
                    alt={dataTheme?.iconImgAlt}
                  />
                </div>
              </div>
            )}
          </div>
          {descriptions.map((desc, i) => {
            if (
              Array.isArray(desc) &&
              desc.length === 2 &&
              typeof desc[1] === "number"
            ) {
              const p = getPrice({ exVat: desc[1] })
              return (
                <div
                  key={desc[0] as React.Key}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>{desc[0]}</div>
                  {showExVat && (
                    <div>
                      {i18n.format(p?.exVat, "price")}{" "}
                      {showVatLabel && t("General.ExVat")}
                    </div>
                  )}
                  {showIncVat && (
                    <div>
                      {i18n.format(p?.incVat, "price")}{" "}
                      {showVatLabel && t("General.IncVat")}
                    </div>
                  )}
                </div>
              )
            }
            return (
              <div
                className="grid-data-description"
                key={typeof desc === "string" ? desc : i}
              >
                <PuxText>{desc}</PuxText>
              </div>
            )
          })}
          {total && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>Total månedpris</div>
              <CheckoutPrice
                exVat={total.exVat}
                incVat={total.incVat}
                showExVat={showExVat}
                showIncVat={showIncVat}
                showVatLabel={showVatLabel}
              />
            </div>
          )}
        </div>
      </PuxItem>
    </PuxSheet>
  )
}
