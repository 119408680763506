import { useContext } from "react"
import { CampaignThemeContext } from "./CampaignThemeContext"
import { DataBoostTheme, DataPackageTheme } from "./themes";

export const useCampaignTheme = <T extends DataBoostTheme | DataPackageTheme>(theme?: string | null) => {
    const themes = useContext(CampaignThemeContext);
    return (
        !theme
          ? themes[0]
          : themes.find((t) => t.name.toLowerCase() === theme.toLowerCase()) ??
            themes[0]
      ) as T
}