import classNames from "classnames"
import useOfflineStatus from "../util/useOfflineStatus"
import css from "./OfflineStatus.module.scss"

export function OfflineStatus() {
  const status = useOfflineStatus()
  if (!status) {
    return null
  }
  return (
    <div className={classNames(css.status, !status.online && css.offline)}>
      {!status.online ? "Du ser ut til å være frakoblet" : ""}
    </div>
  )
}
