import {
  PuxBadge,
  PuxCol,
  PuxIcon,
  PuxLabel,
  PuxRow,
  PuxSkeletonText,
  PuxText,
  useBreakpoint,
} from "@phonero/pux-react"
import { databoost } from "@phonero/pux-icons/icons"
import { ExtraDataPackageOfferingProduct } from "@phonero/common-graphql/types"

import { CodewordType, useAppTranslations } from "../util"
import { DataPackage } from "./DataPackage"
import classNames from "classnames"
import css from "./DataPackage.module.scss"
import { DataBoostTheme } from "../Themes/themes"
import { useCampaignTheme } from "../Themes"

export const ExtraDataPackage = (
  props: Partial<ExtraDataPackageOfferingProduct> & {
    widestAvailablePackageSize?: number
  }
) => {
  const { t, i18n } = useAppTranslations()
  const xs = useBreakpoint("xs", { useMax: true })
  const sm = useBreakpoint("sm")
  const xsMin = useBreakpoint("s", { useMax: true, useMin: false })
  const themeDef = useCampaignTheme<DataBoostTheme>(props.theme ?? undefined)

  const {
    name,
    type,
    size,
    price,
    sizeType,
    widestAvailablePackageSize,
    theme,
  } = props

  if (!name) {
    return <PuxSkeletonText animated />
  }

  switch (type) {
    case CodewordType.ExtraDataEEA:
      return (
        <DataPackage
          {...(props as any)}
          subscriptionName={t("ExtraData.Name", { context: "0" })}
          widestAvailablePackageSize={props.widestAvailablePackageSize}
          sizeType={sizeType}
          hasIconEnd={!xs}
        />
      )
    case CodewordType.ExtraDataInternational:
      return (
        <>
          <PuxRow style={{ flex: "1 1 100%" }}>
            <PuxCol size={12} sizeMd={6} style={{ paddingBottom: 0 }}>
              <PuxLabel style={{ fontWeight: 900 }}>{name}</PuxLabel>
            </PuxCol>
            <PuxCol
              size={12}
              sizeMd={6}
              style={{ paddingTop: 0, paddingBottom: 4 }}
            >
              <PuxText style={{ marginRight: "1rem", fontWeight: 600 }}>
                kr. {i18n.format(price, "price")}
              </PuxText>
            </PuxCol>
          </PuxRow>

          <PuxText
            slot="end"
            color="primary"
            style={{
              fontSize: "2.8rem",
              fontWeight: 700,
              minWidth: "2rem",
              textAlign: "center",
            }}
          >
            {size}
          </PuxText>
          <PuxText
            slot="end"
            style={{ minWidth: "3rem", marginInlineStart: "0.5rem" }}
          >
            {t("ExtraData.SizeType", { context: sizeType, count: size })}
          </PuxText>
        </>
      )
    case CodewordType.ExtraBoost:
    case CodewordType.SpeedBoost:
      return (
        <>
          <div
            slot="start"
            style={{
              marginInlineEnd: xs ? "0" : "0.5rem",
              marginBlock: "auto",
              display: "flex",
            }}
          >
            {}
            {!!themeDef?.iconTopping && (
              <img
                src={themeDef?.iconTopping}
                height={"30px"}
                style={{
                  top: "-0.5rem",
                  left: xsMin ? "-8px" : "10px",
                  position: "absolute",
                  zIndex: 200,
                }}
                alt={themeDef.iconImgAlt}
              />
            )}
            {!!themeDef?.dataPackageRowIcon && (
              <PuxIcon
                icon={themeDef?.dataPackageRowIcon}
                style={{
                  height: "4rem",
                  width: "4rem",
                  marginLeft: xs ? "0.6rem" : "1rem",
                  marginRight: xs ? "0.6rem" : "1rem",
                }}
              />
            )}
            {!themeDef?.dataPackageRowIcon && (
              <PuxBadge
                color="primary"
                style={{
                  height: "4rem",
                  width: "4rem",
                  marginLeft: xs ? "0.6rem" : "1rem",
                  marginRight: xs ? "0.6rem" : "1rem",
                }}
              >
                <PuxIcon icon={databoost} />
              </PuxBadge>
            )}
          </div>
          <PuxText
            slot="start"
            className={classNames(css.sizeTextContainer, {
              [css.sizeWidth1]: widestAvailablePackageSize === 1,
              [css.sizeWidth2]: widestAvailablePackageSize === 2,
              [css.sizeWidth3]: widestAvailablePackageSize === 3,
              [css.sizeWidth4]: widestAvailablePackageSize === 4,
            })}
          >
            {size}
          </PuxText>
          <PuxText
            slot="start"
            style={{
              minWidth: xs ? "2rem" : "4rem",
              marginInlineEnd: sizeType === "GB" ? "1rem" : "0.5rem",
              fontWeight: 500,
            }}
          >
            {t("ExtraData.SizeType", { context: sizeType, count: size })}
          </PuxText>
          <PuxRow
            style={{
              paddingLeft: "10%",
              minWidth: "150px",
              flex: "1 1 100%",
              ...(!sm && {
                flex: "unset",
                width: "min-content",
              }),
              ...(xs && {
                margin: 0,
              }),
            }}
          >
            <PuxCol size={12} sizeMd={6} style={{ padding: 0 }}>
              <PuxLabel style={{ fontWeight: 900, paddingRight: "10px" }}>
                {type !== CodewordType.SpeedBoost && name}
                {type === CodewordType.SpeedBoost && t("ExtraData.FullSpeed")}
              </PuxLabel>
            </PuxCol>
            <PuxCol size={12} sizeMd={6} style={{ padding: 0 }}>
              <PuxText style={{ fontWeight: 500 }}>
                kr. {i18n.format(price, "price")}
                {/* Zero shall be printed with no mva suffix */}
                {!!price && (
                  <span
                    style={{
                      marginLeft: "1rem",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {t("General.ExVat")}
                  </span>
                )}
              </PuxText>
            </PuxCol>
          </PuxRow>
        </>
      )
    default:
      return (
        <>
          <PuxText>{name}</PuxText>
        </>
      )
  }
}
