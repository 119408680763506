


/** Provides a pollinterval, with an override suitable for testing */
export function pollInterval(wanted: number) {
  if (process.env.NODE_ENV === "development") {
    // @ts-ignore
    if (window.Cypress) {
      const p = Number(localStorage.getItem("__apollo_pollInterval"))
      if (p && !isNaN(p)) {
        return p
      }
      return p
    }
    if (!!localStorage.getItem("__poll_interval")) {
      // Effectively disables polling
      return 10000000
    }
  }
  return wanted
}
