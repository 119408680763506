import {
  PuxIcon,
  PuxSheet,
  PuxText,
  PuxTypography,
  useBreakpoint,
} from "@phonero/pux-react"
import css from "./ProductItemCampaign.module.scss"
import Price from "../Other/Price"
import classNames from "classnames"
import { DataPackageTheme } from "../Themes/themes"
import { Markdown } from "../util/markdownComponent"
import { useAppTranslations } from "../util"
import { useCampaignTheme } from "../Themes"

export const ProductItemCampaign = ({ product, onSelectProduct }) => {
  const theme = useCampaignTheme<DataPackageTheme>(product.theme)
  const isDesktop = useBreakpoint("md", { useMin: true })
  const icon = theme?.icon
  const isBiglyIcon = !!icon && icon.indexOf("big") > 0
  const { t } = useAppTranslations()

  return (
    <div className={css.container} onClick={() => onSelectProduct(product)}>
      {!!icon && (
        <PuxIcon
          icon={icon}
          size={"large"}
          className={isBiglyIcon ? css.iconBig : css.icon}
        />
      )}
      <PuxSheet className={css.textContainer}>
        <div className={css.sizeContainer}>
          <PuxText color={theme?.color}>{product.size}</PuxText>
          <span>
            {" "}
            {t("ExtraData.SizeType", {
              context: product.sizeType,
              count: product.size,
            })}
          </span>
        </div>
        <div>
          <PuxTypography
            variant={"action-button2"}
            component={"span"}
            style={{ fontWeight: 900 }}
          >
            {product.name}
          </PuxTypography>
        </div>
        <p style={{ marginBlock: 6 }}>
          <PuxTypography variant={"body2"} component={"div"}>
            <Markdown>{theme.ingress}</Markdown>
          </PuxTypography>
        </p>
        <div className={classNames(css.priceLabel)}>
          <div>
            <PuxText color={theme?.color}>{theme.priceLabel}</PuxText>
          </div>
          <Price
            value={product.price}
            action={["freeIfZero"]}
            priceStyle={{
              fontWeight: 900,
              textTransform: "capitalize",
              fontSize: isDesktop ? 19 : 17,
            }}
          />
          <div
            className={css.line}
            style={{ background: "var(--pux-color-" + theme.color + ")" }}
          />
        </div>
      </PuxSheet>
    </div>
  )
}
