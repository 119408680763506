import { useAppTranslations } from "@phonero/common-ux"
import React from "react"

const ExVatNotice = () => {
  const { t } = useAppTranslations()

  return (
    <small
      style={{
        display: "block",
        paddingBottom: 6,
        fontSize: "1.7rem",
        fontWeight: 500,
        paddingTop: 10,
        textAlign: "center",
        borderBottom: "0.5px solid rgb(211, 211, 211)",
        marginBottom: 20,
      }}
    >
      {t("ChangeSubscription.ExVatNotice")}
    </small>
  )
}

export default ExVatNotice
