import {
  PuxButton,
  PuxLogo,
  PuxSpinner,
  PuxTypography,
} from "@phonero/pux-react"
import css from "../Checkout.module.scss"
import { vipps } from "@phonero/pux-logos/logos"
import { PaymentMethod } from "@phonero/common-graphql/types"
import { featureFlags } from "../../../appConstants"
import { paymentVariation } from "../calculatePaymentVariation"
import { useAppTranslations, dataCy } from "@phonero/common-ux"
import VippsButton from "../../../assets/svgs/VippsButton"

export const SelectPaymentMethod = ({
  onPaymentClick,
  disabled,
  variant,
  loadingProductData,
}: {
  variant: paymentVariation
  subscriptionId: string
  product: any
  disabled: boolean
  loadingProductData: boolean
  onPaymentClick: (method: PaymentMethod | "newVipps") => void
}) => {
  const { t } = useAppTranslations()

  const isNotAVippsOnlyCustomer =
    variant !== paymentVariation.Variant2KunPrivatBetalingSomFeksVipps &&
    !loadingProductData
  const methodMobileButton =
    isNotAVippsOnlyCustomer &&
    t("SelectPaymentMethod.MobileButton", { context: String(variant) })

  return (
    <div>
      <div className={css.paymentButtons}>
        {featureFlags.vippsLegacy && (
          <PuxButton
            expand="block"
            className={css.button}
            disabled={disabled}
            aria-label="Betal med vipps"
            color="medium"
            {...dataCy("pay-vipps")}
            onClick={() => onPaymentClick(PaymentMethod.Vipps)}
          >
            <PuxLogo size="small" logo={vipps} />
          </PuxButton>
        )}
        {isNotAVippsOnlyCustomer && (
          <PuxButton
            disabled={disabled}
            expand="block"
            className={css.button}
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
            }}
            color="medium"
            {...dataCy("pay-cellular")}
            onClick={() => onPaymentClick(PaymentMethod.Mobile)}
          >
            {methodMobileButton}
          </PuxButton>
        )}
        {loadingProductData && <PuxSpinner />}
        {featureFlags.vipps && (
          <VippsButton
            {...dataCy("pay-vipps-new")}
            onClick={() => onPaymentClick("newVipps")}
          />
        )}
      </div>
      {/* The text below is only temporary. See #DPW-1002  */}
      {!featureFlags.vippsLegacy && (
        <PuxTypography variant="body2" className={css.extraButtonText}>
          Vi har gjort forbedringer på Vipps betaling. Fikk du ikke gjennomført
          kjøpet?
          <button
            className={css.hrefButton}
            style={{ color: "#FF6632" }}
            title="Gjennomfør kjøp med alternativ løsning"
            onClick={(e) => {
              e.preventDefault()
              onPaymentClick(PaymentMethod.Vipps)
            }}
          >
            Klikk her for å prøve på nytt
          </button>
        </PuxTypography>
      )}
    </div>
  )
}
