import { CostSharingYearAndMonth } from "@phonero/common-graphql/types"

export interface IPeriod {
  fromMonth: { year: number; month: number }
  toMonth: { year: number; month: number }
}

/** Parses a period from a url in the format `yymm` or `yymm-yymm`.
 *
 * If the fir
 * */
export const getShortPeriodCurrentMonth = () => {
  const now = new Date()
  const thisMonth = now.getMonth() + 1 // Need to add one month in order to comply with GraphQl API.
  const thisYear = now.getFullYear()

  const period: IPeriod = {
    fromMonth: { year: thisYear, month: thisMonth },
    toMonth: { year: thisYear, month: thisMonth },
  }
  return period
}

/** Parses a period from a url in the format `yymm` or `yymm-yymm`.
 *
 * If the fir
 * */
export const parseShortPeriod = (s: string) => {
  if (!s) {
    return null
  }
  const [from, to] = s.split("-")
  const fromMonth = {
    year: Number("20" + from.slice(0, 2)),
    month: Number(from.slice(2)),
  }
  if (!to) {
    return { fromMonth, toMonth: fromMonth }
  }
  const toMonth = {
    year: Number("20" + to.slice(0, 2)),
    month: Number(to.slice(2)),
  }
  return { fromMonth, toMonth } as IPeriod
}

/** Formats a period for use in urls.
 * If only one date is supplied, it will return `yymm` (no leading zeroes)
 * If both parameters are supplied, it will return `yymm-yymm`.
 * However, if both parameters are equal, it will return `yymm`
 *
 * @see parseShortPeriod for parsing this format
 *
 * */
export const formatPeriodForUrl = (from: Date, to?: Date) => {
  const first = formatShortMonthYear(from)
  if (!to) {
    return first
  }
  const second = formatShortMonthYear(to)
  if (first === second) {
    return first
  }
  return first + "-" + second
}

const formatShortMonthYear = (d: Date) => {
  const fd = new Date(d)
  const year = yearFormat.format(fd)
  const month = monthFormat.format(fd).slice(0, 2) // remove trailing punctuation

  return `${String(year)}${String(month)}`
}

const yearFormat = Intl.DateTimeFormat("nb", {
  year: "2-digit",
  timeZone: "Europe/Oslo",
})

const monthFormat = Intl.DateTimeFormat("nb", {
  month: "2-digit",
  timeZone: "Europe/Oslo",
})

/**
 * Parse period to from and to dates.
 *
 * Initialize the dates to UTC timezone (ideally i would like to use Europe/Oslo, as the formatShortMonthYear uses this for conversion).
 * **/
export const parsePeriodToDates = (period: IPeriod) => {
  const fromDate = new Date(
    Date.UTC(period.fromMonth.year, period.fromMonth.month - 1)
  )
  const toDate = new Date(
    Date.UTC(period.toMonth.year, period.toMonth.month - 1)
  )
  return { fromDate, toDate }
}

/** Converts a year and month to a date.
 * This is used to avoid timezone issues when receiving dates from the server. */
export const getDateFromYearAndMonth = (
  yearMonth: CostSharingYearAndMonth | undefined
) => {
  return !!yearMonth ? new Date(yearMonth.year, yearMonth.month - 1) : undefined
}

/** Converts a server year and month to a JS month.
 * JS Months are 0-indexed, so we need to subtract one from the month. */
export const getMonthFromYearAndMonth = (
  yearMonth: CostSharingYearAndMonth | undefined
) => {
  return !!yearMonth ? yearMonth.month - 1 : 0
}
