import { PuxButton, PuxToastGlobal } from "@phonero/pux-react"

import React, { lazy, useEffect } from "react"

import { SplitPaneLayout } from "./components/layout/SplitPaneLayout"
import {
  Redirect,
  Route,
  Switch as _Switch,
  useLocation,
} from "react-router-dom"

import { SplashScreen } from "./pages/welcome"
import { LoadingPage } from "./pages/loading/LoadingPage"
import useAppUrl from "./util/useAppUrl"
import { ChangeSubscriptionModal } from "./dialogs/ChangeSubscriptionModal"
import { AddDataDialog } from "./dialogs/AddDataDialog"
import { AddDataBoostDialog } from "./dialogs/AddDataBoostDialog"
import { Capacitor } from "@capacitor/core"
import { App as CapacitorApp, AppState } from "@capacitor/app"
import { requestAppTrackingPermission } from "./appTracking"
import { routeComponents } from "./pages"
import { RouteKeys, routes } from "./routes"
import useGetExtraDataPackageIfSubscriptionSet from "./dialogs/components/UseGetExtraDataPackageCustomerVariables"
import { SplashScreen as CapSplash } from "@capacitor/splash-screen"

import {
  ExpenseSetEnabledDocument,
  ExpenseSetEnabledQuery,
} from "./pages/usage/Expenses.generated"
import { customerFeaturesAccess } from "./util/useAppState2"
import { ActivationDateDocument } from "./pages/usage/UsageQueries.generated"
import OutstandingPayments from "./components/OutstandingPayments/OustandingPayments"
import {
  getRouteKeys,
  keycloakLoginOptions,
  UrlHashes,
  urlHashMap,
  useAppConstants,
  useFaroClient,
} from "@phonero/common-ux"
import {
  hasCostTransferAccess,
  useHasCostTransferAccess,
  hasSpeedBoost,
  InfoBlock,
  useKeycloak,
  Loading,
  useAppTranslations,
  useCurrentSubscriptionId,
  useAppQuery,
  CustomerFeaturesDocument,
  useTokenData,
  graphqlQueryToast,
} from "@phonero/common-ux"
import Contact from "./pages/contact"
import { useGrowthBook } from "@growthbook/growthbook-react"
import { matchRoute, useRouteMatch } from "./util/useRouteMatcher"
const Switch = _Switch as any

CapSplash.hide()

const DebugAnalytics = lazy(
  () => import(/* webpackChunkName: 'DebugAnalytics' */ "./util/DebugAnalytics")
)

const AppDevThing = lazy(
  () => import(/* webpackChunkName: 'DevView' */ "./DevView")
)

/* Not needed when pux changes the capacitor version to above 3.0 */
if (Capacitor.isNativePlatform()) {
  CapacitorApp.addListener("backButton", (e) => {
    if (Capacitor.getPlatform() !== "android") {
      return
    }

    if (!e.canGoBack) {
      CapacitorApp.exitApp()
    }
  })
}

Object.values(routeComponents).forEach((c) => c.preload?.())

var useSetView = () => {
  const faroClient = useFaroClient()
  const location = useLocation()
  const match = matchRoute(location.pathname)
  useEffect(() => {
    if (!faroClient) {
      return
    }
    if (location.pathname === "/") {
      let name: RouteKeys = "home"
      faroClient?.api.setView({ name })
      return
    }
    if (!match?.key) {
      return
    }
    faroClient.api.setView({ name: match.key })
    faroClient.api.pushEvent("navigate")
  }, [
    match?.key,
    location.pathname,
    location.hash,
    location.search,
    faroClient,
  ])
}
let didRequestAppTracking = false
function App() {
  const faroClient = useFaroClient()
  useAppUrl()
  useSetView()
  const { initialized, keycloak } = useKeycloak()
  const m = useRouteMatch()
  const { t } = useAppTranslations()
  // We need to listen to changes here
  useHasCostTransferAccess()

  const { customerId = "", preferred_username } = useTokenData()
  const { subscriptionId } = useCurrentSubscriptionId()
  useEffect(() => {
    if (!faroClient) {
      return
    }
    if (!subscriptionId) {
      return
    }
    faroClient.api.setUser({
      id: subscriptionId,
      username: preferred_username,
    })
  }, [subscriptionId, preferred_username, faroClient])

  const {
    data: isEnabledData,
    loading: isEnabledLoading,
    error: isEnabledError,
    client,
  } = useAppQuery(ExpenseSetEnabledDocument, {
    skip: !subscriptionId,
    variables: {
      subscriptionId: subscriptionId,
    },
    ...graphqlQueryToast(t, "ExpenseSetEnabled"),
  })

  const keycloakReady = !!initialized && !!keycloak.authenticated
  const { data: customerFeaturesData, error: customerFeaturesError } =
    useAppQuery(CustomerFeaturesDocument, {
      variables: {
        customerId: customerId ?? "",
      },
      skip: !customerId || !keycloakReady,
      fetchPolicy: "cache-first",
      ...graphqlQueryToast(t, "CustomerFeatures"),
    })

  useEffect(() => {
    CapacitorApp.addListener("appStateChange", async (state: AppState) => {
      if (state.isActive && client) {
        await client.refetchQueries({
          include: "active",
        })
      }
    })
  }, [client])

  useEffect(() => {
    if (!isEnabledLoading) {
      return
    }
    hasCostTransferAccess("loading")
  }, [isEnabledLoading])

  const growthbook = useGrowthBook()
  const { buildInfo } = useAppConstants()

  useEffect(() => {
    if (
      growthbook &&
      subscriptionId &&
      customerId &&
      preferred_username &&
      buildInfo
    ) {
      growthbook.setAttributes({
        subscriptionId,
        customerId,
        msisdn: preferred_username,
        version: buildInfo.number,
      })
    }
  }, [growthbook, subscriptionId, customerId, preferred_username, buildInfo])

  useEffect(() => {
    const customerFeatures = customerFeaturesData?.customer?.features ?? []
    customerFeaturesAccess(customerFeatures)
  }, [customerFeaturesData?.customer?.features])

  const isEnabledDataTyped: ExpenseSetEnabledQuery | undefined = isEnabledData
    ? (isEnabledData as ExpenseSetEnabledQuery)
    : undefined
  const _isEnabled =
    !!isEnabledDataTyped?.mobileSubscription?.hasEmployee || !!window.Cypress // I had some trouble with the mock-data here, so I took a shortcut due to time-constraints. ­ Runar

  useEffect(() => {
    if (_isEnabled === undefined && isEnabledError) {
      hasCostTransferAccess("loading")
      hasCostTransferAccess(isEnabledError.message as any)
      return
    }
    if (
      typeof _isEnabled === "boolean" &&
      !isEnabledLoading &&
      !!isEnabledData
    ) {
      hasCostTransferAccess("loading")
      hasCostTransferAccess(_isEnabled)
    }
  }, [isEnabledLoading, isEnabledError, _isEnabled, isEnabledData])

  const result = useGetExtraDataPackageIfSubscriptionSet({
    skip: !subscriptionId,
    onError: graphqlQueryToast(t, "extradatapackageifsubscriptionset").onError,
    variables: {
      subscriptionId: subscriptionId,
    },
  })

  // Very slow query, adding it to cache on startup.
  useAppQuery(ActivationDateDocument, {
    skip: !subscriptionId,
    variables: { subscriptionId },
    fetchPolicy: "cache-first",
  })

  const _calc = result?.calculations?.isSpeedBoostEnabled
  useEffect(() => {
    if (typeof _calc !== "boolean") {
      return
    }
    hasSpeedBoost(_calc)
  }, [_calc])

  // :::::::::::::::::::::::::

  const { authenticated } = keycloak

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return
    }
    if (didRequestAppTracking) {
      return
    }
    if (authenticated) {
      requestAppTrackingPermission()
      didRequestAppTracking = true
    }
  }, [authenticated])

  const { pathname, hash, search } = useLocation()
  const isInvalidPathIndex = pathname.startsWith("/ditt.phonero.no")
  const validPathName = isInvalidPathIndex
    ? pathname.replace("/ditt.phonero.no", "")
    : null
  const redirectTo =
    (validPathName && validPathName !== "" ? validPathName : "") + search + hash

  if (customerFeaturesError)
    console.debug(
      "Customer feature failed with message:",
      customerFeaturesError.message
    )

  if (!initialized) {
    return <LoadingPage />
  }
  // This can happen on subscriptions that are blocked
  // https://phoneroas.atlassian.net/browse/DPW-1062
  if (initialized && !subscriptionId && keycloak.token) {
    return (
      <Contact>
        <InfoBlock color="danger">
          Det ser ut til at det kan være et problem med ditt abonnement.
          <p>Vennligst kontakt kundeservice.</p>
        </InfoBlock>
      </Contact>
    )
  }
  const routeKeys = getRouteKeys(routes)

  return (
    <>
      <PuxToastGlobal defaults={{ position: "top" }} />
      <div>
        <SplitPaneLayout>
          <Switch>
            {/*
            Capacitor universal links sometimes adds the domain name when opening the app
            (i.e. when clicking on https://ditt.phonero.no/#boost on your cell).
            A page not found error is shown in the app.
            Redirecting to the homepage to avoid the error.
            */}
            <Route path="/ditt.phonero.no">
              <Redirect to={redirectTo} />
            </Route>

            {routeKeys.map((k) => {
              const { path, restrictions, isPublic } = routes[k]
              const Component = routeComponents[k]
              if (
                process.env.NODE_ENV !== "development" &&
                typeof path !== "string"
              ) {
                console.warn("Path is not a string.", path, Component)
                return null
              }
              if (process.env.NODE_ENV !== "development" && !Component) {
                console.warn("Component is null for path with key", k, path)
                return null
              }
              if (m.key === k) {
                // This block is for the current path visited by the user

                if (!isPublic && !keycloak.authenticated) {
                  keycloak?.login(keycloakLoginOptions)

                  // The user is suppose to be redirected, but may see this route briefly.
                  // We therefore hide it for a moment, by fading it in after a delay
                  // In case there is an error with the redirect, wed show the user a simple screen to allow
                  return (
                    <Route key={k} exact={true} path={path}>
                      <SplashScreen />
                    </Route>
                  )
                }
              }
              if (restrictions && m.key === k) {
                const result = restrictions(_IS_DEV)
                console.debug("That path has restrictions:", m, k, result)
                switch (typeof result) {
                  case null:
                  case undefined:
                    break
                  case "boolean":
                    if (!result) {
                      return (
                        <Route key={k} exact={true} path={path}>
                          <p>Manglende tilgang</p>
                        </Route>
                      )
                    }
                    break
                  case "string":
                  case "number":
                    return (
                      <Route key={k} exact={true} path={path}>
                        <p>{t(result as any)}</p>
                      </Route>
                    )
                  case "function":
                    return (
                      <Route key={k} exact={true} path={path}>
                        <>{result}</>
                      </Route>
                    )
                  case "object":
                    if (
                      result &&
                      typeof result === "object" &&
                      ("redirect" in result || "forceLogin" in result)
                    ) {
                      const { redirect, forceLogin } = result as {
                        redirect: string
                        forceLogin: boolean
                      }
                      if (forceLogin) {
                        keycloak.login(keycloakLoginOptions)
                        return (
                          <Route key={k} exact={true} path={path}>
                            <div>
                              <div>Dette området krever innlogging</div>
                              <PuxButton
                                onClick={() => {
                                  keycloak.login(keycloakLoginOptions)
                                }}
                              >
                                Logg inn
                              </PuxButton>
                            </div>
                          </Route>
                        )
                      }
                      if (redirect) {
                        const p = routes[redirect]?.path || redirect
                        return <Redirect to={p} key={k} />
                      }
                    }
                    return result
                  default:
                    break
                }
              }
              return (
                <Route
                  key={k}
                  exact={true}
                  path={path}
                  {...(!!Component.preload
                    ? {
                        render: (props) => (
                          <React.Suspense fallback={<Loading />}>
                            <Component {...props} />
                          </React.Suspense>
                        ),
                      }
                    : {
                        component: Component,
                      })}
                />
              )
            })}
          </Switch>
        </SplitPaneLayout>
      </div>
      {hash === UrlHashes.edit && <ChangeSubscriptionModal />}
      {urlHashMap[hash] !== undefined && <AddDataDialog />}
      {hash === UrlHashes.boost && <AddDataBoostDialog />}
      <React.Suspense fallback={null}>
        <AppDevThing />
      </React.Suspense>
      {process.env.NODE_ENV === "development" && (
        <React.Suspense fallback={null}>
          <DebugAnalytics />
        </React.Suspense>
      )}
      <OutstandingPayments />
    </>
  )
}

export default App
