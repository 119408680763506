export enum HelpfulSuggestion {
  LogoutLogin = "LogoutLogin",
  NavigateBack = "NavigateBack",
  NavigateHome = "NavigateHome",
  ClearLocalStorage = "ClearLocalStorage",
  IDPAccount = "IDPAccount",
  ContactSupport = "ContactSupport",
}

export type HelpfulSuggestions =
  | [HelpfulSuggestion, string?]
  | HelpfulSuggestion

/** Helpful errors are used when application may crash.
 *
 * User will presented with a (hopefully) helpful message,
 * along with suggestions for how to resolve the issue.
 *
 * By default, the user will recieve various suggestions,
 * but you may limit and customize them for each error-type.
 */
export class HelpfulError extends Error {
  constructor(
    message: string,
    public details: string,
    public suggestions?: HelpfulSuggestions[]
  ) {
    super(message)
    console.error("HelpfulError instantiated", message, details, this)
  }
}
export class MustUpgradeError extends Error {}
