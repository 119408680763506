import * as Types from '@phonero/common-graphql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OutstandingPaymentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OutstandingPaymentsQuery = { __typename: 'Query', outstandingPayments: Array<{ __typename: 'PaymentDetails', orderID: string, id: string, productID: string, subscriptionId: number, product?: { __typename: 'ProductInfo', id: number, name?: string | null } | null, lastState?: { __typename: 'TransitionState', amount: number, operation: Types.PaymentOperation, rawOperation?: string | null, success: boolean, time: string | Date } | null, states?: Array<{ __typename: 'TransitionState', amount: number, operation: Types.PaymentOperation, rawOperation?: string | null, success: boolean, time: string | Date } | null> | null }> };

export type CancelPaymentMutationVariables = Types.Exact<{
  input: Types.CancelPaymentInput;
}>;


export type CancelPaymentMutation = { __typename: 'Mutation', cancelPayment: { __typename: 'CancelPaymentPayload', paymentDetails?: { __typename: 'PaymentDetails', id: string, lastState?: { __typename: 'TransitionState', amount: number, operation: Types.PaymentOperation, rawOperation?: string | null, time: string | Date } | null } | null, errors?: Array<{ __typename: 'ECommerceError', message: string } | { __typename: 'FieldRequiredError', message: string } | { __typename: 'InvalidArgumentError', message: string } | { __typename: 'InvalidVippsIDError', message: string } | { __typename: 'NotFoundOrNoAccessError', message: string } | { __typename: 'PreconditionNotMetError', message: string }> | null } };


export const OutstandingPaymentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OutstandingPayments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"outstandingPayments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"orderID"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"productID"}},{"kind":"Field","name":{"kind":"Name","value":"product"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"subscriptionId"}},{"kind":"Field","name":{"kind":"Name","value":"lastState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"operation"}},{"kind":"Field","name":{"kind":"Name","value":"rawOperation"}},{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"time"}}]}},{"kind":"Field","name":{"kind":"Name","value":"states"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"operation"}},{"kind":"Field","name":{"kind":"Name","value":"rawOperation"}},{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"time"}}]}}]}}]}}]} as unknown as DocumentNode<OutstandingPaymentsQuery, OutstandingPaymentsQueryVariables>;
export const CancelPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"operation"}},{"kind":"Field","name":{"kind":"Name","value":"rawOperation"}},{"kind":"Field","name":{"kind":"Name","value":"time"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"errors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Error"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}}]} as unknown as DocumentNode<CancelPaymentMutation, CancelPaymentMutationVariables>;