import { makeVar, useReactiveVar } from "@apollo/client"

/** Indicates whether the user has activated speedboost */
export const hasSpeedBoost = makeVar<boolean | undefined>(undefined)
export const useHasSpeedBoost = () => useReactiveVar(hasSpeedBoost)

/** Indicates whether a user has access to cost-transfer or not.

 If the user does not have access, relevant GUI-elements should be hidden from view.
 */
export const hasCostTransferAccess = makeVar<boolean | undefined | "loading" | Error>(undefined)
export const useHasCostTransferAccess = () => useReactiveVar(hasCostTransferAccess)

export interface OrderQueueItem {
  id: string
  skipReFetchObservableQueries?: boolean | undefined
}

/**
 * used by CustomerOrderBadge to get orders that should be pulled for.
 * @param order
 */
export const pushOrderQueueItem = (order: { id: string; skipReFetchObservableQueries?: boolean | undefined }) => {
  if (!order?.id) {
    return
  }
  const existing = orderQueue()

  if (existing.find((o) => o.id === order.id)) return

  orderQueue([...existing, order])
}

/**
 * used by CustomerOrderBadge to remove orders that are completed.
 * @param order
 */
export const removeOrderQueueItem = (order: { id: string }) => {
  const existing = orderQueue()
  if (!existing || !existing.length) {
    return null
  }
  const rest = existing.filter((o) => o.id !== order.id)
  orderQueue(rest)
  return existing.find((o) => o.id === order?.id)
}

export const orderQueue = makeVar<OrderQueueItem[]>([])

export const useOrderQueueItems = () => useReactiveVar(orderQueue)
