import { PuxAvatar } from "@phonero/pux-react"
import classNames from "classnames"
import React, { FC } from "react"
import css from "./Avatar.module.scss"

export const AvatarInitials: FC<
  {
    theme?: string
    givenName?: string
    familyName?: string
  } & Parameters<typeof PuxAvatar>[0]
> = ({ theme, givenName, familyName, className, ...rest }) => {
  return (
    <PuxAvatar
      {...rest}
      className={classNames(
        className,
        css.avatar,
        css.center,
        theme === "light" ? css.mediumBackground : css.darkBackground,
        rest.class,
        (rest as any).className
      )}
    >
      <button className={classNames("pux-focusable", css.avatarButton)}>
        <div
          className={classNames(
            css.initials,
            css.mediumColor,
            theme === "light" ? css.darkColor : css.mediumColor
          )}
        >
          {!!givenName && !!familyName
            ? givenName.charAt(0).toUpperCase() +
              familyName.charAt(0).toUpperCase()
            : ""}
          {!!givenName && !familyName ? givenName.substring(0, 2) : ""}
          {!!familyName && !givenName ? familyName.substring(0, 2) : ""}
        </div>
      </button>
    </PuxAvatar>
  )
}
