/** Utility-function for checking if an object is empty */
export function isEmpty(obj: any) {
  if (!obj) {
    return true
  }
  if (Array.isArray(obj)) {
    return !obj.length
  }
  return !Object.keys(obj).length
}

export function isNullOrEmptyString(value) {
  return typeof value !== "string" || value === ""
}
