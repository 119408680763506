import { Capacitor } from "@capacitor/core"
import { parseBuildInfo } from "@phonero/common-ux"
import { useCallback, useEffect, useRef, useState } from "react"

/** General fetch with json-parsing. 

Adds default-headers which includes addtional inforatmion.

These headers could then optionally be used by the backend to serve different data.

*/
async function fetchJson<T = any>(url: string) {
  if (!url) {
    return null
  }

  let json: T
  try {
    let headers = new Headers()
    headers.append("pragma", "no-cache")
    headers.append("cache-control", "no-cache")
  // headers.append("PuxPlatform", Capacitor.getPlatform()) // TODO: Get buildinfo in here or do it some other way
  // headers.append("PuxClientVersion", build.tag)
  // headers.append("PuxClient", "no.phonero.ditt")
  // headers.append("v-hash", build.hash)
  // headers.append("v-tag", build.tag)

    const result = await fetch(url, { method: "GET", headers })
    if (!result.ok) {
      return null
    }
    json = (await result.json()) as any as T
  } catch (err) {
    console.error("Failed to fetch url:", url, err)
    return null
  }
  if (!json) {
    return null
  }
  return json as T
}

export const useFetchJson = <T = any>(url: string, lazy?: boolean) => {
  const [state, setState] = useState<T | null>(null)
  const [loading, setLoading] = useState(false)
  const internal = useRef({ called: false })
  const refetch = useCallback(
    async (urlOverride?: string) => {
      internal.current.called = true
      setLoading(true)
      const result = await fetchJson<T>(urlOverride || url)
      setLoading(false)
      if (!result) {
        return
      }
      setState(result)
    },
    [url]
  )
  useEffect(() => {
    if (internal.current.called) {
      return
    }
    if (lazy) {
      return
    }
    refetch()
  }, [lazy, refetch])
  return {
    data: state,
    loading,
    called: internal.current.called,
    refetch,
  }
}

export default fetchJson
