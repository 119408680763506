// This flag override display of product sizes.
// Special handling when flag is present on data packages
import { Maybe } from "graphql/jsutils/Maybe"
import { DataPackageSizeMetadataFlag } from "@phonero/common-graphql/types"

export enum PackageSizeFlag {
  Unlimited = "Unlimited",
  PayPerByte = "PayPerByte",
}

const apiToUiMap: Record<DataPackageSizeMetadataFlag, PackageSizeFlag> = {
  [DataPackageSizeMetadataFlag.Unlimited]: PackageSizeFlag.Unlimited,
  [DataPackageSizeMetadataFlag.Payperbyte]: PackageSizeFlag.PayPerByte,
}

export const toPackageSizeFlags = (
  apiFlags: Maybe<DataPackageSizeMetadataFlag>[]
) => {
  return apiFlags
    .map((f) => {
      if (!!f) return apiToUiMap[f]
      return undefined
    })
    .filter((f): f is PackageSizeFlag => !!f)
}
