import React, { FC, useEffect, useRef, useState } from "react"
import { OrderStatusId } from "@phonero/common-graphql/types"
import { PuxButton, PuxSpinner, PuxText } from "@phonero/pux-react"
import { pollInterval } from "@phonero/common-ux"
import css from "../Checkout.module.scss"
import { useAppQuery, useAppTranslations } from "@phonero/common-ux"
import { CheckOrderStatusDocument } from "../Checkout.generated"

const endStatuses = ["CANCELLED", "COMPLETED"]

// TODO: move this functianlity to the server so that it accepts guids both with and without hyphens
const ensureGuid = (s: string) => {
  if (!s) {
    return ""
  }
  if (s.length !== 32) {
    return s
  }
  if (s.includes("-")) {
    return s
  }
  return [
    s.substr(0, 8),
    s.substr(8, 4),
    s.substr(12, 4),
    s.substr(16, 4),
    s.substr(20),
  ]
    .join("-")
    .toUpperCase()
}

export const OrderStatus: FC<{
  id: string
  subscriptionId: string
  payUsingVipps: boolean
  onClose: () => any
  onOrderStatusChange?: (
    prev: OrderStatusId | undefined | null,
    next: OrderStatusId
  ) => any
}> = ({
  onOrderStatusChange: onStatusChange,
  id,
  subscriptionId,
  payUsingVipps,
  onClose,
}) => {
  const { t } = useAppTranslations()
  const [status, setStatus] = useState<string>()
  const [message, setMessage] = useState<string>()
  const orderId = ensureGuid(id)
  const { data, error, stopPolling } = useAppQuery(CheckOrderStatusDocument, {
    skip: !subscriptionId || !orderId,
    variables: { where: { id: { eq: orderId } }, subscriptionId },
    pollInterval: pollInterval(2000),
  })
  const order = data?.mobileSubscription?.orders?.nodes?.[0]
  const previousStatusRef = useRef(order?.status)
  const firstOrder = order
  const firstOrderStatus = firstOrder?.status
  const firstOrderMessage = firstOrder?.orderStatusMessage

  useEffect(() => {
    if (previousStatusRef.current !== firstOrderStatus && firstOrderStatus) {
      if (onStatusChange) {
        onStatusChange(previousStatusRef.current, firstOrderStatus)
      }
    }
    previousStatusRef.current = firstOrderStatus
  }, [firstOrderStatus, onStatusChange])

  useEffect(() => {
    !!firstOrderStatus && setStatus(firstOrderStatus)
    firstOrderMessage && setMessage(firstOrderMessage)
    if (firstOrderStatus && endStatuses.includes(firstOrderStatus)) {
      stopPolling()
    }
  }, [firstOrderStatus, firstOrderMessage, stopPolling])

  return (
    <div className={css.container}>
      {!!error && (
        <p>
          <PuxText>Det skjedde en feil ved uthenting av ordrestatus</PuxText>
        </p>
      )}
      {!!status && (
        <p>
          <PuxText>
            {t("OrderStatus", {
              context: status + (payUsingVipps ? "-VIPPS" : ""),
            })}
          </PuxText>
        </p>
      )}
      {!!message && (
        <div className={css.messageContainer}>
          <PuxText>{message}</PuxText>
        </div>
      )}
      {!!firstOrderStatus && endStatuses.includes(firstOrderStatus) && (
        <PuxButton
          style={{ minWidth: "25rem", marginTop: "2rem" }}
          onClick={onClose}
        >
          Lukk
        </PuxButton>
      )}
      {!(!!firstOrderStatus && endStatuses.includes(firstOrderStatus)) && (
        <PuxSpinner aria-hidden="true" />
      )}
    </div>
  )
}
